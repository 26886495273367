import { CoreMenu } from "@core/types";
import { Role } from "app/auth/models";

export const menu: CoreMenu[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    translate: "MENU.DASHBOARD",
    type: "item",
    icon: "home",
    url: "home",
  },
  {
    id: "visitas",
    title: "Visitas",
    translate: "MENU.VISITAS",
    type: "item",
    icon: "user-check",
    url: "apps/visitantes/list",
  },
  {
    id: "chat",
    title: "Chat",
    translate: "MENU.CHAT",
    type: "item",
    icon: "message-circle",
    url: "apps/chat",
    role: [Role.AdminWithChat, Role.OperadorWithChat],
  },
  {
    id: "ocorrencias",
    title: "Ocorrências",
    translate: "MENU.OCORRENCIAS",
    type: "item",
    icon: "alert-circle",
    url: "apps/ocorrencias/list",
  },
  {
    id: "encomendas",
    title: "Encomendas",
    translate: "MENU.ENCOMENDAS",
    type: "item",
    icon: "archive",
    url: "apps/encomendas/list",
  },

  {
    id: "cadastros",
    title: "Cadastros",
    translate: "MENU.CADASTROS",
    type: "collapsible",
    // role: ['Admin'], //? To hide collapsible based on user role
    icon: "home",
    // badge: {
    //   title: '2',
    //   translate: 'MENU.DASHBOARD.BADGE',
    //   classes: 'badge-light-warning badge-pill'
    // },
    children: [
      {
        id: "condominos",
        title: "Condôminos",
        translate: "MENU.CONDOMINOS",
        type: "item",
        icon: "users",
        url: "apps/condominos/list",
      },
      {
        id: "visitantes",
        title: "Visitantes",
        translate: "MENU.VISITANTES",
        type: "item",
        icon: "users",
        url: "apps/pessoas/list",
      },
      {
        id: "unidades",
        title: "Unidades",
        translate: "MENU.UNIDADES",
        type: "item",
        icon: "home",
        url: "apps/unidades/list",
      },
      {
        id: "veiculos",
        title: "Veículos",
        translate: "MENU.VEICULOS",
        type: "item",
        icon: "truck",
        url: "apps/veiculos/list",
      },
    ],
  },
  {
    id: "configuracoes",
    title: "Configurações",
    translate: "MENU.CONFIGURACOES",
    type: "collapsible",
    role: [Role.Admin, Role.AdminWithChat],
    // role: ['Admin'], //? To hide collapsible based on user role
    icon: "settings",
    // badge: {
    //   title: '2',
    //   translate: 'MENU.DASHBOARD.BADGE',
    //   classes: 'badge-light-warning badge-pill'
    // },
    children: [
      {
        id: "pessoa_perfil",
        title: "Perfis de Acesso",
        translate: "MENU.PESSOA_PERFIL",
        type: "item",
        icon: "shield",
        url: "apps/pessoa_perfil/list",
        role: [Role.Admin, Role.AdminWithChat],
      },
      {
        id: "grupo_leitor",
        title: "Grupos de Leitores",
        translate: "MENU.GRUPO_LEITOR",
        type: "item",
        icon: "sliders",
        url: "apps/grupo_leitor/list",
        role: [Role.Admin, Role.AdminWithChat],
      },
      {
        id: "alarme_motivo",
        title: "Motivos de Alarme",
        translate: "MENU.ALARME_MOTIVO",
        type: "item",
        icon: "sliders",
        url: "apps/alarme_motivo/list",
        role: [Role.Admin, Role.AdminWithChat],
      },
      {
        id: "device",
        title: "WhatsApp",
        translate: "MENU.WHATSAPP",
        type: "item",
        icon: "smartphone",
        url: "apps/device",
        role: [Role.Admin, Role.AdminWithChat],
      },
      {
        id: "equipamento",
        title: "Equipamentos",
        translate: "MENU.EQUIPAMENTOS",
        type: "item",
        icon: "camera",
        url: "apps/equipamento/list",
        role: [Role.Admin, Role.AdminWithChat],
      },
    ],
  },
  {
    id: "gestao",
    title: "Gestão",
    translate: "MENU.GESTAO",
    type: "collapsible",
    role: [Role.Admin, Role.AdminWithChat],
    icon: "book",
    // badge: {
    //   title: '2',
    //   translate: 'MENU.DASHBOARD.BADGE',
    //   classes: 'badge-light-warning badge-pill'
    // },
    children: [
      {
        id: "background-check",
        title: "Background Check",
        translate: "MENU.BACKGROUNDCHECK",
        type: "item",
        icon: "log-in",
        url: "apps/background-check/list",
      },
      {
        id: "login",
        title: "Registro de Logins",
        translate: "MENU.LOGIN",
        type: "item",
        icon: "log-in",
        url: "apps/login/list",
      },
    ],
  },

  // {
  //   id: 'moradores',
  //   title: 'Moradores',
  //   translate: 'MENU.MORADORES',
  //   type: 'item',
  //   icon: 'users',
  //   url: 'apps/moradores/list'
  // },
];
