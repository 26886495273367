import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { CoreCommonModule } from '@core/common.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreSidebarModule } from '@core/components';

import { AddOcorrenciaCardComponent } from './add-ocorrencia-card/add-ocorrencia-card.component';
import { OcorrenciaService } from './ocorrencia.service';
import { NgxMaskModule } from 'ngx-mask';
import { WebcamModule } from 'ngx-webcam';
import { OcorrenciaListComponent } from './ocorrencia-list/ocorrencia-list.component';
import { ViewOcorrenciaSidebarComponent } from './view-ocorrencia-sidebar/view-ocorrencia-sidebar.component';
import { AddOcorrenciaDialogComponent } from './add-ocorrencia-dialog/add-ocorrencia-dialog.component';

// routing
const routes: Routes = [
//   {
//     path: 'add',
//     component: InvoiceAddComponent,
//     resolve: {
//       Ias: InvoiceAddService
//     },
//     data: { animation: 'InvoiceAddComponent' }
//   },
  {
    path: 'list',
    component: OcorrenciaListComponent,
    resolve: {
      uls: OcorrenciaService
    },
     data: { animation: 'OcorrenciaListComponent' }
   },
//   {
//     path: 'preview/:id',
//     component: InvoicePreviewComponent,
//     resolve: {
//       data: InvoicePreviewService
//     },
//     data: { path: 'user-view/:id', animation: 'InvoicePreviewComponent' }
//   },
//   {
//     path: 'edit/:id',
//     component: InvoiceEditComponent,
//     resolve: {
//       data: InvoiceEditService
//     },
//     data: { path: 'user-view/:id', animation: 'InvoiceEditComponent' }
//   },
//   {
//     path: 'preview',
//     redirectTo: '/apps/invoice/preview/4989' // Redirection
//   },
//   {
//     path: 'edit',
//     redirectTo: '/apps/invoice/edit/4989' // Redirection
//   }
];

@NgModule({
  declarations: [
    AddOcorrenciaCardComponent,
    OcorrenciaListComponent,
    ViewOcorrenciaSidebarComponent,
    AddOcorrenciaDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    CoreDirectivesModule,
    Ng2FlatpickrModule,
    NgxDatatableModule,
    FormsModule,
    CorePipesModule,
    NgbModule,
    NgSelectModule,
    CoreSidebarModule,
    NgxMaskModule.forRoot(),
    WebcamModule
  ],
  providers: [OcorrenciaService],
  exports: [AddOcorrenciaCardComponent,OcorrenciaListComponent]
})
export class OcorrenciaModule {}
