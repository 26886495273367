import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

@Injectable()
export class EncomendaDeliverService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {}

  /**
   * Get deliver by code
   * @param {string} code
   * @returns {Observable<any>}
   * */
  getByCode(code) {
    return this._http.get<any[]>(
      `${environment.apiUrl}/encomendas/check/${code}.json`
    );
  }
  getMoradores(unidade){
    return this._http.get<any[]>(
      `${environment.apiUrl}/unidades/moradores/${unidade}.json`
    );
  }

}
