<div class="slideout-content">
  <!-- Modal to Add Customer starts-->
  <div class="modal-slide-in" id="modals-slide-in">
    <div class="modal-dialog">
      <div class="modal-content p-0">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="toggleSidebar('view-equipamento-evento-sidebar')"
        >
          ×
        </button>
        <div class="modal-header mb-1">
          <h5 class="modal-title">
            <span class="align-middle" *ngIf="evento?.EquipamentoEvento"
              >Evento #{{ evento.EquipamentoEvento.id }}
              {{ evento.EquipamentoEvento.dv_tp_alarme }}</span
            >
            <span class="align-middle" *ngIf="!evento"
              >Consultando evento ...</span
            >
          </h5>
        </div>
        <div class="d-flex justify-content-center my-1" *ngIf="!evento">
          <div class="spinner-border" role="status" aria-hidden="true"></div>
        </div>
        <div class="modal-body flex-grow-1" *ngIf="evento?.EquipamentoEvento">
          <form>
            <!-- <h4>Detalhes do Evento</h4> -->
            <div
              class="d-flex justify-content-between align-items-center flex-column"
              *ngIf="
                !evento.EquipamentoEvento.pessoa_id &&
                !evento.EquipamentoEvento.tp_alarme
              "
            >
              <h3
                class="badge badge-pill"
                title="{{ evento.EquipamentoEvento.tp_evento }}"
                [ngClass]="{
                  'badge-light-primary':
                    evento.EquipamentoEvento.tp_evento ==
                    'liberado-sem-passagem',
                  'badge-light-success':
                    evento.EquipamentoEvento.tp_evento == 'liberado' ||
                    evento.EquipamentoEvento.tp_evento == 'porta-fechada',
                  'badge-light-danger':
                    evento.EquipamentoEvento.tp_evento == 'nao-identificado' ||
                    evento.EquipamentoEvento.tp_evento == 'negado' ||
                    (evento.EquipamentoEvento.tp_evento == 'porta-aberta' &&
                      evento.EquipamentoEvento.dc_evento ==
                        'Porta esquecida aberta') ||
                    evento.EquipamentoEvento.tp_evento == 'porta-violada',
                  'badge-light-warning':
                    evento.EquipamentoEvento.tp_evento == 'porta-aberta' &&
                    evento.EquipamentoEvento.dc_evento !=
                      'Porta esquecida aberta'
                }"
              >
                {{ evento.EquipamentoEvento.dc_evento }}
              </h3>
            </div>
            <div
              class="d-flex justify-content-between align-items-center flex-column"
              *ngIf="
                !evento.EquipamentoEvento.pessoa_id &&
                evento.EquipamentoEvento.tp_alarme
              "
            >
              <div class="profile-image-wrapper">
                <div class="profile-image p-1">
                  <div
                    class="avatar bg-light-{{
                      config[evento.EquipamentoEvento.tp_alarme].color
                    }} p-25 m-0"
                  >
                    <div class="avatar-content">
                      <i
                        [data-feather]="
                          config[evento.EquipamentoEvento.tp_alarme].icon
                        "
                        class="font-medium-5"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <h3
                class="badge badge-pill"
                title="{{ evento.EquipamentoEvento.tp_evento }}"
                [ngClass]="{
                  'badge-light-primary':
                    evento.EquipamentoEvento.tp_evento ==
                    'liberado-sem-passagem',
                  'badge-light-success':
                    evento.EquipamentoEvento.tp_evento == 'liberado' ||
                    evento.EquipamentoEvento.tp_evento == 'porta-fechada',
                  'badge-light-danger':
                    evento.EquipamentoEvento.tp_evento == 'nao-identificado' ||
                    evento.EquipamentoEvento.tp_evento == 'negado' ||
                    (evento.EquipamentoEvento.tp_evento == 'porta-aberta' &&
                      evento.EquipamentoEvento.dc_evento ==
                        'Porta esquecida aberta') ||
                    evento.EquipamentoEvento.tp_evento == 'porta-violada',
                  'badge-light-warning':
                    evento.EquipamentoEvento.tp_evento == 'porta-aberta' &&
                    evento.EquipamentoEvento.dc_evento !=
                      'Porta esquecida aberta'
                }"
              >
                {{ evento.EquipamentoEvento.dc_evento }}
              </h3>
            </div>
            <div
              class="d-flex justify-content-between align-items-center flex-column"
              *ngIf="evento.EquipamentoEvento.pessoa_id"
            >
              <div class="profile-image-wrapper">
                <div class="profile-image p-1">
                  <div class="avatar">
                    <img
                      src="{{
                        evento.Pessoa.dc_picture_url ||
                          'assets/images/avatars/default.jpg'
                      }}"
                      alt="Profile Picture"
                      class="thumbnail-image"
                    />
                  </div>
                </div>
              </div>
              <h3>{{ evento.Pessoa.nm_pessoa }}</h3>
              <h4
                class="badge badge-pill"
                title="{{ evento.EquipamentoEvento.tp_evento }}"
                [ngClass]="{
                  'badge-light-primary':
                    evento.EquipamentoEvento.tp_evento ==
                    'liberado-sem-passagem',
                  'badge-light-success':
                    evento.EquipamentoEvento.tp_evento == 'liberado' ||
                    evento.EquipamentoEvento.tp_evento == 'porta-fechada',
                  'badge-light-danger':
                    evento.EquipamentoEvento.tp_evento == 'nao-identificado' ||
                    evento.EquipamentoEvento.tp_evento == 'negado' ||
                    (evento.EquipamentoEvento.tp_evento == 'porta-aberta' &&
                      evento.EquipamentoEvento.dc_evento ==
                        'Porta esquecida aberta') ||
                    evento.EquipamentoEvento.tp_evento == 'porta-violada',
                  'badge-light-warning':
                    evento.EquipamentoEvento.tp_evento == 'porta-aberta' &&
                    evento.EquipamentoEvento.dc_evento !=
                      'Porta esquecida aberta'
                }"
              >
                {{ evento.EquipamentoEvento.dc_evento }}
              </h4>
              <h6 *ngIf="evento.PessoaPerfil.nm_perfil" class="text-muted">
                {{ evento.PessoaPerfil.nm_perfil }}
              </h6>
              <h6 *ngIf="evento.Pessoa.dc_email" class="text-muted">
                {{ evento.Pessoa.dc_email }}
              </h6>
              <h6
                class="text-muted"
                *ngIf="evento.Pessoa.nr_cpf || evento.Pessoa.nr_rg"
              >
                <i class="feather icon-credit-card"></i>
                {{ evento.Pessoa.nr_cpf || evento.Pessoa.nr_rg }}
              </h6>
              <h6 *ngIf="evento.Pessoa.nr_telefone" class="text-muted">
                <i class="feather icon-phone"></i>
                {{ evento.Pessoa.nr_telefone }}
              </h6>
              <div
                class="badge badge-light-primary profile-badge"
                *ngIf="evento.Pessoa.nm_empresa"
              >
                {{ evento.Pessoa.nm_empresa }}
              </div>
              <div class="card bg-warning text-white" *ngIf="evento.EquipamentoEvento.dc_observacao_cadastro">
                <div class="card-body">
                  <h4 class="card-title text-white">Observação</h4>
                  <p class="card-text pre-wrap">{{ evento.EquipamentoEvento.dc_observacao_cadastro }}</p>
                </div>
              </div>

            
              <!-- <h6 *ngIf="evento.EquipamentoEvento.dc_placa" class="text-muted">
                    {{ evento.EquipamentoEvento.dc_placa }}
                  </h6>
                  <h6 *ngIf="evento.EquipamentoEvento.dc_modelo" class="text-muted">
                    {{ evento.EquipamentoEvento.dc_modelo }}
                  </h6> -->
            </div>
            <hr class="mb-2" />
            <!-- <div class="form-group">
                  <label for="nm_pessoa" class="form-label">Visitante</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nm_pessoa"
                    name="nm_pessoa"
                    [(ngModel)]="evento.Pessoa.nm_pessoa"
                    disabled
                  />
                </div>
                <div class="form-group" *ngIf="evento?.Pessoa.nr_cpf">
                  <label for="nr_cpf" class="form-label">CPF</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nr_cpf"
                    name="nr_cpf"
                    [(ngModel)]="evento.Pessoa.nr_cpf"
                    disabled
                  />
                </div>
                <div class="form-group" *ngIf="evento?.Pessoa.nr_rg">
                  <label for="nr_rg" class="form-label">RG</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nr_rg"
                    name="nr_rg"
                    [(ngModel)]="evento.Pessoa.nr_rg"
                    disabled
                  />
                </div>
                <div class="form-group" *ngIf="evento?.Pessoa.nm_empresa">
                  <label for="nm_empresa" class="form-label">Empresa</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nm_empresa"
                    name="nm_empresa"
                    [(ngModel)]="evento.Pessoa.nm_empresa"
                    disabled
                  />
                </div> -->
            <!-- <div class="form-group" *ngIf="evento?.Pessoa.nr_telefone">
                  <label for="nr_telefone" class="form-label">Telefone</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nr_telefone"
                    name="nr_telefone"
                    [(ngModel)]="evento.Pessoa.nr_telefone"
                    disabled
                  />
                </div>
                <div class="form-group" *ngIf="evento?.Pessoa.dc_email">
                  <label for="dc_email" class="form-label">E-mail</label>
                  <input
                    type="text"
                    class="form-control"
                    id="dc_email"
                    name="dc_email"
                    [(ngModel)]="evento.Pessoa.dc_email"
                    disabled
                  />
                </div> -->
            <h4>Dados do Evento</h4>
            <div class="form-group" *ngIf="evento?.EquipamentoEvento.created">
              <label for="created" class="form-label">Data/Hora</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="created"
                  name="created"
                  [(ngModel)]="evento.EquipamentoEvento.created"
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="feather icon-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
            <div
              class="form-group"
              *ngIf="evento?.EquipamentoEvento.equipamento_id"
            >
              <label for="nm_equipamento" class="form-label">Equipamento</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="nm_equipamento"
                  name="nm_equipamento"
                  [(ngModel)]="evento.Equipamento.nm_equipamento"
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="feather icon-tablet"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group" *ngIf="evento?.EquipamentoEvento.leitor_id">
              <label for="nm_leitor" class="form-label">Leitor</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="nm_leitor"
                  name="nm_leitor"
                  [(ngModel)]="evento.Leitor.nm_leitor"
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="feather icon-airplay"></i>
                  </span>
                </div>
              </div>
            </div>
            <div
              class="form-group"
              *ngIf="evento?.EquipamentoEvento.id_direcao"
            >
              <label for="dv_id_direcao" class="form-label">Direção</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="dv_id_direcao"
                  name="dv_id_direcao"
                  [(ngModel)]="evento.EquipamentoEvento.dv_id_direcao"
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="feather icon-repeat"></i>
                  </span>
                </div>
              </div>
            </div>
            <div
              class="form-group"
              *ngIf="evento?.EquipamentoEvento.id_biometria"
            >
              <label for="dv_id_biometria" class="form-label"
                >Identificação</label
              >
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="dv_id_biometria"
                  name="dv_id_biometria"
                  [(ngModel)]="evento.EquipamentoEvento.dv_id_biometria"
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="feather icon-airplay"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group" *ngIf="evento?.Unidade.nm_display">
              <label for="nm_display" class="form-label">Unidade</label>
              <input
                type="text"
                class="form-control"
                id="nm_display"
                name="nm_display"
                [(ngModel)]="evento.Unidade.nm_display"
                disabled
              />
            </div>
            <div class="form-group" *ngIf="evento?.Unidade.nr_ramal">
              <label for="nr_ramal" class="form-label">Ramal da Unidade</label>
              <input
                type="text"
                class="form-control"
                id="nr_ramal"
                name="nr_ramal"
                [(ngModel)]="evento.Unidade.nr_ramal"
                disabled
              />
            </div>
            <div class="form-group" *ngIf="evento?.Veiculo.nm_veiculo">
              <label for="nm_display" class="form-label">Veículo</label>
              <input
                type="text"
                class="form-control"
                id="nm_veiculo"
                name="nm_veiculo"
                [(ngModel)]="evento.Veiculo.nm_veiculo"
                disabled
              />
            </div>
            <ng-container *ngIf="evento.EquipamentoEvento.alarme_motivo_id">
              <h4>Dados da Ocorrência</h4>
              <div
                class="form-group"
                *ngIf="evento?.EquipamentoEvento.alarme_motivo_id"
              >
                <label for="nm_motivo" class="form-label">Motivo</label>
                <input
                  type="text"
                  class="form-control"
                  id="nm_motivo"
                  name="nm_motivo"
                  [(ngModel)]="evento.AlarmeMotivo.nm_motivo"
                  disabled
                />
              </div>
              <div
                class="form-group"
                *ngIf="evento?.EquipamentoEvento.dc_tratamento"
              >
                <label for="dc_tratamento" class="form-label"
                  >Descrição/Tratamento</label
                >
                <textarea
                  type="text"
                  class="form-control"
                  id="dc_tratamento"
                  name="dc_tratamento"
                  [(ngModel)]="evento.EquipamentoEvento.dc_tratamento"
                  disabled
                ></textarea>
              </div>
              <div
                class="form-group"
                *ngIf="evento?.EquipamentoEvento.usuario_id"
              >
                <label for="nm_usuario" class="form-label">Responsável</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="nm_usuario"
                    name="nm_usuario"
                    [(ngModel)]="evento.Usuario.nm_usuario"
                    disabled
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="feather icon-user"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="form-group"
                *ngIf="evento?.EquipamentoEvento.dt_tratamento"
              >
                <label for="dt_tratamento" class="form-label">Em</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="dt_tratamento"
                    name="dt_tratamento"
                    [(ngModel)]="evento.EquipamentoEvento.dt_tratamento"
                    disabled
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="feather icon-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="form-group d-flex flex-wrap mt-2">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-dismiss="modal"
                (click)="toggleSidebar('view-equipamento-evento-sidebar')"
                rippleEffect
              >
                Fechar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
