import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { Subject } from "rxjs";
import { EquipamentoEventoService } from "../equipamento-evento.service";
import { takeUntil } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EditEquipamentoEventoComponent } from "../edit-equipamento-evento/edit-equipamento-evento.component";

@Component({
  selector: "equipamento-evento-card",
  templateUrl: "./equipamento-evento-card.component.html",
  styleUrls: ["./equipamento-evento-card.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EquipamentoEventoCardComponent implements OnInit, OnDestroy {
  @Input() public type: any;

  private _unsubscribeAll: Subject<any>;
  public data: any[];

  config: any = {
    alerta: {
      title: "Avisos",
      icon: "alert-triangle",
      color: "warning",
    },
    panico: {
      title: "Pânico",
      icon: "x-circle",
      color: "danger",
    },
  };
  constructor(private _equipamentoEventoService: EquipamentoEventoService,
    private _modalService: NgbModal) {
    this._unsubscribeAll = new Subject();
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  ngOnInit(): void {
    if (this.type == "alerta") {
      this._equipamentoEventoService.onAlertaPendenteListChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((response) => {
          this.data = response;
        });
    } else if (this.type == "panico") {
      this._equipamentoEventoService.onPanicoPendenteListChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((response) => {
          this.data = response;
        });
    } else {
        throw new Error("Tipo de evento inválido!");
    }
  }
  openEvent(){
    if(!this.data || !this.data.length){
        return;
    } else {
        let modalRef = this._modalService.open(EditEquipamentoEventoComponent, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            // scrollable: true,
            size: "lg",
            windowClass: 'modal modal-' + this.config[this.type].color
          
      
          });
          modalRef.componentInstance.evento = this.data[0];
    }
  }
}
