import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        console.log(err);
        if ([401, 403].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          //this._router.navigate(['/pages/miscellaneous/not-authorized']);
          
          // ? Can also logout and reload if needed
          try{
          this._authenticationService.logout();
          }catch(e){
          }
          console.log('vou para a login');
          this._router.navigate(['/pages/authentication/login-v2'], { queryParams: { returnUrl: this._router.url } });
          console.log('naveguei');
          // location.reload();
        }
        // throwError
        const error = err.error.text || (err.error.message || err.statusText);
        return throwError(error);
      })
    );
  }
}
