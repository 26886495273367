import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { CoreConfigService } from "@core/services/config.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EncomendaListService } from "../encomenda-list/encomenda-list.service";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl,
} from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { WebcamImage } from "ngx-webcam";
import Swal from "sweetalert2";
import { EncomendaDeliverComponent } from "../encomenda-deliver/encomenda-deliver.component";

@Component({
  selector: "encomenda-card",
  templateUrl: "./encomenda-card.component.html",
  styleUrls: ["./encomenda-card.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EncomendaCardComponent implements OnInit {
  public blocos: any[] = [];
  public unidades: any[] = [];
  public usuarios: any[] = [];
  public tipo_encomendas: any[] = [];
  public submitting: boolean = false;
  public encomenda: any = {
    id: null,
    bloco_id: null,
    unidade_id: null,
    cd_encomenda: "",
    st_encomenda: "pendente",
    tipo_encomenda_id: null,
    dt_recebimento: new Date(),
    usuario_recebimento_id: null,
    dt_retirada: null,
    usuario_entrega_id: null,
    usuario_retirada_id: null,
    dc_observacao: "",
    nm_remetente: "",
    nm_destinatario: "",
    image: null,
    usuarios: [],
  };
  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  avatarImage = "../../../assets/images/avatars/default-avatar.jpg";
  imageMode = "file";

  public EncomendaForm: FormGroup;
  public submitted = false;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {CalendarService} _calendarService
   * @param {EncomendaListService} _encomendaListService
   */
  constructor(
    private formBuilder: FormBuilder,
    private _encomendaListService: EncomendaListService,
    private _coreConfigService: CoreConfigService,
    private modalService: NgbModal
  ) {}
  // Public Methods
  // -----------------------------------------------------------------------------------------------------
  get VForm() {
    return this.EncomendaForm.controls;
  }
  ngOnInit(): void {
    this._encomendaListService.getTiposEncomenda().then((data: any) => {
      console.log(data);
      this.tipo_encomendas = data;
    });
    this._encomendaListService.getBlocos().subscribe((data: any) => {
      this.blocos = data;
    });
    this.EncomendaForm = this.formBuilder.group({
      // id: null,
      // unidade_id: null,
      // cd_encomenda: "",
      // st_encomenda: "pendente",
      // tipo_encomenda_id: null,
      // dt_recebimento: new Date(),
      // usuario_recebimento_id: null,
      // dt_retirada: null,
      // usuario_entrega_id: null,
      // usuario_retirada_id: null,
      // dc_observacao: "",
      // nm_remetente: "",
      // nm_destinatario: "",
      id: [this.encomenda.id],
      bloco_id: [this.encomenda.bloco_id, Validators.required],
      unidade_id: [this.encomenda.unidade_id, Validators.required],
      cd_encomenda: [this.encomenda.cd_encomenda, Validators.required],
      st_encomenda: [this.encomenda.st_encomenda],
      tipo_encomenda_id: [
        this.encomenda.tipo_encomenda_id,
        Validators.required,
      ],
      dc_observacao: [this.encomenda.dc_observacao],
      nm_remetente: [this.encomenda.nm_remetente, Validators.required],
      nm_destinatario: [this.encomenda.nm_destinatario, Validators.required],
      // id_notificar: [this.encomenda.id_notificar],
      usuarios: new FormArray([]),
    });
  }

  modalOpenEncomendaDeliver() {
    this.modalService.open(EncomendaDeliverComponent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      // scrollable: true,
      size: "lg",
    });
  }
  modalOpenForm(modalForm) {
    this.encomenda = {
      id: null,
      bloco_id: null,
      unidade_id: null,
      cd_encomenda: "",
      st_encomenda: "pendente",
      tipo_encomenda_id: null,
      dt_recebimento: new Date(),
      usuario_recebimento_id: null,
      dt_retirada: null,
      usuario_entrega_id: null,
      usuario_retirada_id: null,
      dc_observacao: "",
      nm_remetente: "",
      nm_destinatario: "",
      image: null,
      id_notificar: true,
      usuarios: [],
    };
    this.EncomendaForm = this.formBuilder.group({
      bloco_id: [this.encomenda.bloco_id, Validators.required],
      unidade_id: [this.encomenda.unidade_id, Validators.required],
      cd_encomenda: [this.encomenda.cd_encomenda, Validators.required],
      tipo_encomenda_id: [
        this.encomenda.tipo_encomenda_id,
        Validators.required,
      ],
      dc_observacao: [this.encomenda.dc_observacao],
      nm_remetente: [this.encomenda.nm_remetente, Validators.required],
      nm_destinatario: [this.encomenda.nm_destinatario, Validators.required],
      id_notificar: [this.encomenda.id_notificar],
      usuarios: new FormArray([]),
    });
    this.modalService.open(modalForm, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      // scrollable: true,
      size: "lg",
    });
  }
  public getSnapshot(): void {
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.encomenda.image = webcamImage!.imageAsDataUrl;
    console.info("got webcam image", this.encomenda.image);
    this.avatarImage = this.encomenda.image;
    this.imageMode = "file";
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }
  uploadImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (event: any) => {
        this.avatarImage = event.target.result;
        this.encomenda.image = event.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }
  EncomendaFormOnSubmit() {
    this.submitted = true;
    this.submitting = true;

    // stop here if form is invalid
    if (this.EncomendaForm.invalid) {
      this.submitting = false;
      return;
    }
    if(this.encomenda.id_notificar && this.encomenda.usuarios.filter(u => u.selected).length == 0){
      this.submitting = false;
      Swal.fire({
        icon: "error",
        title: "Não foi possível registrar a encomenda",
        text: "Selecione pelo menos um usuário para notificar",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    var postData = {
      unidade_id: this.encomenda.unidade_id,
      cd_encomenda: this.encomenda.cd_encomenda,
      tipo_encomenda_id: this.encomenda.tipo_encomenda_id,
      dc_observacao: this.encomenda.dc_observacao,
      nm_remetente: this.encomenda.nm_remetente,
      nm_destinatario: this.encomenda.nm_destinatario,
      id_notificar: this.encomenda.id_notificar,
      image: this.encomenda.image,
      EncomendaNotificacao: this.encomenda.usuarios.filter(u => u.selected).map(u =>{
        return {
          usuario_id: u.usuario_id
        }
      }),
    };
    this._encomendaListService
      .addEncomenda(postData)
      .then((data: any) => {
        this.EncomendaForm.reset();
        this.encomenda.image = null;
        this.encomenda.st_encomenda = "pendente";
        this.submitted = false;
        this.avatarImage = "../../../assets/images/avatars/default-avatar.jpg";
        this.imageMode = "file";
        this.usuarios = [];
        this.submitting = false;
        this.modalService.dismissAll();
        // this._encomendaListService.get.subscribe((data: any) => {
        //   this.lastVisits = data;
        // });
        Swal.fire({
          icon: "success",
          title: "Encomenda registrada com sucesso",
          showConfirmButton: false,
          timer: 1500,
        });
      },(error)=>{
        this.submitting = false;
        Swal.fire({
          icon: "error",
          title: "Não foi possível registrar encomenda",
          html: (error.text || error) + "",
        });
      })
      .catch((error) => {
        this.submitting = false;
        Swal.fire({
          icon: "error",
          title: "Não foi possível registrar encomenda",
          html: (error.text || error) + "",
        });
      });
  }
  loadUsuarios(unidadeId) {
    if (unidadeId == null) {
      this.buildUsuarios(null);
    }
    this._encomendaListService.getUsuarios(unidadeId).subscribe((data: any) => {
      this.usuarios = data;
      this.buildUsuarios(this.usuarios);
    });
  }
  buildUsuarios(usuarios) {
    var formArray = this.EncomendaForm.controls["usuarios"] as FormArray;
    formArray.clear();
    this.encomenda.usuarios = [];
    if(usuarios == null) {
      return;
    }
    this.usuarios.forEach((usuario) => {
      formArray.push(
        new FormGroup({
          usuario_id: new FormControl(usuario.Usuario.id),
          selected: new FormControl(false),
        })
      );
      this.encomenda.usuarios.push({
        usuario_id: usuario.Usuario.id,
        selected: false,
      });
    });
    this.EncomendaForm.controls["usuarios"].updateValueAndValidity();
  }
  onChangeNotificar() {
    this.usuarios = [];
    this.loadUsuarios(this.encomenda.unidade_id);
  }
  onChangeUnidade() {
    this.usuarios = [];
    this.loadUsuarios(this.encomenda.unidade_id);
  }
  onChangeBloco() {
    this.usuarios = [];
    this.encomenda.usuarios = [];
    this.encomenda.unidade_id = null;
    this.loadUsuarios(this.encomenda.unidade_id);
    this._encomendaListService.getUnidades(this.encomenda.bloco_id).subscribe((data: any) => {
      this.unidades = data;
    });
  }
}
