import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";

import { environment } from "environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class ChaveService implements Resolve<any> {
  rows: [] = [];
  paging = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: "ControleAreaItem.id",
    orderDir: "desc",
  };
  filter = {
    search: "",
  };
  public onChaveListChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {
    // Set the defaults
    this.onChaveListChanged = new BehaviorSubject([]);
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows()]).then(() => {
        resolve();
      }, reject);
    });
  }

  /**
   * Get rows
   */
  getDataTableRows(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http
        .post(
          `${environment.apiUrl}/controle_area_itens.json`,
          { ControleAreaItemFilter: this.filter },
          { params: this.paging }
        )
        .subscribe((response: any) => {
          this.rows = response.data;
          this.paging.count = response.total;
          this.onChaveListChanged.next(this.rows);
          resolve(this.rows);
        }, reject);
    });
  }
 
  registerReturn(id) {
    return this._http.put<any>(
      `${environment.apiUrl}/controle_area_itens/return/${id}.json`,
      { ControleAreaItem: { id } }
    );
  }
  add(controle) {
    return this._http.post<any>(
      `${environment.apiUrl}/controle_area_itens/add.json`,
      { ControleAreaItem: controle }
    );
  }
  getUnidades() {
    return this._http.get<any[]>(`${environment.apiUrl}/unidades/list.json`);
  }
  getPessoas(unidade_id) {
    return this._http.get<any[]>(
      `${environment.apiUrl}/pessoas/list/${unidade_id}.json`
    );
  }
  getItens(){
    return this._http.get<any[]>(`${environment.apiUrl}/area_itens/list.json`);
  }
 
  updatePaging(paging: any) {
    this.paging = { ...this.paging, ...paging };
    this.getDataTableRows();
  }
  updateFilter(filter: any) {
    this.filter = { ...this.filter, ...filter };
    this.paging.offset = 0;
    this.getDataTableRows();
  }
}
