import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule } from "@core/components";
import { CoreDirectivesModule } from "@core/directives/directives";
import { CorePipesModule } from "@core/pipes/pipes.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { EquipamentoEventoListComponent } from "./equipamento-evento-list/equipamento-evento-list.component";
import { EquipamentoEventoService } from "./equipamento-evento.service";
import { EquipamentoEventoCardComponent } from "./equipamento-evento-card/equipamento-evento-card.component";
import { BlockUIModule } from "ng-block-ui";
import { EditEquipamentoEventoComponent } from "./edit-equipamento-evento/edit-equipamento-evento.component";
import { ViewEquipamentoEventoSidebarComponent } from "./view-equipamento-evento-sidebar/view-equipamento-evento-sidebar.component";
import { EquipamentoEventoPreviewComponent } from "./equipamento-evento-preview/equipamento-evento-preview.component";


const routes: Routes = [
  {
    path: "list",
    component: EquipamentoEventoListComponent,
    resolve: {
      uls: EquipamentoEventoService,
    },
    data: { animation: "EquipamentoEventoListComponent" },
  },
];

@NgModule({
  declarations: [
    EquipamentoEventoListComponent,
    EquipamentoEventoCardComponent,
    EditEquipamentoEventoComponent,
    ViewEquipamentoEventoSidebarComponent,
    EquipamentoEventoPreviewComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    CoreDirectivesModule,
    Ng2FlatpickrModule,
    NgxDatatableModule,
    FormsModule,
    CorePipesModule,
    NgbModule,
    NgSelectModule,
    CoreSidebarModule,
    BlockUIModule.forRoot({
      message: 'Aguarde ...'
    })
  ],
  providers: [
    EquipamentoEventoService,
  ],
  exports: [EquipamentoEventoListComponent,
    EquipamentoEventoCardComponent,
  EquipamentoEventoPreviewComponent],
})
export class EquipamentoEventoModule {}
