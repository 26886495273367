<!-- ngClass -->
<div class="card card-event mb-0" (click)="openEvent()">
  <div class="card-header">
    <div>
      <h2 class="font-weight-bolder mb-0">{{ config[type].title }}</h2>
      <!-- <p class="card-text">Chaves</p> -->
    </div>
    <div class="avatar bg-light-{{config[type].color}}  p-0 p-lg-25 m-0">
      <div class="avatar-content">
        <i [data-feather]="config[type].icon" class="font-medium-5"></i>
      </div>
    </div>
  </div>
  <span class="badge badge-{{config[type].color}} badge-pill" *ngIf="data && data.length > 0">{{ data.length }}</span>
</div>
