export const locale = {
  lang: 'pt',
  data: {
    MENU: {
      DASHBOARD: 'Dashboard',
      CADASTROS: 'Cadastros',
      GESTAO: 'Gestão',
      BACKGROUNDCHECK: 'Background Check',
      LOGIN: 'Registro de Logins',
      CONFIGURACOES: 'Configurações',
      CHAT: 'Chat',
      WHATSAPP: 'Conexão Whatsapp',
      VISITANTES: 'Visitantes',
      CONDOMINOS: 'Condôminos',
      VISITAS: 'Histórico de Visitas',
      VEICULOS: 'Veículos',
      ENCOMENDAS: 'Encomendas',
      OCORRENCIAS: 'Ocorrencias',
      EQUIPAMENTOS: 'Equipamentos',
      PESSOA_PERFIL: 'Perfis de Acesso',
      ALARME_MOTIVO: 'Motivos de Alarme',
      UNIDADES: 'Unidades',
      GRUPO_LEITOR: 'Grupos de Leitores',
    }
  }
}
