import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "maskDocument",
})
export class MaskDocumentPipe implements PipeTransform {
  transform(cpfCnpj: string): any {
    return cpfCnpj
      ?.split("")
      .map((n, i) =>
        i < cpfCnpj.length - 4 && (parseInt(n) >= 0 || parseInt(n) <= 9)
          ? "*"
          : n
      )
      .join("");
  }
}
