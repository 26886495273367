import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule } from "@core/components";
import { CoreDirectivesModule } from "@core/directives/directives";
import { CorePipesModule } from "@core/pipes/pipes.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { ReservaCardComponent } from "./reserva-card/reserva-card.component";
import { ReservaListComponent } from "./reserva-list/reserva-list.component";
import { ReservaService } from "./reserva.service";
import { ReservaGuestsComponent } from "./reserva-guests/reserva-guests.component";

// routing
const routes: Routes = [
    {
      path: 'list',
      component: ReservaListComponent,
      resolve: {
        uls: ReservaService
      },
      data: { animation: 'ReservaListComponent' }
    }
  ];
  
  @NgModule({
    declarations: [
      ReservaListComponent,
      ReservaCardComponent,
      ReservaGuestsComponent
    ],
    imports: [
      CommonModule,
      RouterModule.forChild(routes),
      CoreCommonModule,
      CoreDirectivesModule,
      Ng2FlatpickrModule,
      NgxDatatableModule,
      FormsModule,
      CorePipesModule,
      NgbModule,
      NgSelectModule,
      CoreSidebarModule,
  
    ],
    providers: [ReservaService],
    exports: [ReservaListComponent, ReservaCardComponent, ReservaGuestsComponent]
  })
  export class ReservaModule {}
  