import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ChaveService } from "../chave.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-add-chaves",
  templateUrl: "./add-chaves.component.html",
  styleUrls: ["./add-chaves.component.scss"],
})
export class AddChavesComponent implements OnInit {
  @BlockUI("form-section") formBlockUI: NgBlockUI;
  @Input() public periodo: any;

  public ControleAreaItemAddForm: FormGroup;
  public submitted = false;
  public pessoas: any = null;
  public itens: any = [];
  public unidades: any = [];

  controle: any = {
    unidade_id: null,
    pessoa_id: null,
    area_item_id: null,
  };
  /**
   * Constructor
   *
   * @param {FormBuilder} formBuilder
   * @param {NgbModal} modalService
   */
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private _chaveService: ChaveService
  ) {}

  ngOnInit(): void {
    this._chaveService.getUnidades().subscribe((data: any) => {
      this.unidades = data;
    });
    this._chaveService.getItens().subscribe((data: any) => {
      this.itens = data;
    });

    this.ControleAreaItemAddForm = this.formBuilder.group({
      unidade_id: [this.controle.unidade_id, Validators.required],
      pessoa_id: [this.controle.pessoa_id, Validators.required],
      area_item_id: [this.controle.area_item_id, Validators.required],
    });
  }
  get VForm() {
    return this.ControleAreaItemAddForm.controls;
  }
  ControleAreaItemAddFormOnSubmit() {
    this.submitted = true;
    // if (this.ControleAreaItemAddForm.invalid) {
    //    return;
    //  }
    if (!this.controle.pessoa_id || !this.controle.area_item_id) {
      Swal.fire({
        icon: "error",
        title: "Preencha todos os campos",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    this.formBlockUI.start("Registrando ...");

    this._chaveService.add(this.controle).subscribe(
      (data: any) => {
        this.activeModal.close();
        this.ControleAreaItemAddForm.reset();
        this.controle.area_item_id = null;
        this.submitted = false;
        this.formBlockUI.stop();
        this._chaveService.getDataTableRows();
        Swal.fire({
          icon: "success",
          title: "Retirada registrada com sucesso",
          showConfirmButton: false,
          timer: 1500,
        });
      },
      (error) => {
        this.formBlockUI.stop();
        Swal.fire({
          icon: "error",
          title: "Não foi possível registrar retirada",
          html: (error.text || error) + "",
        });
      }
    );
  }
  onChangeUnidade(event) {
    this.pessoas = null;
    this.controle.pessoa_id = null;
    this.ControleAreaItemAddForm.controls["pessoa_id"].setValue(null);
    if (event) {
      this._chaveService.getPessoas(event.Unidade.id).subscribe((data: any) => {
        this.pessoas = data;
      });
    }
  }
}
