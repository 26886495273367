import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";

import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";

import { CoreConfigService } from "@core/services/config.service";

import { EncomendaListService } from "app/main/apps/encomenda/encomenda-list/encomenda-list.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WebcamImage } from "ngx-webcam";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import Swal from "sweetalert2";
import { EncomendaDeliverComponent } from "../encomenda-deliver/encomenda-deliver.component";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { AuthenticationService } from "app/auth/service";

@Component({
  selector: "app-encomenda-list",
  templateUrl: "./encomenda-list.component.html",
  styleUrls: ["./encomenda-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EncomendaListComponent implements OnInit, OnDestroy {
  // public
  public submitting: boolean = false;
  public data: any;
  public selectedOption = 10;
  public ColumnMode = ColumnMode;
  public selectStatus: any = [
    { name: "Todas", value: "" },
    { name: "Pendente", value: "pendente" },
    { name: "Retirada", value: "retirada" },
    { name: "Devolvida", value: "devolvida" },
  ];

  public selectedStatus = [];
  public searchValue = "";
  public page: any;

  // decorator
  @ViewChild(DatatableComponent) table: DatatableComponent;

  // private
  private tempData = [];
  private _unsubscribeAll: Subject<any>;
  public rows = [];
  public tempFilterData;
  public previousStatusFilter = "";
  public dataReferencia = "";

  public blocos: any[] = [];
  public unidades: any[] = [];
  public usuarios: any[] = [];
  public tipo_encomendas: any[] = [];
  public encomenda: any = {
    id: null,
    bloco_id: null,
    unidade_id: null,
    cd_encomenda: "",
    st_encomenda: "pendente",
    tipo_encomenda_id: null,
    dt_recebimento: new Date(),
    usuario_recebimento_id: null,
    dt_retirada: null,
    usuario_entrega_id: null,
    usuario_retirada_id: null,
    dc_observacao: "",
    nm_remetente: "",
    nm_destinatario: "",
    image: null,
  };
  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  avatarImage = "../../../assets/images/avatars/default-avatar.jpg";
  imageMode = "file";

  public EncomendaForm: FormGroup;
  public submitted = false;
  public currentUser: any;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {CalendarService} _calendarService
   * @param {EncomendaListService} _encomendaListService
   */
  constructor(
    private formBuilder: FormBuilder,
    private _encomendaListService: EncomendaListService,
    private _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private _authenticationService: AuthenticationService,
    private modalService: NgbModal
  ) {
    this.page = _encomendaListService.paging;
    this._authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------
  get VForm() {
    return this.EncomendaForm.controls;
  }
  /**
   * filterUpdate
   *
   * @param event
   */
  filterUpdate(event) {
    this._encomendaListService.updateFilter({ search: event.target.value });
  }
  filterUpdateByDate(event) {
    this._encomendaListService.updateFilter({
      dt_referencia: event.target.value,
    });
  }

  /**
   * Filter By Roles
   *
   * @param event
   */
  filterByStatus(event) {
    this._encomendaListService.updateFilter({
      st_encomenda: event ? event.value : "",
    });
  }

  /**
   * Filter Rows
   *
   * @param statusFilter
   */
  filterRows(statusFilter): any[] {
    // Reset search on select change
    this.searchValue = "";

    statusFilter = statusFilter.toLowerCase();

    return this.tempData.filter((row) => {
      const isPartialNameMatch =
        row.encomendaStatus.toLowerCase().indexOf(statusFilter) !== -1 ||
        !statusFilter;
      return isPartialNameMatch;
    });
  }
  /**
   * Called whenever the user changes page
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  pageCallback(pageInfo: {
    count?: number;
    pageSize?: number;
    limit?: number;
    offset?: number;
  }) {
    this.page.offset = pageInfo.offset;
    this._encomendaListService.updatePaging(this.page);
  }

  /**
   * Called whenever the user changes the sort order
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  sortCallback(sortInfo: {
    sorts: { dir: string; prop: string }[];
    column: {};
    prevValue: string;
    newValue: string;
  }) {
    // there will always be one "sort" object if "sortType" is set to "single"
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this._encomendaListService.updatePaging(this.page);
  }
  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------
  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe config change
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        // If we have zoomIn route Transition then load datatable after 450ms(Transition will finish in 400ms)
        if (config.layout.animation === "zoomIn") {
          setTimeout(() => {
            this._encomendaListService.onEncomendaListChanged
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((response) => {
                this.data = response;
                this.rows = this.data;
                this.tempData = this.rows;
                this.tempFilterData = this.rows;
                this.page = this._encomendaListService.paging;
              });
          }, 450);
        } else {
          this._encomendaListService.onEncomendaListChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response) => {
              this.data = response;
              this.rows = this.data;
              this.tempData = this.rows;
              this.tempFilterData = this.rows;
              this.page = this._encomendaListService.paging;
            });
        }
      });

    this._encomendaListService.getTiposEncomenda().then((data: any) => {
      console.log(data);
      this.tipo_encomendas = data;
    });
    this._encomendaListService.getBlocos().subscribe((data: any) => {
      this.blocos = data;
    });
    this.EncomendaForm = this.formBuilder.group({
      // id: null,
      // unidade_id: null,
      // cd_encomenda: "",
      // st_encomenda: "pendente",
      // tipo_encomenda_id: null,
      // dt_recebimento: new Date(),
      // usuario_recebimento_id: null,
      // dt_retirada: null,
      // usuario_entrega_id: null,
      // usuario_retirada_id: null,
      // dc_observacao: "",
      // nm_remetente: "",
      // nm_destinatario: "",
      id: [this.encomenda.id],
      unidade_id: [this.encomenda.unidade_id, Validators.required],
      cd_encomenda: [this.encomenda.cd_encomenda, Validators.required],
      st_encomenda: [this.encomenda.st_encomenda],
      tipo_encomenda_id: [
        this.encomenda.tipo_encomenda_id,
        Validators.required,
      ],
      dc_observacao: [this.encomenda.dc_observacao],
      nm_remetente: [this.encomenda.nm_remetente, Validators.required],
      nm_destinatario: [this.encomenda.nm_destinatario, Validators.required],
    });
    // this._encomendaListService.getDataTableRows().then((data: any) => {
    //   console.log(data);
    // });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  modalOpenEncomendaDeliver() {
    this.modalService.open(EncomendaDeliverComponent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      // scrollable: true,
      size: "lg",
    });
  }
  modalOpenForm(modalForm) {
    this.encomenda = {
      id: null,
      unidade_id: null,
      cd_encomenda: "",
      st_encomenda: "pendente",
      tipo_encomenda_id: null,
      dt_recebimento: new Date(),
      usuario_recebimento_id: null,
      dt_retirada: null,
      usuario_entrega_id: null,
      usuario_retirada_id: null,
      dc_observacao: "",
      nm_remetente: "",
      nm_destinatario: "",
      image: null,
      id_notificar: true,
      usuarios: [],
    };
    this.EncomendaForm = this.formBuilder.group({
      bloco_id: [this.encomenda.bloco_id, Validators.required],
      unidade_id: [this.encomenda.unidade_id, Validators.required],
      cd_encomenda: [this.encomenda.cd_encomenda, Validators.required],
      tipo_encomenda_id: [
        this.encomenda.tipo_encomenda_id,
        Validators.required,
      ],
      dc_observacao: [this.encomenda.dc_observacao],
      nm_remetente: [this.encomenda.nm_remetente, Validators.required],
      nm_destinatario: [this.encomenda.nm_destinatario, Validators.required],
      // id_notificar: [this.encomenda.id_notificar],
      usuarios: new FormArray([]),
    });
    this.modalService.open(modalForm, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      // scrollable: true,
      size: "lg",
    });
  }
  public getSnapshot(): void {
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.encomenda.image = webcamImage!.imageAsDataUrl;
    console.info("got webcam image", this.encomenda.image);
    this.avatarImage = this.encomenda.image;
    this.imageMode = "file";
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }
  uploadImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (event: any) => {
        this.avatarImage = event.target.result;
        this.encomenda.image = event.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }
  EncomendaFormOnSubmit() {
    this.submitting = true;
    this.submitted = true;

    // stop here if form is invalid
    if (this.EncomendaForm.invalid) {
      this.submitting = false;
      return;
    }

    if (
      this.encomenda.id_notificar &&
      this.encomenda.usuarios.filter((u) => u.selected).length == 0
    ) {
      this.submitting = false;
      Swal.fire({
        icon: "error",
        title: "Não foi possível registrar a encomenda",
        text: "Selecione pelo menos um usuário para notificar",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    var postData = {
      unidade_id: this.encomenda.unidade_id,
      cd_encomenda: this.encomenda.cd_encomenda,
      tipo_encomenda_id: this.encomenda.tipo_encomenda_id,
      dc_observacao: this.encomenda.dc_observacao,
      nm_remetente: this.encomenda.nm_remetente,
      nm_destinatario: this.encomenda.nm_destinatario,
      id_notificar: this.encomenda.id_notificar,
      image: this.encomenda.image,
      EncomendaNotificacao: this.encomenda.usuarios
        .filter((u) => u.selected)
        .map((u) => {
          return {
            usuario_id: u.usuario_id,
          };
        }),
    };
    this._encomendaListService
      .addEncomenda(postData)
      .then(
        (data: any) => {
          this.submitting = false;
          this.EncomendaForm.reset();
          this.encomenda.image = null;
          this.encomenda.st_encomenda = "pendente";
          this.submitted = false;
          this.avatarImage =
            "../../../assets/images/avatars/default-avatar.jpg";
          this.imageMode = "file";
          this.usuarios = [];
          this.modalService.dismissAll();
          // this._encomendaListService.get.subscribe((data: any) => {
          //   this.lastVisits = data;
          // });
          Swal.fire({
            icon: "success",
            title: "Encomenda registrada com sucesso",
            showConfirmButton: false,
            timer: 1500,
          });
        },
        (error) => {
          this.submitting = false;
          Swal.fire({
            icon: "error",
            title: "Não foi possível registrar encomenda",
            html: (error.text || error) + "",
          });
        }
      )
      .catch((error) => {
        this.submitting = false;
        Swal.fire({
          icon: "error",
          title: "Não foi possível registrar encomenda",
          html: (error.text || error) + "",
        });
      });
  }
  viewData(id) {
    this._coreSidebarService
      .getSidebarRegistry("view-encomenda-sidebar")
      .toggleOpen();
    this._encomendaListService.getById(id);
  }
  editData(id) {
    this._coreSidebarService
      .getSidebarRegistry("edit-encomenda-sidebar")
      .toggleOpen();
    this._encomendaListService.getById(id);
  }
  loadUsuarios(unidadeId) {
    if (unidadeId == null) {
      this.buildUsuarios(null);
    }
    this._encomendaListService.getUsuarios(unidadeId).subscribe((data: any) => {
      this.usuarios = data;
      this.buildUsuarios(this.usuarios);
    });
  }
  buildUsuarios(usuarios) {
    var formArray = this.EncomendaForm.controls["usuarios"] as FormArray;
    formArray.clear();
    this.encomenda.usuarios = [];
    if (usuarios == null) {
      return;
    }
    this.usuarios.forEach((usuario) => {
      formArray.push(
        new FormGroup({
          usuario_id: new FormControl(usuario.Usuario.id),
          selected: new FormControl(false),
        })
      );
      this.encomenda.usuarios.push({
        usuario_id: usuario.Usuario.id,
        selected: false,
      });
    });
    this.EncomendaForm.controls["usuarios"].updateValueAndValidity();
  }
  onChangeNotificar() {
    this.usuarios = [];
    this.loadUsuarios(this.encomenda.unidade_id);
  }
  onChangeUnidade() {
    this.usuarios = [];
    this.loadUsuarios(this.encomenda.unidade_id);
  }
  onChangeBloco() {
    this.usuarios = [];
    this.encomenda.usuarios = [];
    this.encomenda.unidade_id = null;
    this.loadUsuarios(this.encomenda.unidade_id);
    this._encomendaListService
      .getUnidades(this.encomenda.bloco_id)
      .subscribe((data: any) => {
        this.unidades = data;
      });
  }
  download() {
    this._encomendaListService.download().subscribe(
      (response: any) => {
        console.log(response);
        //const blob = new Blob([response], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(response.body);
        const link = document.createElement("a");
        link.href = url;
        link.download = "encomendas.csv";
        link.click();
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
