import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { OcorrenciaService } from "../ocorrencia.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddOcorrenciaDialogComponent } from "../add-ocorrencia-dialog/add-ocorrencia-dialog.component";

@Component({
  selector: "app-ocorrencia-list",
  templateUrl: "./ocorrencia-list.component.html",
  styleUrls: ["./ocorrencia-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OcorrenciaListComponent implements OnInit, OnDestroy {
  // public
  public data: any;
  public selectedOption = 10;
  public ColumnMode = ColumnMode;
  public searchValue = "";
  public page:any;

  // decorator
  @ViewChild(DatatableComponent) table: DatatableComponent;

  // private
  private tempData = [];
  private _unsubscribeAll: Subject<any>;
  public rows = [];
  public tempFilterData;

  

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {EncomendaListService} _encomendaListService
   */
  constructor(
    private _ocorrenciaService: OcorrenciaService,
    private _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private modalService: NgbModal,
  ) {
    this.page = this._ocorrenciaService.paging;
    this._unsubscribeAll = new Subject();
  }

  /**
   * filterUpdate
   *
   * @param event
   */
  filterUpdate(event) {
    this._ocorrenciaService.updateFilter({ search: event.target.value });
  }

  /**
   * Called whenever the user changes page
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this._ocorrenciaService.updatePaging(this.page);
  }

  /**
   * Called whenever the user changes the sort order
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    // there will always be one "sort" object if "sortType" is set to "single"
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this._ocorrenciaService.updatePaging(this.page);
  }

  ngOnInit(): void {
    
    // Subscribe config change
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        // If we have zoomIn route Transition then load datatable after 450ms(Transition will finish in 400ms)
        if (config.layout.animation === "zoomIn") {
          setTimeout(() => {
            this._ocorrenciaService.onOcorrenciaListChanged
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((response) => {
                this.data = response;
                this.rows = this.data;
                this.tempData = this.rows;
                this.tempFilterData = this.rows;
                this.page = this._ocorrenciaService.paging;
              });
          }, 450);
        } else {
          this._ocorrenciaService.onOcorrenciaListChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response) => {
              this.data = response;
              this.rows = this.data;
              this.tempData = this.rows;
              this.tempFilterData = this.rows;
              this.page = this._ocorrenciaService.paging;
            });
        }
      });
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  viewData(id) {
    this._coreSidebarService
      .getSidebarRegistry("view-ocorrencia-sidebar")
      .toggleOpen();
    this._ocorrenciaService.getById(id);
  }
  modalOpenForm() {
    const modalRef = this.modalService.open(AddOcorrenciaDialogComponent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      // scrollable: true,
      size: "lg",
    });
  }
}
