<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel1">
    Registrar Visitante {{ autorizado.Autorizado.nm_evento }}
  </h4>
  <button
    type="button"
    class="close"
    (click)="activeModal.dismiss('Cross click')"
    aria-label="Close"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <ng-container *ngIf="!loading">
  <form
    [formGroup]="VisitaForm"
    (ngSubmit)="VisitaFormOnSubmit()"
    #formVisita
    *blockUI="'form-section'"
    class="form-block"
    
  >
    <h6 class="mb-2">1. Dados do Visitante</h6>
    <div class="row">
      <div class="col-md-8 col-12">
        <div class="row">
          <div class="col-md-6 col-12">
            <label>Documento: </label>
            <div class="form-group">
              <ng-select
                [items]="tipo_documentos"
                [clearable]="false"
                bindLabel="name"
                bindValue="id"
                name="tp_documento"
                [(ngModel)]="visita.tp_documento"
                (change)="changeTipoDocumento($event)"
                formControlName="tp_documento"
                [ngClass]="{
                  'is-invalid': submitted && VForm.tp_documento.errors
                }"
              >
              </ng-select>
              <div
                *ngIf="submitted && VForm.tp_documento.errors"
                class="invalid-feedback"
              >
                <div *ngIf="VForm.tp_documento.errors.required">
                  Tipo de documento é obrigatório
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-12"
            *ngIf="visita.tp_documento == 'cpf'"
            [formGroup]="VForm['Pessoa']"
          >
            <label>CPF: </label>
            <div class="form-group">
              <input
                type="text"
                placeholder="CPF"
                class="form-control"
                name="nr_cpf"
                [(ngModel)]="visita.Pessoa.nr_cpf"
                mask="000.000.000-00"
                [dropSpecialCharacters]="false"
                (change)="changeCpf($event)"
                formControlName="nr_cpf"
                [ngClass]="{
                  'is-invalid': submitted && VFormPessoa.nr_cpf.errors
                }"
              />
              <div
                *ngIf="submitted && VFormPessoa.nr_cpf.errors"
                class="invalid-feedback"
              >
                <div *ngIf="VFormPessoa.nr_cpf.errors.required">
                  CPF é obrigatório
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-12"
            *ngIf="visita.tp_documento == 'rg'"
            [formGroup]="VForm['Pessoa']"
          >
            <label>RG: </label>
            <div class="form-group">
              <input
                type="text"
                placeholder="RG"
                class="form-control"
                name="nr_rg"
                [(ngModel)]="visita.Pessoa.nr_rg"
                (change)="changeRg($event)"
                formControlName="nr_rg"
                [ngClass]="{
                  'is-invalid': submitted && VFormPessoa.nr_rg.errors
                }"
              />
              <div
                *ngIf="submitted && VFormPessoa.nr_rg.errors"
                class="invalid-feedback"
              >
                <div *ngIf="VFormPessoa.nr_rg.errors.required">
                  RG é obrigatório
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12" [formGroup]="VForm['Pessoa']">
            <label>Nome: </label>
            <div class="form-group">
              <input
                type="text"
                placeholder="Nome Completo"
                class="form-control"
                name="nm_pessoa"
                [(ngModel)]="visita.Pessoa.nm_pessoa"
                formControlName="nm_pessoa"
                [ngClass]="{
                  'is-invalid': submitted && VFormPessoa.nm_pessoa.errors
                }"
              />
              <div
                *ngIf="submitted && VFormPessoa.nm_pessoa.errors"
                class="invalid-feedback"
              >
                <div *ngIf="VFormPessoa.nm_pessoa.errors.required">
                  Nome é obrigatório
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-12" [formGroup]="VForm['Pessoa']">
            <label>Telefone: </label>
            <div class="form-group">
              <input
                type="text"
                placeholder="Telefone"
                class="form-control"
                name="nr_telefone"
                [(ngModel)]="visita.Pessoa.nr_telefone"
                mask="(00) 00000-0000"
                [dropSpecialCharacters]="false"
                formControlName="nr_telefone"
                [ngClass]="{
                  'is-invalid': submitted && VFormPessoa.nr_telefone.errors
                }"
              />
              <div
                *ngIf="submitted && VFormPessoa.nr_telefone.errors"
                class="invalid-feedback"
              >
                <div *ngIf="VFormPessoa.nr_telefone.errors.required">
                  Telefone é obrigatório
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-12" [formGroup]="VForm['Pessoa']">
            <label>E-mail: </label>
            <div class="form-group">
              <input
                type="email"
                placeholder="E-mail"
                class="form-control"
                name="dc_email"
                [(ngModel)]="visita.Pessoa.dc_email"
                formControlName="dc_email"
                [ngClass]="{
                  'is-invalid': submitted && VFormPessoa.dc_email.errors
                }"
              />
              <div
                *ngIf="submitted && VFormPessoa.dc_email.errors"
                class="invalid-feedback"
              >
                <div *ngIf="VFormPessoa.dc_email.errors.required">
                  E-mail é obrigatório
                </div>
                <div *ngIf="VFormPessoa.dc_email.errors.minlength">
                  Informe o e-mail completo
                </div>
                <div *ngIf="VFormPessoa.dc_email.errors.maxlength">
                  Tamanho máximo de 100 caracteres
                </div>
                <div *ngIf="VFormPessoa.dc_email.errors.pattern">
                  Informe um e-mail válido
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6" [formGroup]="VForm['Pessoa']">
            <div class="form-group">
              <label for="pessoa_perfil_id">Perfil</label>
              <ng-select
                *ngIf="pessoaPerfis && pessoaPerfis.length > 0"
                [items]="pessoaPerfis"
                [clearable]="false"
                bindLabel="nm_perfil"
                bindValue="id"
                name="pessoa_perfil_id"
                [(ngModel)]="visita.Pessoa.pessoa_perfil_id"
                formControlName="pessoa_perfil_id"
                [ngClass]="{
                  'is-invalid':
                    submitted &&
                    VForm['Pessoa'].controls.pessoa_perfil_id.errors
                }"
              >
              </ng-select>
              <div
                *ngIf="
                  submitted &&
                  VForm['Pessoa'].controls.pessoa_perfil_id.errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="
                    VForm['Pessoa'].controls.pessoa_perfil_id.errors
                      .required
                  "
                >
                  Perfil é obrigatório
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label>Data Saída: </label>
            <div class="form-group">
              <input
                type="date"
                readonly
                disabled
                class="form-control"
                name="dt_saida"
                [(ngModel)]="autorizado.Autorizado.dt_saida"
              />
            </div>
          </div>
          <div class="col-md-8">
            <label>Observação: </label>
            <div class="form-group">
              <textarea
                readonly
                disabled
                class="form-control"
                name="dc_observacao"
                [(ngModel)]="autorizado.Autorizado.dc_observacao"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-12">
            <label>Visita: </label>
            <div class="form-group">
              <ng-select
                [items]="tipo_visitas"
                bindLabel="name"
                bindValue="id"
                [loading]="false"
                name="tp_visita"
                [(ngModel)]="visita.tp_visita"
                [clearable]="false"
                formControlName="tp_visita"
                (change)="changeTipoVisita($event)"
                [ngClass]="{
                  'is-invalid': submitted && VForm.tp_visita.errors
                }"
              >
              </ng-select>
              <div
                *ngIf="submitted && VForm.tp_visita.errors"
                class="invalid-feedback"
              >
                <div *ngIf="VForm.tp_visita.errors.required">
                  Visita é obrigatório
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-12"
            *ngIf="visita.tp_visita == 'servico'"
            [formGroup]="VForm['Pessoa']"
          >
            <label>Empresa: </label>
            <div class="form-group">
              <input
                type="text"
                placeholder="Nome Completo"
                class="form-control"
                name="nm_empresa"
                [(ngModel)]="visita.Pessoa.nm_empresa"
                formControlName="nm_empresa"
                [ngClass]="{
                  'is-invalid': submitted && VFormPessoa.nm_empresa.errors
                }"
              />
              <div
                *ngIf="submitted && VFormPessoa.nm_empresa.errors"
                class="invalid-feedback"
              >
                <div *ngIf="VFormPessoa.nm_empresa.errors.required">
                  Empresa é obrigatório
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <!-- header media -->
        <div *ngIf="imageMode == 'camera'">
          <div class="row">
            <div class="col-md-12 text-center">
              <div class="rounded mb-75 object-fit-cover">
                <webcam
                  [trigger]="invokeObservable"
                  (imageCapture)="captureImg($event)"
                  [width]="200"
                  [height]="200"
                ></webcam>
              </div>
            </div>
            <div class="col-md-12 text-center">
              <button
                type="button"
                class="btn btn-sm btn-primary mb-75 mr-75"
                (click)="getSnapshot()"
              >
                Capturar
              </button>
              <button
                type="button"
                class="btn btn-sm btn-outline-secondary mb-75"
                rippleEffect
                (click)="imageMode = 'file'"
              >
                Voltar
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="imageMode == 'file'">
          <div class="row">
            <div class="col-12 text-center">
              <a href="javascript:void(0);">
                <img
                  [src]="avatarImage"
                  class="rounded mb-75 object-fit-cover shadow"
                  alt=""
                  height="200"
                  width="200"
                  (click)="file.click()"
                />
              </a>
            </div>
            <div class="col-12 text-center">
              <button
                type="button"
                (click)="file.click()"
                for="account-upload"
                class="btn btn-sm btn-primary mb-75 mr-75"
                rippleEffect
              >
                Upload
              </button>
              <input
                type="file"
                #file
                id="account-upload"
                hidden
                accept="image/*"
                (change)="uploadImage($event)"
              />
              <button
                type="button"
                class="btn btn-sm btn-outline-secondary mb-75"
                rippleEffect
                (click)="imageMode = 'camera'"
              >
                Câmera
              </button>
            </div>
          </div>
        </div>
        <!--/ header media -->
      </div>
    </div>
    <h6 class="mb-2">2. Destino</h6>
    <div class="row">
      <div class="col-md-4 col-12">
        <label>Destino: </label>
        <div class="form-group">
          <ng-select
            [disabled]="true"
            [items]="destinos"
            bindLabel="name"
            bindValue="id"
            [loading]="false"
            name="tp_destino"
            [(ngModel)]="visita.tp_destino"
            [clearable]="false"
            formControlName="tp_destino"
            [ngClass]="{
              'is-invalid': submitted && VForm.tp_destino.errors
            }"
          >
          </ng-select>
          <div
            *ngIf="submitted && VForm.tp_destino.errors"
            class="invalid-feedback"
          >
            <div *ngIf="VForm.tp_destino.errors.required">
              Destino é obrigatório
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-12" *ngIf="visita.tp_destino == 'unidade'">
        <label>Unidade: </label>
        <div class="form-group">
          <ng-select
            [disabled]="true"
            [items]="unidades"
            bindLabel="Unidade.nm_display"
            bindValue="Unidade.id"
            [loading]="false"
            name="unidade_id"
            [(ngModel)]="visita.unidade_id"
            formControlName="unidade_id"
            [ngClass]="{
              'is-invalid': submitted && VForm.unidade_id.errors
            }"
          >
          </ng-select>
          <div
            *ngIf="submitted && VForm.unidade_id.errors"
            class="invalid-feedback"
          >
            <div *ngIf="VForm.unidade_id.errors.required">
              Unidade é obrigatório
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12"
        ngClass="{'col-md-4':visita.tp_destino == 'unidade','col-md-8':visita.tp_destino=='condominio'}"
      >
        <label>Visitado: </label>
        <div class="form-group">
          <input
            readonly
            disabled
            type="text"
            placeholder="Nome do visitado"
            class="form-control"
            name="nm_visitado"
            [(ngModel)]="visita.nm_visitado"
            formControlName="nm_visitado"
            [ngClass]="{
              'is-invalid': submitted && VForm.nm_visitado.errors
            }"
          />
          <div
            *ngIf="submitted && VForm.nm_visitado.errors"
            class="invalid-feedback"
          >
            <div *ngIf="VForm.nm_visitado.errors.required">
              Visitado é obrigatório
            </div>
          </div>
        </div>
      </div>
    </div>

    <h6 class="mb-2">3. Autorização</h6>
    <div class="row">
      <div class="col-12 col-md-4">
        <label>Código de Autorização: </label>
        <div class="form-group">
          <input
            type="text"
            placeholder="Codigo enviado ao visitante"
            class="form-control"
            name="cd_autorizacao"
            [(ngModel)]="visita.cd_autorizacao"
            formControlName="cd_autorizacao"
            [ngClass]="{
              'is-invalid': submitted && VForm.cd_autorizacao.errors
            }"
          />
          <div
            *ngIf="submitted && VForm.cd_autorizacao.errors"
            class="invalid-feedback"
          >
            <div *ngIf="VForm.cd_autorizacao.errors.required">
              Código de autorizaçao é obrigatório
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>
</div>
<div class="modal-footer">
  <!-- Button to submit formVisita -->
  <button
    type="button"
    class="btn btn-primary"
    (click)="VisitaFormOnSubmit()"
    rippleEffect
  >
    <!-- (click)="modal.close('Accept click')" -->
    Registrar Entrada
  </button>
</div>
