import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @param {string} key
   *
   * @returns {any}
   */
  transform(items: any[], searchText: string, key: string): any[] {
    if (!items) return [];
    if (!searchText) return items;

    searchText = searchText.toLowerCase();
    //if key has dot, it means it is a nested object
    if (key.indexOf('.') !== -1) {
      let keys = key.split('.');
      return items.filter(it => {
        return it[keys[0]][keys[1]].toLowerCase().includes(searchText);
      });
    }


    return items.filter(it => {
      return it[key].toLowerCase().includes(searchText);
    });
  }
}
