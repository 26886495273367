import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject } from "rxjs";

import Swal from "sweetalert2";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { EquipamentoEventoService } from "../equipamento-evento.service";

@Component({
  selector: "edit-equipamento-evento",
  templateUrl: "./edit-equipamento-evento.component.html",
  styleUrls: ["./edit-equipamento-evento.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EditEquipamentoEventoComponent implements OnInit {
  @BlockUI("form-section") formBlockUI: NgBlockUI;
  @Input() public evento: any;
  /**
   * Constructor
   *
   * @param {FormBuilder} formBuilder
   * @param {NgbModal} modalService
   * @param {EquipamentoEventoService} _equipamentoEventoService
   */
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private _equipamentoEventoService: EquipamentoEventoService,
    private modalService: NgbModal
  ) {}
  public EventoForm: FormGroup;
  public submitted = false;
  public contentHeader: object;
  public motivos: any[] = [];
  public severidades: any[] = [
    { id: "alta", name: "Alta" },
    { id: "media", name: "Média" },
    { id: "baixa", name: "Baixa" },
    { id: "nenhuma", name: "Nenhuma" },
  ];

  get VForm() {
    return this.EventoForm.controls;
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._equipamentoEventoService
      .getMotivos(this.evento.EquipamentoEvento.tp_alarme)
      .subscribe((data: any) => {
        this.motivos = data;
      });

    this.EventoForm = this.formBuilder.group({
      alarme_motivo_id: [null, Validators.required],
      id_severidade: ["nenhuma", Validators.required],
      dc_tratamento: ["", Validators.required],
    });
  }

  EventoFormOnSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.EventoForm.invalid) {
      return;
    }
    this.formBlockUI.start("Registrando ...");
    this._equipamentoEventoService.update(this.evento).then(
      (data: any) => {
        this.modalService.dismissAll();
        this.EventoForm.reset();
        this.evento.alarme_motivo_id = null;
        this.evento.id_severidade = "nenhuma";
        this.evento.dc_tratamento = null;

        this.submitted = false;
        this.formBlockUI.stop();
        Swal.fire({
          icon: "success",
          title: "Evento registrado com sucesso",
          showConfirmButton: false,
          timer: 1500,
        });
      },
      (error) => {
        this.formBlockUI.stop();
        Swal.fire({
          icon: "error",
          title: "Não foi possível registrar evento",
          html: (error.text || error) + "",
        });
      }
    );
  }
}
