<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section id="home-page">
      <main>
        <add-visitante-card></add-visitante-card>
        <encomenda-card></encomenda-card>
        <div class="chaves">
          <div class="card cursor-pointer mb-0" (click)="openChaves()">
            <div class="card-header">
              <div>
                <h2 class="font-weight-bolder mb-0">Chaves</h2>
                <!-- <p class="card-text">Chaves</p> -->
              </div>
              <div class="avatar bg-light-primary  p-0 p-lg-25 m-0">
                <div class="avatar-content">
                  <i data-feather="key" class="font-medium-5"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <equipamento-evento-card type="alerta" class="avisos"></equipamento-evento-card>
        <autorizado-card></autorizado-card>
        <equipamento-evento-card type="panico" class="panico"></equipamento-evento-card>
        <app-equipamento-evento-preview></app-equipamento-evento-preview>
        <app-equipamento-evento-list class="h-100 d-inline-block w-100"></app-equipamento-evento-list>
        <reserva-card></reserva-card>
      </main>
      
    </section>
  </div>
</div>

<!-- <div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section id="home-page">
      <div class="row match-height">
        <div class="col-md-6 col-12">
          <add-visitante-card></add-visitante-card>
        </div>
        <div class="col-md-6 col-12">
          <add-ocorrencia-card></add-ocorrencia-card>
        </div>
        <div class="col-md-6 col-12">
          <add-autorizado-card></add-autorizado-card>
        </div>
        <div class="col-md-6 col-12">
          <reserva-card></reserva-card>
        </div>
      </div>
    </section>
    <app-encomenda-list></app-encomenda-list>
  </div>
</div> -->
