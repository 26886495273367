import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { VisitanteService } from "../visitante.service";

@Component({
  selector: "app-visitante-list",
  templateUrl: "./visitante-list.component.html",
  styleUrls: ["./visitante-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class VisitanteListComponent implements OnInit, OnDestroy {
  // public
  public data: any;
  public selectedOption = 10;
  public ColumnMode = ColumnMode;
  public searchValue = "";
  public page: any;

  // decorator
  @ViewChild(DatatableComponent) table: DatatableComponent;

  // private
  private tempData = [];
  private _unsubscribeAll: Subject<any>;
  public rows = [];
  public tempFilterData;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {EncomendaListService} _encomendaListService
   */
  constructor(
    private _visitanteService: VisitanteService,
    private _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService
  ) {
    this.page = this._visitanteService.paging;
    this._unsubscribeAll = new Subject();
  }

  /**
   * filterUpdate
   *
   * @param event
   */
  filterUpdate(event) {
    this._visitanteService.updateFilter({ search: event.target.value });
  }

  /**
   * Called whenever the user changes page
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  pageCallback(pageInfo: {
    count?: number;
    pageSize?: number;
    limit?: number;
    offset?: number;
  }) {
    this.page.offset = pageInfo.offset;
    this._visitanteService.updatePaging(this.page);
  }

  /**
   * Called whenever the user changes the sort order
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  sortCallback(sortInfo: {
    sorts: { dir: string; prop: string }[];
    column: {};
    prevValue: string;
    newValue: string;
  }) {
    // there will always be one "sort" object if "sortType" is set to "single"
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this._visitanteService.updatePaging(this.page);
  }

  ngOnInit(): void {
    // Subscribe config change
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        // If we have zoomIn route Transition then load datatable after 450ms(Transition will finish in 400ms)
        if (config.layout.animation === "zoomIn") {
          setTimeout(() => {
            this._visitanteService.onVisitanteListChanged
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((response) => {
                this.data = response.map(r => {
                  if(r.Pessoa.nr_cpf){
                    r.Pessoa.nr_cpf = r.Pessoa.nr_cpf.replace(/(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/, "$1.$2.XXX-$4");
                  }
                  return r;
                });
                this.rows = this.data;
                this.tempData = this.rows;
                this.tempFilterData = this.rows;
                this.page = this._visitanteService.paging;
              });
          }, 450);
        } else {
          this._visitanteService.onVisitanteListChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response) => {
              this.data = response.map(r => {
                if(r.Pessoa.nr_cpf){
                  r.Pessoa.nr_cpf = r.Pessoa.nr_cpf.replace(/(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/, "$1.$2.XXX-$4");
                }
                return r;
              });
              this.rows = this.data;
              this.tempData = this.rows;
              this.tempFilterData = this.rows;
              this.page = this._visitanteService.paging;
            });
        }
      });
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  viewData(id) {
    this._coreSidebarService
      .getSidebarRegistry("view-visitante-sidebar")
      .toggleOpen();
    this._visitanteService.getById(id);
  }
  download(){
      this._visitanteService.download().subscribe(
        (response: any) => {
          console.log(response);
          //const blob = new Blob([response], { type: "application/octet-stream" });
          const url = window.URL.createObjectURL(response.body);
          const link = document.createElement("a");
          link.href = url;
          link.download = "visitantes.csv";
          link.click();
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
