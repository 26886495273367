<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- Visitante List -->
    <section class="visitante-list-wrapper">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Lista de Visitantes</h4>
          <div ngbDropdown>
            <button
              class="btn-icon btn btn-transparent hide-arrow btn-sm"
              type="button"
              aria-haspopup="true"
              aria-expanded="false"
              ngbDropdownToggle
            >
              <i data-feather="more-vertical" id="brtn-options" class="font-medium-3"></i>
            </button>
            <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="brtn-options">
              <a ngbDropdownItem (click)="download()">Download CSV</a>
            </div>
          </div>
        </div>
        <!-- Visitante List Header -->
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <div class="d-flex align-items-center">
                <label class="d-flex align-items-center"
                  >Exibir
                  <select
                    class="form-control mx-25"
                    [(ngModel)]="page.limit"
                    (change)="pageCallback({ offset: 0 })"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </label>
                <!-- routerLink="/apps/encomenda/add" -->
                <!-- <button
                    class="btn btn-info ml-2"
                    rippleEffect
                    (click)="modalOpenForm(encomendaForm)"
                  >
                  <i data-feather="log-in" class="font-medium-2"></i> Registrar Recebimento
                  </button>
                  <button
                    class="btn btn-success ml-2"
                    rippleEffect
                    (click)="modalOpenVisitanteDeliver()"
                  >
                  <i data-feather="log-out" class="font-medium-2"></i> Registrar Entrega
                  </button> -->
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12 d-flex justify-content-end align-items-center pr-3">
            <label class=""
              ><input
                [(ngModel)]="searchValue"
                name="searchValue"
                type="search"
                class="form-control"
                placeholder="Pesquisar..."
                (keyup)="filterUpdate($event)"
                (search)="filterUpdate($event)"
            /></label>
          </div>
        </div>
        <!--/ Visitante List Header -->

        <!-- Visitante List Table -->
        <ngx-datatable
        [rows]="rows"
        [rowHeight]="50"
        class="bootstrap core-bootstrap"
        [columnMode]="ColumnMode.force"
        [headerHeight]="50"
        [footerHeight]="50"
        [scrollbarH]="true"
        [externalPaging]="true"
        [externalSorting]="true"
        [count]="page.count"
        [offset]="page.offset"
        [limit]="page.limit"
        [sortType]="'single'"
        (page)="pageCallback($event)"
        (sort)="sortCallback($event)"
        >
          <ngx-datatable-column name="#" prop="Visita.id" [width]="10">
            <ng-template let-id="value" ngx-datatable-cell-template>
              <a
              (click)="viewData(id)"
              data-toggle="modal"
              data-target="#view-visitante-sidebar"
                class="font-weight-bold"
                >#{{ id }}</a
              >
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="Entrada"
            prop="Visita.dt_entrada"
            [width]="70"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <div class="cell-line-height">
                <span class="d-block text-nowrap">{{ value }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          
          <ngx-datatable-column
            name="Tipo"
            prop="Visita.dv_tp_visita"
            [width]="70"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <div class="cell-line-height">
                <span class="d-block text-nowrap">{{ value }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="Visitante"
            prop="Pessoa.nm_pessoa"
            [width]="150"
          >
            <ng-template
              let-row="row"
              let-name="value"
              ngx-datatable-cell-template
            >
              <div class="d-flex align-items-center">
                <div *ngIf="row.Pessoa.dc_picture_url; else customAvatar">
                  <img
                    class="rounded-circle mr-1"
                    src="{{ row.Pessoa.dc_picture_url }}"
                    height="32"
                    width="32"
                    alt="datatable-avatar"
                  />
                </div>
                <ng-template #customAvatar>
                  <div class="avatar mr-1 ml-0">
                    <div class="avatar-content">{{ name | initials }}</div>
                  </div>
                </ng-template>
                <div class="cell-line-height">
                  <span
                    class="font-weight-bold d-block text-nowrap font-medium-1"
                    >{{ name | shortname}}</span
                  >
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="Unidade"
            prop="Unidade.nm_display"
            [width]="50"
          >
            <ng-template
              let-row="row"
              let-name="value"
              ngx-datatable-cell-template
            >
              <div class="cell-line-height">
                <span class="d-block text-nowrap">{{ name }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          

          <ngx-datatable-column name="Ações" [width]="40" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="d-flex align-items-center col-actions">
                <!-- <a
                    class="mr-1"
                    href="javascript:void(0);"
                    container="body"
                    placement="top"
                    ngbTooltip="Send Mail"
                    ><i size="18" data-feather="send"></i
                  ></a> -->
                <a
                  class="mr-1"
                  data-toggle="modal"
                  data-target="#view-visitante-sidebar"
                  (click)="viewData(row.Visita.id)"
                  container="body"
                  placement="top"
                  ngbTooltip="Visualizar Detalhes"
                  ><i size="18" data-feather="eye"></i
                ></a>
                <!-- <div ngbDropdown container="body">
                    <button
                      ngbDropdownToggle
                      type="button"
                      class="btn icon-btn btn-sm hide-arrow px-0"
                      rippleEffect
                    >
                      <i
                        data-feather="more-vertical"
                        size="18"
                        class="cursor-pointer"
                      ></i>
                    </button>
                    <div ngbDropdownMenu>
                      <a href="javascript:void(0)" ngbDropdownItem
                        ><i data-feather="download" class="mr-1"></i>Download</a
                      ><a
                        routerLink="/apps/encomenda/edit/{{ row.id }}"
                        ngbDropdownItem
                        ><i data-feather="edit" class="mr-1"></i>Edit</a
                      ><a href="javascript:void(0)" ngbDropdownItem
                        ><i data-feather="trash" class="mr-1"></i>Delete</a
                      ><a href="javascript:void(0)" ngbDropdownItem
                        ><i data-feather="copy" class="mr-1"></i>Duplicate</a
                      >
                    </div>
                  </div> -->
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
        <!--/ Visitante List Table -->
      </div>
    </section>
    <!--/ Visitante List -->
  </div>
  <core-sidebar
    class="modal modal-slide-in sidebar-todo-modal fade"
    name="view-visitante-sidebar"
    overlayClass="modal-backdrop"
    hideOnEsc="true"
  >
    <app-view-visitante-sidebar></app-view-visitante-sidebar>
  </core-sidebar>
</div>
