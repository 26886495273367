import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { EncomendaListService } from "../encomenda-list/encomenda-list.service";
import { EncomendaDeliverService } from "./encomenda-deliver.service";

@Component({
  selector: "app-encomenda-deliver",
  templateUrl: "./encomenda-deliver.component.html",
  styleUrls: ["./encomenda-deliver.component.scss"],
})
export class EncomendaDeliverComponent implements OnInit, OnDestroy {
  availableDevices: MediaDeviceInfo[];
  selectedDevice: MediaDeviceInfo = null;
  hasDevices: boolean;
  deviceSelected: string;
  codigo_validacao: string = "";
  code_invalid: boolean = false;
  code_valid: boolean = false;
  encomenda: any = null;
  loading: boolean = false;
  init: boolean = true;
  moradores: any[] = [];
  public EncomendaForm: FormGroup;
  public submitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private encomendaDeliverService: EncomendaDeliverService,
    private _encomendaListService: EncomendaListService
  ) {}

  ngOnInit() {
    this.EncomendaForm = this.formBuilder.group({
      usuario_retirada_id: [
        this.encomenda?.usuario_retirada_id,
        Validators.required,
      ],
    });
  }

  ngOnDestroy() {}

  get VForm() {
    return this.EncomendaForm.controls;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
    if (this.hasDevices) {
      setTimeout(() => {
        this.onDeviceSelectChange(devices[0]);
        this.init = false;
      }, 500);
    }
  }
  onScanSuccess(resultString: string) {
    if (!this.codigo_validacao) {
      this.codigo_validacao = resultString;
      this.loading = true;
      this.verificarCodigo();
    }
  }
  verificarCodigo() {
    this.code_invalid = false;
    this.code_valid = false;
    this.encomendaDeliverService.getByCode(this.codigo_validacao).subscribe(
      (res) => {
        this.loading = false;
        console.log(res);
        if (res) {
          this.encomenda = res;
          this.code_valid = true;
          if (this.encomenda?.Encomenda.unidade_id) {
            this.encomendaDeliverService
              .getMoradores(this.encomenda?.Encomenda.unidade_id)
              .subscribe((res) => {
                this.moradores = res;
              });
          }
          Swal.fire({
            title: "Código válido",
            text: "O código é válido",
            icon: "success",
            timer: 2000,
          });
        } else {
          this.encomenda = null;
          this.code_invalid = true;
          this.codigo_validacao = "";
          Swal.fire({
            title: "Código inválido",
            text: "O código de encomenda não é válido",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      },
      (error) => {
        this.loading = false;
        this.code_invalid = true;
        this.codigo_validacao = "";
        Swal.fire({
          title: "Código inválido",
          text: "O código de encomenda não é válido",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    );
  }
  onDeviceSelectChange(selected) {
    const selectedStr = selected?.deviceId || "";
    if (this.selectedDevice?.deviceId === selectedStr) {
      return;
    }
    this.deviceSelected = selectedStr;
    const device = this.availableDevices.find(
      (x) => x.deviceId === selected?.deviceId
    );
    this.selectedDevice = device || undefined;
    console.log(this.selectedDevice, "this.selectedDevice");
  }
  ReiniciarEntrega() {
    this.codigo_validacao = "";
    this.code_invalid = false;
    this.code_valid = false;
    this.encomenda = null;
    this.loading = false;
  }

  EncomendaFormOnSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.EncomendaForm.invalid) {
      return;
    }

    this._encomendaListService
      .addEntrega({usuario_retirada_id: this.encomenda.usuario_retirada_id, id: this.encomenda.Encomenda.id})
      .then((data: any) => {
        this.modalService.dismissAll();
        this.EncomendaForm.reset();
        this.submitted = false;
        Swal.fire({
          icon: "success",
          title: "Entrega registrada com sucesso",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Não foi possível registrar entrega",
          html: (error.text || error) + "",
        });
      });
  }
}
