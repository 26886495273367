import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChavesListComponent } from "../apps/chaves/chaves-list/chaves-list.component";
import { AuthenticationService } from "app/auth/service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EquipamentoEventoService } from "../apps/equipamento-evento/equipamento-evento.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  /**
   * Constructor
   *
   * @param {NgbModal} modalService
   */
  constructor(
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private _equipamentoEventoService: EquipamentoEventoService
  ) {
    this._unsubscribeAll = new Subject();
  }

  public contentHeader: object;

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  /**
   * On init
   */
  ngOnInit() {
    this.authService.onNewEventSubject
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response) => {
        console.log(response);
        if (response && response.event_type == "event") {
          this._equipamentoEventoService.getDataTableRows();
          this._equipamentoEventoService.getPendentes('alerta');
          this._equipamentoEventoService.getPendentes('panico');
        }
      });
    this.contentHeader = {
      headerTitle: "Home",
      actionButton: true,
      breadcrumb: {
        type: "",
        links: [
          {
            name: "Home",
            isLink: true,
            link: "/",
          },
          {
            name: "Sample",
            isLink: false,
          },
        ],
      },
    };
  }

  openChaves() {
    const modalRef = this.modalService.open(ChavesListComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
      centered: true,
      //scrollable: true,
      modalDialogClass: "modal-1000",
    });
  }
}
