<div class="card text-center mb-0 h-100 d-inline-block w-100">
  <div class="card-body">
    <div class="avatar bg-light-primary p-50 m-0 mb-1">
      <div class="avatar-content">
        <i data-feather="user" class="font-medium-5"></i>
      </div>
    </div>
    <h2 class="font-weight-bolder">Visitantes</h2>

    <!-- <div>
      <div
        *ngFor="let visit of lastVisits"
        class="employee-task d-flex justify-content-between align-items-center"
      >
        <div class="media">
          <div class="avatar mr-75">
            <img
              src="{{
                visit.Pessoa.dc_picture_url ||
                  'assets/images/avatars/default.jpg'
              }}"
              class="rounded"
              width="42"
              height="42"
              alt="Avatar"
            />
          </div>
          <div class="media-body my-auto">
            <h6 class="mb-0">{{ visit.Pessoa.nm_pessoa }}</h6>
            <small *ngIf="visit.Visita.tp_destino == 'unidade'"
              >{{ visit.Visita.dv_tp_destino }}:
              {{ visit.Unidade.nm_display }}</small
            >
            <small *ngIf="visit.Visita.tp_destino == 'condominio'"
              >{{ visit.Visita.dv_tp_destino }}:
              {{ visit.Visita.nm_visitado }}</small
            >
          </div>
        </div>
        <div>
          <div class="d-flex align-items-center">
            <small class="text-muted mr-75">{{
              visit.Visita.dt_entrada
            }}</small>
            <button
              type="button"
              rippleeffect
              (click)="registerExit(visit.Visita)"
              *ngIf="!visit.Visita.dt_saida"
              class="btn btn-primary btn-icon btn-sm ml-auto waves-effect waves-float waves-light"
            >
              Registrar saída
            </button>
          </div>
          <div class="d-flex align-items-center" *ngIf="visit.Visita.dt_saida">
            <small class="text-muted mr-75">{{ visit.Visita.dt_saida }}</small>
          </div>
        </div> -->
    <!-- <div class="d-flex align-items-center">
        <small class="text-muted mr-75">{{ visit.Visita.dt_entrada }}</small>
        <div class="employee-task-chart-primary-1"></div>
      </div> -->
    <!-- </div>
    </div>
    <div *ngIf="!lastVisits || lastVisits.length == 0" class="text-center py-4">
      <h3>Nenhuma visita registrada</h3>
      <p>Utilize o botão abaixo para registrar uma nova visita.</p>
    </div> -->
    <div class="d-flex justify-content-between align-items-center mt-2 gap-3">
      <button
        type="button"
        class="btn btn-primary"
        rippleEffect
        (click)="modalOpenForm(visitForm)"
      >
        Entrada
      </button>
      <button
        type="button"
        class="btn btn-success ml-2"
        rippleEffect
        (click)="modalOpenDoor(doorModal)"
      >
        Abrir Porta
      </button>
      <!-- <button type="button" class="btn btn-danger" rippleEffect>Saída</button> -->
    </div>
    <ng-template #doorModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title">Abrir Porta</h4>
        <button
          type="button"
          class="close"
          (click)="modal.dismiss('Cross click')"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body" tabindex="0" ngbAutofocus *ngIf="doors">
        <ul
          ngbNav
          #nav="ngbNav"
          class="nav nav-pills"
          (navChange)="filterDoor($event)"
        >
          <li ngbNavItem>
            <a ngbNavLink class="d-flex align-items-center"
              ><i data-feather="repeat" class="mr-sm-50"></i
              ><span class="d-none d-sm-block">Todos</span></a
            >
          </li>
          <li ngbNavItem>
            <a ngbNavLink class="d-flex align-items-center"
              ><i data-feather="log-in" class="mr-sm-50"></i
              ><span class="d-none d-sm-block">Entrada</span></a
            >
          </li>
          <li ngbNavItem>
            <a ngbNavLink class="d-flex align-items-center"
              ><i data-feather="log-out" class="mr-sm-50"></i
              ><span class="d-none d-sm-block">Saída</span></a
            >
          </li>
        </ul>
        <ng-container *ngFor="let item of filteredDoors | keyvalue">
          <div *ngIf="item.value && item.value.length > 0">
            <h6 class="mt-1">{{ item.key }}</h6>

            <div
              class="d-flex flex-wrap justify-content-start align-items-center gap-3"
            >
              <button
                type="button"
                class="btn btn-primary btn-door"
                rippleEffect
                *ngFor="let door of item.value"
                [ngClass]="{
                  'btn-success': door.Equipamento.nm_equipamento
                    .toLowerCase()
                    .includes('ent'),
                  'btn-danger': door.Equipamento.nm_equipamento
                    .toLowerCase()
                    .includes('sai')
                }"
                (click)="openDoor(door.Equipamento.id)"
              >
                {{ door.Equipamento.nm_equipamento }}
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>

    <!-- Modal -->
    <ng-template #visitForm let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Registrar Visitante</h4>
        <button
          type="button"
          class="close"
          (click)="closeModal()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        class="modal-body"
        tabindex="0"
        ngbAutofocus
        *ngIf="unidades && pessoaPerfis"
      >
        <form
          [formGroup]="VisitaForm"
          (ngSubmit)="VisitaFormOnSubmit()"
          #formVisita
          *blockUI="'form-section'"
          class="form-block"
        >
          <h6 class="mb-2">1. Dados do Visitante {{ tipo_documento }}</h6>
          <div class="row">
            <div class="col-md-8 col-12">
              <div class="row">
                <div class="col-md-6 col-12">
                  <label>Documento: </label>
                  <div class="form-group">
                    <ng-select
                      [items]="tipo_documentos"
                      [clearable]="false"
                      bindLabel="name"
                      bindValue="id"
                      name="tp_documento"
                      [(ngModel)]="visita.tp_documento"
                      (change)="changeTipoDocumento($event)"
                      formControlName="tp_documento"
                      [ngClass]="{
                        'is-invalid': submitted && VForm.tp_documento.errors
                      }"
                    >
                    </ng-select>
                    <div
                      *ngIf="submitted && VForm.tp_documento.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="VForm.tp_documento.errors.required">
                        Tipo de documento é obrigatório
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6 col-12"
                  *ngIf="visita.tp_documento == 'cpf'"
                  [formGroup]="VForm['Pessoa']"
                >
                  <label (click)="showObs()">CPF: <i class="feather icon-info" *ngIf="visita.Pessoa.dc_observacoes"></i></label>
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="CPF"
                      class="form-control"
                      name="nr_cpf"
                      [(ngModel)]="visita.Pessoa.nr_cpf"
                      mask="000.000.000-00"
                      [dropSpecialCharacters]="false"
                      (change)="changeCpf($event)"
                      formControlName="nr_cpf"
                      [ngClass]="{
                        'is-invalid':
                          submitted && VForm['Pessoa'].controls.nr_cpf.errors
                      }"
                    />
                    <div
                      *ngIf="
                        submitted && VForm['Pessoa'].controls.nr_cpf.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="VForm['Pessoa'].controls.nr_cpf.errors.required"
                      >
                        CPF é obrigatório
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6 col-12"
                  *ngIf="visita.tp_documento == 'rg'"
                  [formGroup]="VForm['Pessoa']"
                >
                  <label>RG: </label>
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="RG"
                      class="form-control"
                      name="nr_rg"
                      [(ngModel)]="visita.Pessoa.nr_rg"
                      (change)="changeRg($event)"
                      formControlName="nr_rg"
                      [ngClass]="{
                        'is-invalid':
                          submitted && VForm['Pessoa'].controls.nr_rg.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && VForm['Pessoa'].controls.nr_rg.errors"
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="VForm['Pessoa'].controls.nr_rg.errors.required"
                      >
                        RG é obrigatório
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12" [formGroup]="VForm['Pessoa']">
                  <label>Nome: </label>
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Nome Completo"
                      class="form-control"
                      name="nm_pessoa"
                      [(ngModel)]="visita.Pessoa.nm_pessoa"
                      formControlName="nm_pessoa"
                      [ngClass]="{
                        'is-invalid':
                          submitted && VForm['Pessoa'].controls.nm_pessoa.errors
                      }"
                    />
                    <div
                      *ngIf="
                        submitted && VForm['Pessoa'].controls.nm_pessoa.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          VForm['Pessoa'].controls.nm_pessoa.errors.required
                        "
                      >
                        Nome é obrigatório
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 col-12" [formGroup]="VForm['Pessoa']">
                  <label>Telefone: </label>
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Telefone"
                      class="form-control"
                      name="nr_telefone"
                      [(ngModel)]="visita.Pessoa.nr_telefone"
                      mask="(00) 00000-0000"
                      [dropSpecialCharacters]="false"
                      formControlName="nr_telefone"
                      [ngClass]="{
                        'is-invalid':
                          submitted &&
                          VForm['Pessoa'].controls.nr_telefone.errors
                      }"
                    />
                    <div
                      *ngIf="
                        submitted && VForm['Pessoa'].controls.nr_telefone.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          VForm['Pessoa'].controls.nr_telefone.errors.required
                        "
                      >
                        Telefone é obrigatório
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8 col-12" [formGroup]="VForm['Pessoa']">
                  <label>E-mail: </label>
                  <div class="form-group">
                    <input
                      type="email"
                      placeholder="E-mail"
                      class="form-control"
                      name="dc_email"
                      [(ngModel)]="visita.Pessoa.dc_email"
                      formControlName="dc_email"
                      [ngClass]="{
                        'is-invalid':
                          submitted && VForm['Pessoa'].controls.dc_email.errors
                      }"
                    />
                    <div
                      *ngIf="
                        submitted && VForm['Pessoa'].controls.dc_email.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          VForm['Pessoa'].controls.dc_email.errors.required
                        "
                      >
                        E-mail é obrigatório
                      </div>
                      <div
                        *ngIf="
                          VForm['Pessoa'].controls.dc_email.errors.minlength
                        "
                      >
                        Informe o e-mail completo
                      </div>
                      <div
                        *ngIf="
                          VForm['Pessoa'].controls.dc_email.errors.maxlength
                        "
                      >
                        Tamanho máximo de 100 caracteres
                      </div>
                      <div
                        *ngIf="VForm['Pessoa'].controls.dc_email.errors.pattern"
                      >
                        Informe um e-mail válido
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6" [formGroup]="VForm['Pessoa']">
                  <div class="form-group">
                    <label for="pessoa_perfil_id">Perfil</label>
                    <ng-select
                      *ngIf="pessoaPerfis && pessoaPerfis.length > 0"
                      [items]="pessoaPerfis"
                      [clearable]="false"
                      bindLabel="nm_perfil"
                      bindValue="id"
                      name="pessoa_perfil_id"
                      [(ngModel)]="visita.Pessoa.pessoa_perfil_id"
                      formControlName="pessoa_perfil_id"
                      [ngClass]="{
                        'is-invalid':
                          submitted &&
                          VForm['Pessoa'].controls.pessoa_perfil_id.errors
                      }"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        submitted &&
                        VForm['Pessoa'].controls.pessoa_perfil_id.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          VForm['Pessoa'].controls.pessoa_perfil_id.errors
                            .required
                        "
                      >
                        Perfil é obrigatório
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6" [formGroup]="VForm['Pessoa']">
                  <div class="form-group">
                    <label class="d-block mb-1">Biometria</label>
                    <div
                      class="custom-control custom-checkbox custom-control-inline"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="pin"
                        name="id_pin"
                        [(ngModel)]="visita.Pessoa.id_pin"
                        formControlName="id_pin"
                      />
                      <label class="custom-control-label" for="pin"
                        >Utilizar documento como PIN</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="visita.Pessoa.nm_unidades">
                <div class="col-12">
                  <div
                    class="d-flex flex-wrap justify-content-start align-items-center mb-1"
                  >
                    <div
                      class="badge badge-light-primary profile-badge mr-1"
                      *ngFor="
                        let unidade of visita.Pessoa.nm_unidades.split(', ')
                      "
                    >
                      {{ unidade }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <fieldset>
                    <legend>Veículo</legend>
                    <div class="row">
                      <div class="col-md-8">
                        <label>Modelo: </label>
                        <div class="form-group">
                          <input
                            type="text"
                            placeholder="Marca e modelo do veículo"
                            class="form-control"
                            name="dc_modelo"
                            [(ngModel)]="visita.dc_modelo"
                            formControlName="dc_modelo"
                            [ngClass]="{
                              'is-invalid': submitted && VForm.dc_modelo.errors
                            }"
                          />
                          <div
                            *ngIf="submitted && VForm.dc_modelo.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="VForm.dc_modelo.errors.required">
                              Modelo do veículo é obrigatório
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <label>Placa: </label>
                        <div class="form-group">
                          <input
                            type="text"
                            placeholder="XXX-9A99"
                            class="form-control"
                            name="dc_placa"
                            [(ngModel)]="visita.dc_placa"
                            mask="SSS-0A00"
                            [dropSpecialCharacters]="false"
                            formControlName="dc_placa"
                            [ngClass]="{
                              'is-invalid': submitted && VForm.dc_placa.errors
                            }"
                            (change)="changePlaca($event)"
                          />
                          <div
                            *ngIf="submitted && VForm.dc_placa.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="VForm.dc_placa.errors.required">
                              Placa é obrigatório
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="visita.dc_placa">
                      <div class="col-md-8">
                        <label>CNH: </label>
                        <div class="form-group">
                          <input
                            type="text"
                            placeholder="CNH"
                            class="form-control"
                            name="nr_cnh"
                            [(ngModel)]="visita.nr_cnh"
                            formControlName="nr_cnh"
                            [ngClass]="{
                              'is-invalid': submitted && VForm.nr_cnh.errors
                            }"
                          />
                          <div
                            *ngIf="submitted && VForm.nr_cnh.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="VForm.nr_cnh.errors.required">
                              CNH é obrigatório
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <label>Validade: </label>
                        <div class="form-group">
                          <input
                            type="date"
                            class="form-control"
                            name="dt_validade_cnh"
                            [(ngModel)]="visita.dt_validade_cnh"
                            formControlName="dt_validade_cnh"
                            [ngClass]="{
                              'is-invalid':
                                submitted && VForm.dt_validade_cnh.errors
                            }"
                          />
                          <div
                            *ngIf="submitted && VForm.dt_validade_cnh.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="VForm.dt_validade_cnh.errors.required">
                              Data de validade da CNH é obrigatório
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-12">
                  <label>Visita: </label>
                  <div class="form-group">
                    <ng-select
                      [items]="tipo_visitas"
                      bindLabel="name"
                      bindValue="id"
                      [loading]="false"
                      name="tp_visita"
                      [(ngModel)]="visita.tp_visita"
                      [clearable]="false"
                      formControlName="tp_visita"
                      (change)="changeTipoVisita($event)"
                      [ngClass]="{
                        'is-invalid': submitted && VForm.tp_visita.errors
                      }"
                    >
                    </ng-select>
                    <div
                      *ngIf="submitted && VForm.tp_visita.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="VForm.tp_visita.errors.required">
                        Visita é obrigatório
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6 col-12"
                  *ngIf="visita.tp_visita == 'servico'"
                  [formGroup]="VForm['Pessoa']"
                >
                  <label>Empresa: </label>
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Nome Completo"
                      class="form-control"
                      name="nm_empresa"
                      [(ngModel)]="visita.Pessoa.nm_empresa"
                      formControlName="nm_empresa"
                      [ngClass]="{
                        'is-invalid':
                          submitted &&
                          VForm['Pessoa'].controls.nm_empresa.errors
                      }"
                    />
                    <div
                      *ngIf="
                        submitted && VForm['Pessoa'].controls.nm_empresa.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          VForm['Pessoa'].controls.nm_empresa.errors.required
                        "
                      >
                        Empresa é obrigatório
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <!-- header media -->
              <div *ngIf="imageMode == 'file'">
                <div class="row">
                  <div class="col-12 text-center">
                    <a href="javascript:void(0);">
                      <img
                        [src]="avatarImage"
                        class="rounded mb-75 object-fit-cover shadow"
                        alt=""
                        height="200"
                        width="200"
                        (click)="file.click()"
                      />
                    </a>
                  </div>
                  <div class="col-12 text-center">
                    <div class="p-50">
                      <button
                        type="button"
                        (click)="file.click()"
                        for="account-upload"
                        class="btn btn-sm btn-primary btn-block mb-75"
                        rippleEffect
                      >
                        Carregar Imagem
                      </button>
                      <input
                        type="file"
                        #file
                        id="account-upload"
                        hidden
                        accept="image/*"
                        (change)="uploadImage($event)"
                      />
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-secondary btn-block mb-75"
                        rippleEffect
                        (click)="setImageMode('camera')"
                      >
                        Webcam
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-secondary btn-block mb-75"
                        rippleEffect
                        (click)="setImageMode('equipment')"
                        *ngIf="cameras && cameras.length > 0"
                      >
                        Equipamento
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="imageMode == 'camera'">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <div class="rounded mb-75 object-fit-cover">
                      <webcam
                        [trigger]="invokeObservable"
                        (imageCapture)="captureImg($event)"
                        [width]="200"
                        [height]="200"
                      ></webcam>
                    </div>
                  </div>
                  <div class="col-md-12 text-center">
                    <button
                      type="button"
                      class="btn btn-sm btn-primary mb-75 mr-75"
                      (click)="getSnapshot()"
                    >
                      Capturar
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-secondary mb-75"
                      rippleEffect
                      (click)="setImageMode('file')"
                    >
                      Voltar
                    </button>
                  </div>
                </div>
              </div>

              <div *ngIf="imageMode == 'equipment'">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="equipamento_id">Equipamento</label>
                      <ng-select
                        [items]="cameras"
                        bindLabel="Equipamento.nm_equipamento"
                        bindValue="Equipamento.id"
                        [loading]="false"
                        name="equipamento_id"
                        [(ngModel)]="equipamento_id"
                        [clearable]="false"
                        (change)="changeEquipamento($event)"
                        [ngModelOptions]="{ standalone: true }"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-12 text-center" *ngIf="!equipamento_id">
                    <p>Nenhum equipamento disponível</p>
                  </div>
                  <div class="col-12 text-center" *ngIf="equipamento_id > 0">
                    <img
                      [src]="avatarImage"
                      class="rounded mb-75 object-fit-cover shadow"
                      alt=""
                      height="356"
                      width="200"
                    />
                  </div>

                  <div class="col-md-12 text-center">
                    <button
                      type="button"
                      class="btn btn-sm btn-primary mb-75 mr-75"
                      (click)="getEquipmentSnapshot()"
                    >
                      Capturar
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-secondary mb-75"
                      rippleEffect
                      (click)="cancelEquipment()"
                    >
                      Voltar
                    </button>
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="button"
                  class="btn btn-sm btn-block my-75"
                  [ngClass]="{
                    'btn-primary': visita.Pessoa.id_facial,
                    'btn-outline-primary': !visita.Pessoa.id_facial
                  }"
                  rippleEffect
                  (click)="openCropModal(cropModal)"
                  [disabled]="!(imageMode == 'file' && visita.image)"
                >
                  {{
                    visita.Pessoa.id_facial
                      ? "Desativar Biometria Facial"
                      : "Ativar Biometria Facial"
                  }}
                </button>
              </div>

              <!--/ header media -->
            </div>
          </div>

          <h6 class="mb-2">2. Destino</h6>
          <div class="row">
            
            <div class="col-md-4 col-12">
              <label>Destino: </label>
              <div class="form-group">
                <ng-select
                  [items]="destinos"
                  bindLabel="name"
                  bindValue="id"
                  [loading]="false"
                  name="tp_destino"
                  [(ngModel)]="visita.tp_destino"
                  [clearable]="false"
                  formControlName="tp_destino"
                  (change)="changeTipoDestino($event)"
                  [ngClass]="{
                    'is-invalid': submitted && VForm.tp_destino.errors
                  }"
                >
                </ng-select>
                <div
                  *ngIf="submitted && VForm.tp_destino.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="VForm.tp_destino.errors.required">
                    Destino é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <label>Horário Limite (Hr. Brasília): <i class="fa fa-info-circle" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Inserir horário de acesso ao condomínio (entrada e/ou saída) limite na data atual."></i></label>
              <div class="form-group">
               <input 
                type="text"
                class="form-control"
                name="hr_termino"
                mask="00:00"
                formControlName="hr_termino"
                [ngClass]="{
                  'is-invalid': submitted && VForm.hr_termino.errors
                }"
                [dropSpecialCharacters]="false"
                [(ngModel)]="visita.hr_termino"
                />
                <div
                  *ngIf="submitted && VForm.hr_termino.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="VForm.hr_termino.errors.required">
                    Horário limite é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-12" *ngIf="visita.tp_destino == 'unidade'">
              <label>Unidade: </label>
              <div class="form-group">
                <ng-select
                  [items]="unidades"
                  bindLabel="Unidade.nm_display"
                  bindValue="Unidade.id"
                  [loading]="false"
                  name="unidade_id"
                  [(ngModel)]="visita.unidade_id"
                  formControlName="unidade_id"
                  [ngClass]="{
                    'is-invalid': submitted && VForm.unidade_id.errors
                  }"
                  (change)="changeUnidade($event)"
                >
                </ng-select>
                <div
                  *ngIf="submitted && VForm.unidade_id.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="VForm.unidade_id.errors.required">
                    Unidade é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-12" *ngIf="visita.tp_destino == 'unidade' && unidade">
              <label>Situação: </label>
              <div class="form-group">
                <input type="text" class="form-control"
                [ngModelOptions]="{standalone: true}" 
                [(ngModel)]="unidade.SituacaoImovel.nm_situacao" disabled />
              </div>
            </div>
            <div class="col-md-4 col-12" *ngIf="visita.tp_destino == 'unidade' && unidade">
              <label>Ramal: </label>
              <div class="form-group">
                <input type="text" class="form-control"
                [ngModelOptions]="{standalone: true}" 
                [(ngModel)]="unidade.Unidade.nr_ramal" disabled />
              </div>
            </div>
            <div
              class="col-12"
              ngClass="{'col-md-4':visita.tp_destino == 'unidade','col-md-8':visita.tp_destino=='condominio'}"
            >
              <label>Visitado: </label>
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Nome do visitado"
                  class="form-control"
                  name="nm_visitado"
                  [(ngModel)]="visita.nm_visitado"
                  formControlName="nm_visitado"
                  [ngClass]="{
                    'is-invalid': submitted && VForm.nm_visitado.errors
                  }"
                  *ngIf="visita.tp_destino == 'condominio' || !usuarios"
                />
                <ng-select
                  *ngIf="visita.tp_destino != 'condominio' && usuarios"
                  [items]="usuarios"
                  bindLabel="nm_pessoa"
                  bindValue="nm_pessoa"
                  [loading]="false"
                  name="nm_visitado"
                  [(ngModel)]="visita.nm_visitado"
                  formControlName="nm_visitado"
                  [ngClass]="{
                    'is-invalid': submitted && VForm.nm_visitado.errors
                  }"
                >
                </ng-select>
                <div
                  *ngIf="submitted && VForm.nm_visitado.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="VForm.nm_visitado.errors.required">
                    Visitado é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <!-- Button to submit formVisita -->
        <!-- <button
          type="button"
          class="btn btn-primary"
          (click)="VisitaFormOnSubmit(false)"
          rippleEffect
        >
          Salvar para depois
        </button> -->
        <button
          type="button"
          class="btn btn-primary"
          (click)="VisitaFormOnSubmit(true)"
          rippleEffect
        >
          <!-- (click)="modal.close('Accept click')" -->
          Registrar Entrada
        </button>
      </div>
    </ng-template>
    <ng-template #cropModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Recortar Imagem</h4>
        <button
          type="button"
          class="close"
          (click)="modal.dismiss('Cross click')"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" tabindex="0" ngbAutofocus *ngIf="visita.image">
        <div class="row">
          <div class="col-6 text-center mb-1">
            <!-- [cropperStaticWidth]="300"
                        [cropperStaticHeight]="300" -->
            <div class="image-cropper-wrapper">
              <image-cropper
                [imageBase64]="avatarImage"
                [maintainAspectRatio]="true"
                [aspectRatio]="1 / 1"
                format="jpeg"
                [resizeToWidth]="300"
                [resizeToHeight]="300"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"
                output="base64"
              ></image-cropper>
            </div>
          </div>
          <div class="col-6 text-center mb-1">
            <img [src]="croppedImage" />
          </div>
        </div>
        <div class="col-12 text-center">
          <div class="p-50">
            <button
              type="button"
              class="btn btn-sm btn-primary mb-75 mr-75"
              (click)="cropImage()"
            >
              Confirmar
            </button>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary mb-75"
              rippleEffect
              (click)="modal.dismiss('Cross click')"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- / Modal -->
  </div>
</div>
