<div class="card cursor-pointer mb-0" (click)="openDialog()">
  <div class="card-header">
    <div>
      <h2 class="font-weight-bolder mb-0">Autorizados</h2>
    </div>
    <div class="avatar bg-light-info p-0 p-lg-25 m-0">
      <div class="avatar-content">
        <i data-feather="clock" class="font-medium-5"></i>
      </div>
    </div>
  </div>
</div>
