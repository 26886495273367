import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WebcamImage } from "ngx-webcam";
import { Observable, Subject } from "rxjs";
import { VisitanteService } from "../visitante.service";
import Swal from "sweetalert2";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ImageCroppedEvent, LoadedImage } from "ngx-image-cropper";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "add-visitante-card",
  templateUrl: "./add-visitante-card.component.html",
  styleUrls: ["./add-visitante-card.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddVisitanteCardComponent implements OnInit, OnDestroy {
  /**
   * Constructor
   *
   * @param {FormBuilder} formBuilder
   * @param {NgbModal} modalService
   * @param {VisitanteService} visitanteService
   */
  constructor(
    private formBuilder: FormBuilder,
    private visitanteService: VisitanteService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) {}
  @BlockUI("form-section") formBlockUI: NgBlockUI;
  public VisitaForm: FormGroup;
  public submitted = false;
  public contentHeader: object;
  public doorType: any = "all";
  // public lastVisits: any[] = [];
  public cameras: any[] = [];
  public doors: any[] = [];
  public filteredDoors: any[] = [];
  public unidades: any[] = [];
  public pessoaPerfis: any[] = [];
  public usuarios: any[] = [];
  public unidade: any = null;
  public tipo_documentos: any[] = [
    { id: "cpf", name: "CPF" },
    { id: "rg", name: "RG" },
  ];
  public tipo_visitas: any[] = [
    { id: "particular", name: "Particular" },
    { id: "servico", name: "Serviço" },
  ];
  public destinos: any[] = [
    { id: "condominio", name: "Condomínio" },
    { id: "unidade", name: "Unidade" },
  ];

  public equipamento_id: any = null;
  public cameraInterval: any = null;

  public visita: any = {
    id: null,
    tp_documento: "cpf",
    tp_destino: "unidade",
    tp_visita: "particular",
    Pessoa: {
      id: null,
      nr_cpf: "",
      nr_rg: "",
      nm_pessoa: "",
      dc_email: "",
      nr_telefone: "",
      nm_empresa: "",
      pessoa_perfil_id: null,
      nm_unidades: "",
      id_pin: false,
      id_facial: false,
    },
    unidade_id: null,
    // condominio_id: 0,
    // dt_entrada: new Date(),
    // dt_saida: "",
    nm_visitado: "",
    hr_termino: "23:59",
    image: null,
  };
  croppedImage: any = "";
  croppedImageBase64: any = "";
  cropModalReference: any = null;

  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  avatarImage = "../../../assets/images/avatars/default-avatar.jpg";
  imageMode = "file";

  get VForm() {
    return this.VisitaForm.controls;
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    // this.visitanteService.getLastVisits(5).subscribe((data: any) => {
    //   this.lastVisits = data;
    // });
    this.visitanteService.getUnidades().subscribe((data: any) => {
      this.unidades = data;
    });
    this.visitanteService.getPerfis().then((data: any) => {
      this.pessoaPerfis = data
        .filter((item) => item.PessoaPerfil.id_condomino == 0)
        .map((perfil: any) => {
          return perfil.PessoaPerfil;
        });
    });
    this.visitanteService.getCameras().then((data: any) => {
      this.cameras = data;
      if (this.cameras.length > 0) {
        this.equipamento_id = this.cameras[0].Equipamento.id;
      }
    });
    this.VisitaForm = this.formBuilder.group({
      tp_documento: [this.visita.tp_documento, Validators.required],
      tp_destino: [this.visita.tp_destino, Validators.required],
      tp_visita: [this.visita.tp_visita, Validators.required],
      dc_placa: [this.visita.dc_placa],
      dc_modelo: [this.visita.dc_modelo],
      nr_cnh: [this.visita.nr_cnh],
      dt_validade_cnh: [this.visita.dt_validade_cnh],
      Pessoa: this.formBuilder.group({
        // id: [this.visita.Pessoa.id],
        nr_cpf: [this.visita.Pessoa.nr_cpf, Validators.required],
        nr_rg: [this.visita.Pessoa.nr_rg],
        nm_pessoa: [this.visita.Pessoa.nm_pessoa, Validators.required],
        dc_email: [
          this.visita.Pessoa.dc_email,
          [
            Validators.maxLength(100),
            Validators.minLength(5),
            Validators.pattern(/.+@.+\..+/),
          ],
        ],
        pessoa_perfil_id: [
          this.visita.Pessoa.pessoa_perfil_id,
          Validators.required,
        ],
        nr_telefone: [this.visita.Pessoa.nr_telefone],
        nm_empresa: [this.visita.Pessoa.nm_empresa],
        id_pin: [false],
        id_facial: [false],
      }),
      unidade_id: [this.visita.unidade_id, Validators.required],
      // condominio_id: [this.visita.condominio_id],
      // dt_entrada: [this.visita.dt_entrada, Validators.required],
      // dt_saida: [this.visita.dt_saida],
      hr_termino: [this.visita.hr_termino, Validators.required],
      nm_visitado: [this.visita.nm_visitado, Validators.required],
    });
  }
  /* OnDestroy */
  ngOnDestroy() {
    if (this.cameraInterval) {
      clearInterval(this.cameraInterval);
      this.cameraInterval = null;
    }
  }
  openDoor(id) {
    this.visitanteService
      .openDoor(id)
      .then((data: any) => {
        Swal.fire({
          icon: "success",
          title: "Abertura de porta",
          text: data.message.text,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Não foi possível abrir a porta",
          html: (error.text || error) + "",
        });
      });
  }
  filterDoor(e) {
    console.log(e);
    switch (e.nextId) {
      case "ngb-nav-0":
        this.doorType = "all";
        break;
      case "ngb-nav-1":
        this.doorType = "in";
        break;
      case "ngb-nav-2":
        this.doorType = "out";
        break;
    }
    this.filterDoors();
  }
  modalOpenDoor(modal) {
    this.visitanteService.getDoors().then((data: any) => {
      //extract first word of door name
      //create group of doors with that name
      //add doors to group
      //add group to array
      console.log(data);
      this.doors = data;
      this.filterDoors();
      this.modalService.open(modal, {
        backdrop: "static",
        keyboard: false,
        centered: true,
        //scrollable: true,
        size: "lg",
      });
    });
  }
  filterDoors() {
    //clone this.doors in to a local variable

    let doors = JSON.parse(JSON.stringify(this.doors));
    let groups = doors.reduce((acc, door) => {
      let name = door.Equipamento.nm_equipamento.split(" ")[0];
      if (!acc[name]) {
        acc[name] = [];
      }

      if (
        this.doorType == "all" ||
        (this.doorType == "in" &&
          door.Equipamento.nm_equipamento.toLowerCase().includes("ent")) ||
        (this.doorType == "out" &&
          door.Equipamento.nm_equipamento.toLowerCase().includes("sai"))
      ) {
        let doorName = door.Equipamento.nm_equipamento.replace(name, "").trim();
        if (doorName) {
          door.Equipamento.nm_equipamento = doorName;
        }
        acc[name].push(door);
        // sort doors by name
        acc[name].sort((a, b) => {
          return a.Equipamento.nm_equipamento.localeCompare(
            b.Equipamento.nm_equipamento
          );
        });
      }
      return acc;
    }, {});

    console.log(this.doors);
    console.log(groups);
    this.filteredDoors = groups;
  }
  modalOpenForm(modalForm) {
    this.modalService.open(modalForm, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      //scrollable: true,
      size: "lg",
      beforeDismiss: () => {
        if (this.cameraInterval) {
          clearInterval(this.cameraInterval);
          this.cameraInterval = null;
        }
        return true;
      },
    });
  }
  public getSnapshot(): void {
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.visita.image = webcamImage!.imageAsDataUrl;
    this.avatarImage = this.visita.image;
    this.imageMode = "file";
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }
  uploadImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (event: any) => {
        this.visita.image = event.target.result;
        this.avatarImage = this.visita.image;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }
  VisitaFormOnSubmit(completed = true) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.VisitaForm.invalid) {
      return;
    }
    this.formBlockUI.start("Registrando visita...");
    this.visita.complete = completed;
    this.visitanteService.addVisit(this.visita).subscribe(
      (data: any) => {
        this.modalService.dismissAll();
        this.VisitaForm.reset();
        this.visita.image = null;
        this.visita.tp_documento = "cpf";
        this.visita.tp_destino = "unidade";
        this.visita.tp_visita = "particular";
        this.submitted = false;
        this.avatarImage = "../../../assets/images/avatars/default-avatar.jpg";
        this.imageMode = "file";
        // this.visitanteService.getLastVisits(5).subscribe((data: any) => {
        //   this.lastVisits = data;
        // });
        this.formBlockUI.stop();
        Swal.fire({
          icon: "success",
          title: "Visita registrada com sucesso",
          showConfirmButton: false,
          timer: 1500,
        });
      },
      (error) => {
        this.formBlockUI.stop();
        Swal.fire({
          icon: "error",
          title: "Não foi possível registrar visita",
          html: (error.text || error) + "",
        });
      }
    );
  }
  changeTipoDocumento(e) {
    this.visita.Pessoa = {
      id: null,
      nr_cpf: "",
      nr_rg: "",
      nm_pessoa: "",
      dc_email: "",
      nr_telefone: "",
      nm_empresa: "",
      pessoa_perfil_id: null,
      nm_unidades: "",
      id_pin: false,
      id_facial: false,
    };
    if (e.id == "cpf") {
      this.VisitaForm.controls.Pessoa.get("nr_cpf").setValidators([
        Validators.required,
        Validators.pattern(/^(\d{3}\.){2}\d{3}-\d{2}$/),
      ]);
      this.VisitaForm.controls.Pessoa.get("nr_rg").clearValidators();
    } else if (e.id == "rg") {
      this.VisitaForm.controls.Pessoa.get("nr_cpf").clearValidators();
      this.VisitaForm.controls.Pessoa.get("nr_rg").setValidators([
        Validators.required,
      ]);
    }
    this.VisitaForm.controls.Pessoa.get("nr_cpf").updateValueAndValidity();
    this.VisitaForm.controls.Pessoa.get("nr_rg").updateValueAndValidity();
  }
  changeTipoVisita(e) {
    if (e.id == "particular") {
      this.VisitaForm.controls.Pessoa.get("nm_empresa").clearValidators();
    } else if (e.id == "servico") {
      this.VisitaForm.controls.Pessoa.get("nm_empresa").setValidators([
        Validators.required,
      ]);
    }
    this.VisitaForm.controls.Pessoa.get("nm_empresa").updateValueAndValidity();
  }
  changeTipoDestino(e) {
    this.unidade = null;
    this.usuarios = null;
    if (e.id == "condominio") {
      this.VisitaForm.get("unidade_id").clearValidators();
    } else if (e.id == "unidade") {
      this.VisitaForm.get("unidade_id").setValidators([Validators.required]);
    }
    this.VisitaForm.get("unidade_id").updateValueAndValidity();
  }
  changeUnidade(e) {
    this.visitanteService
      .getUnidade(this.visita.unidade_id)
      .subscribe((data: any) => {
        this.unidade = data;
        console.log(this.unidade);
      });
    this.visitanteService
      .getUsuarios(this.visita.unidade_id)
      .subscribe((data: any) => {
        this.usuarios = data.map((usuario) => {
          return {
            nm_pessoa: usuario.Usuario.nm_usuario + " - " + this.maskDocument(usuario.Usuario.nr_cpf_cnpj) + " - " + (usuario.Usuario.nr_celular || usuario.Usuario.nr_telefone)
          };
        });
      });
  }
  maskDocument(cpfCnpj) {
    return cpfCnpj
      ?.split("")
      .map((n, i) =>
        i < cpfCnpj.length - 4 && (parseInt(n) >= 0 || parseInt(n) <= 9)
          ? "*"
          : n
      )
      .join("");
  }
  changePlaca(e) {
    // if(e.target.value){
    //   this.VisitaForm.controls.dc_modelo.setValidators([Validators.required]);
    //   this.VisitaForm.controls.nr_cnh.setValidators([Validators.required]);
    //   this.VisitaForm.controls.dt_validade_cnh.setValidators([Validators.required]);
    // }else{
    //   this.VisitaForm.controls.dc_modelo.clearValidators();
    //   this.VisitaForm.controls.nr_cnh.clearValidators();
    //   this.VisitaForm.controls.dt_validade_cnh.clearValidators();
    // }
    // this.VisitaForm.controls.dc_modelo.updateValueAndValidity();
    // this.VisitaForm.controls.nr_cnh.updateValueAndValidity();
    // this.VisitaForm.controls.dt_validade_cnh.updateValueAndValidity();
  }
  changeCpf(e) {
    this.visitanteService.getByCpf(e.target.value).subscribe((data: any) => {
      this.visita.Pessoa = {
        id: null,
        nr_cpf: e.target.value,
        nr_rg: "",
        nm_pessoa: "",
        dc_email: "",
        nr_telefone: "",
        nm_empresa: "",
        pessoa_perfil_id: null,
        nm_unidades: "",
        id_pin: false,
        id_facial: false,
        dc_observacoes: "",
      };
      this.avatarImage = "../../../assets/images/avatars/default-avatar.jpg";
      if (data.Pessoa) {
        this.visita.Pessoa.id = data.Pessoa.id;
        this.visita.Pessoa.nr_cpf = data.Pessoa.nr_cpf;
        this.visita.Pessoa.nr_rg = data.Pessoa.nr_rg;
        this.visita.Pessoa.nm_pessoa = data.Pessoa.nm_pessoa;
        this.visita.Pessoa.dc_email = data.Pessoa.dc_email;
        this.visita.Pessoa.nr_telefone = data.Pessoa.nr_telefone;
        this.visita.Pessoa.nm_empresa = data.Pessoa.nm_empresa;
        this.visita.Pessoa.pessoa_perfil_id = data.Pessoa.pessoa_perfil_id;
        this.visita.Pessoa.nm_unidades = data.Pessoa.nm_unidades;
        this.visita.Pessoa.dc_observacoes = data.Pessoa.dc_observacoes;
        if (data.Pessoa.dc_picture_url) {
          this.avatarImage = data.Pessoa.dc_picture_url;
        }
        if(data.Pessoa.dc_observacoes){
          this.showObs();
        }
      }
    });
  }
  changeRg(e) {
    this.visita.Pessoa = {
      id: null,
      nr_rg: e.target.value,
      nr_cpf: "",
      nm_pessoa: "",
      dc_email: "",
      nr_telefone: "",
      nm_empresa: "",
      pessoa_perfil_id: null,
      nm_unidades: "",
      id_pin: false,
      id_facial: false,
      dc_observacoes: "",
    };
    this.visitanteService.getByRg(e.target.value).subscribe((data: any) => {
      this.avatarImage = "../../../assets/images/avatars/default-avatar.jpg";
      if (data.Pessoa) {
        this.visita.Pessoa.id = data.Pessoa.id;
        this.visita.Pessoa.nr_cpf = data.Pessoa.nr_cpf;
        this.visita.Pessoa.nr_rg = data.Pessoa.nr_rg;
        this.visita.Pessoa.nm_pessoa = data.Pessoa.nm_pessoa;
        this.visita.Pessoa.dc_email = data.Pessoa.dc_email;
        this.visita.Pessoa.nr_telefone = data.Pessoa.nr_telefone;
        this.visita.Pessoa.nm_empresa = data.Pessoa.nm_empresa;
        this.visita.Pessoa.pessoa_perfil_id = data.Pessoa.pessoa_perfil_id;
        this.visita.Pessoa.nm_unidades = data.Pessoa.nm_unidades;
        this.visita.Pessoa.dc_observacoes = data.Pessoa.dc_observacoes;
        if (data.Pessoa.dc_picture_url) {
          this.avatarImage = data.Pessoa.dc_picture_url;
        }
        if(data.Pessoa.dc_observacoes){
          this.showObs();
        }
      }
    });
  }
  showObs(){
    Swal.fire({
      title: 'Observações',
      text: this.visita.Pessoa.dc_observacoes,
      icon: 'warning',
      confirmButtonText: 'Ok'
    });
  }
  registerExit(visit) {
    Swal.fire({
      title: "Deseja realmente registrar a saída deste visitante?",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((result) => {
      if (result.isConfirmed) {
        this.visitanteService.registerExit(visit.id).subscribe(
          (data: any) => {
            Swal.fire({
              icon: "success",
              title: "Saída registrada com sucesso",
              showConfirmButton: false,
              timer: 1500,
            });
            // this.visitanteService.getLastVisits(5).subscribe((data: any) => {
            //   this.lastVisits = data;
            // });
          },
          (error) => {
            Swal.fire({
              icon: "error",
              title: "Não foi possível registrar saída",
              html: (error.text || error) + "",
            });
          }
        );
      } else if (result.isDismissed) {
        //Swal.fire("Saída não registrada", "", "info");
      }
    });
  }
  setImageMode(mode) {
    this.imageMode = mode;
    if (this.cameraInterval) {
      clearInterval(this.cameraInterval);
      this.cameraInterval = null;
    }
    if (mode == "equipment") {
      this.getEquipmentImage();
      this.cameraInterval = setInterval(() => {
        this.getEquipmentImage();
      }, 3000);
    }
  }
  cancelEquipment() {
    if (this.cameraInterval) {
      clearInterval(this.cameraInterval);
      this.cameraInterval = null;
    }
    this.avatarImage = "../../../assets/images/avatars/default-avatar.jpg";
    this.visita.image = null;
    this.setImageMode("file");
  }
  getEquipmentImage() {
    this.visitanteService
      .getEquipmentImage(this.equipamento_id)
      .then((data: any) => {
        if (this.cameraInterval) {
          console.log(data);
          //base64
          this.visita.image = "data:image/png;base64," + data.result;
          //set base64 as data url
          this.avatarImage = this.visita.image;
        }
      });
  }
  getEquipmentSnapshot() {
    this.setImageMode("file");
  }
  closeModal() {
    if (this.cameraInterval) {
      clearInterval(this.cameraInterval);
      this.cameraInterval = null;
    }
    this.modalService.dismissAll();
  }
  changeEquipamento(e) {
    if (this.cameraInterval) {
      clearInterval(this.cameraInterval);
      this.cameraInterval = null;
    }
    this.getEquipmentImage();
    this.cameraInterval = setInterval(() => {
      this.getEquipmentImage();
    }, 3000);
  }
  openCropModal(modal) {
    if (this.visita.Pessoa.id_facial) {
      this.visita.Pessoa.id_facial = false;
    } else {
      console.log(this.visita.image);
      console.log(this.avatarImage);
      this.cropModalReference = this.modalService.open(modal, {
        backdrop: "static",
        keyboard: false,
        centered: true,
        //scrollable: true,
        size: "lg",
      });
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.base64);
    this.croppedImageBase64 = event.base64;
    // event.blob can be used to upload the cropped image
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  cropImage() {
    this.visitanteService
      .testImage(this.equipamento_id, this.croppedImageBase64)
      .then((data: any) => {
        console.log(data);
        if (data.result && data.result.success) {
          this.visita.Pessoa.id_facial = true;
          this.visita.image = this.croppedImageBase64;
          this.avatarImage = this.visita.image;
          this.cropModalReference.close();
        } else {
          const messages = data.result.errors.map((error) => {
            switch (error.code) {
              case 1:
                //Image file not recognized. Image should be either JPG or PNG.
                return "Arquivo de imagem não reconhecido. A imagem deve ser JPG ou PNG.";
              case 2:
                //Face not detected
                return "Rosto não detectado";
              case 3:
                //Face exists
                return "Rosto já existe";
              case 4:
                //Face not centered
                return "Rosto não centralizado";
              case 5:
                //Face too distant
                return "Rosto muito distante";
              case 6:
                //Face too close
                return "Rosto muito próximo";
              case 7:
                //Face pose not centered
                return "Rosto deve estar de frente para a câmera";
              case 8:
                //Low sharpness
                return "Baixa nitidez";
              case 9:
                //Face too close to image borders
                return "Rosto muito próximo das bordas da imagem";
            }
          });

          Swal.fire({
            icon: "error",
            title: "Não foi possível validar imagem",
            text: messages ? messages.join("<br>") : "",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erro ao enviar imagem",
          html: (error.text || error) + "",
        });
      });
  }
}
