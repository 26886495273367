<div class="slideout-content">
  <!-- Modal to Add Customer starts-->
  <div class="modal-slide-in new-user-modal" id="modals-slide-in">
    <div class="modal-dialog">
      <div class="modal-content p-0">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="toggleSidebar('view-ocorrencia-sidebar')"
        >
          ×
        </button>
        <div class="modal-header mb-1">
          <h5 class="modal-title">
            <span class="align-middle">Ocorrencia</span>
          </h5>
        </div>
        <div class="modal-body flex-grow-1" *ngIf="ocorrencia?.Ocorrencia">
          <form>
            <h4>Dados da Ocorrência</h4>
            <div
              class="d-flex justify-content-between align-items-center flex-column"
            >
              <div class="ocorrencia-image-wrapper">
                <div class="ocorrencia-image p-1">
                    <img
                      src="{{
                        ocorrencia.Ocorrencia.dc_picture_url ||
                          'assets/images/default_camera.png'
                      }}"
                      alt="Profile Picture"
                      class="thumbnail-image"
                    />
                </div>
              </div>
              <h3>{{ ocorrencia.Ocorrencia.nm_ocorrencia }}</h3>
              <h6><i class="feather icon-calendar"></i> {{ ocorrencia.Ocorrencia.dt_ocorrencia }}</h6>
              <div class="w-100" [innerHTML]="ocorrencia.Ocorrencia.dc_ocorrencia" ></div>
              <h6 class="text-muted mt-1">
                {{ ocorrencia.Ocorrencia.dv_id_destino }}
              </h6>
              <div
                class="badge badge-light-primary profile-badge"
              >
                {{ ocorrencia.Ocorrencia.dv_id_severidade }}
              </div>
              <div
                class="badge badge-light-warning profile-badge"
              >
                {{ ocorrencia.Ocorrencia.st_ocorrencia }}
              </div>
            </div>
            <hr class="mb-2" />
            <h6>Usuário</h6>
              <div class="media">
                <div class="avatar mr-75">
                  <img
                    src="{{
                      ocorrencia.Usuario.dc_picture_url ||
                        'assets/images/avatars/default.jpg'
                    }}"
                    class="rounded"
                    width="42"
                    height="42"
                    alt="Avatar"
                  />
                </div>
                <div class="media-body my-auto">
                  <h6 class="mb-0">
                    {{ ocorrencia.Usuario.nm_usuario }}
                  </h6>
                </div>
              </div>
          

            <div class="form-group d-flex flex-wrap mt-2">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-dismiss="modal"
                (click)="toggleSidebar('view-ocorrencia-sidebar')"
                rippleEffect
              >
                Fechar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal to Add Customer Ends-->
</div>
