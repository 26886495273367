import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";

import { environment } from "environments/environment";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class OcorrenciaService implements Resolve<any> {
  rows: [] = [];
  paging = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: "Ocorrencia.id",
    orderDir: "desc",
  };
  filter = {
    search: "",
  };
  nextOcorrencias: [] = [];
  onOcorrenciaListChanged: BehaviorSubject<any>;
  public currentOcorrencia;
  public onCurrentOcorrenciaChange: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {
    // Set the defaults
    this.onOcorrenciaListChanged = new BehaviorSubject([]);
    this.onCurrentOcorrenciaChange = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows()]).then(() => {
        resolve();
      }, reject);
    });
  }

  /**
   * Get rows
   */
  getDataTableRows(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http
        .post(
          `${environment.apiUrl}/ocorrencias.json`,
          { OcorrenciaFilter: this.filter },
          { params: this.paging }
        )
        .subscribe((response: any) => {
          this.rows = response.data;
          this.paging.count = response.total;
          this.onOcorrenciaListChanged.next(this.rows);
          resolve(this.rows);
        }, reject);
    });
  }

  /**
   * Get last visits
   * @param {number} limit
   * @returns {Observable<any>}
   * */
  getLastOccurrences(limit: number = 5) {
    return this._http.get<any[]>(
      `${environment.apiUrl}/ocorrencias/last/${limit}.json`
    );
  }
  addOccurrence(data: any) {
    return new Promise((resolve, reject) => {
      this._http
        .post<any>(`${environment.apiUrl}/ocorrencias/add.json`, {
          Ocorrencia: data,
        })
        .subscribe((response: any) => {
          this.getDataTableRows();
          resolve(response);
        }, reject);
    });
  }
  getById(id) {
    this._http
      .get<any>(`${environment.apiUrl}/ocorrencias/view/${id}.json`)
      .subscribe((response: any) => {
        this.currentOcorrencia = response;
        this.onCurrentOcorrenciaChange.next(this.currentOcorrencia);
      });
  }
  updatePaging(paging: any) {
    this.paging = { ...this.paging, ...paging };
    this.getDataTableRows();
  }
  updateFilter(filter: any) {
    this.filter = { ...this.filter, ...filter };
    this.paging.offset = 0;
    this.getDataTableRows();
  }
}
