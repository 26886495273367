<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel1">Visitantes Autorizados</h4>
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <div class="row">
    <div class="col-md-6 col-12">
      <div class="d-flex justify-content-between align-items-center m-1">
        <div class="d-flex align-items-center">
          <label class="d-flex align-items-center"
            >Exibir
            <select
              class="form-control mx-25"
              [(ngModel)]="page.limit"
              (change)="pageCallback({ offset: 0 })"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </label>
          <!-- routerLink="/apps/encomenda/add" -->
        </div>
      </div>
    </div>
    <div
      class="col-md-6 col-lg-4 col-12 d-flex justify-content-start justify-content-md-end align-items-center offset-lg-2"
    >
      <div class="row pr-1 pb-1 pb-md-0 align-items-center w-100">
        <div class="col-12 pr-0">
          <label class="d-flex align-items-center mb-0 ml-1 ml-md-0"
            ><input
              [(ngModel)]="searchValue"
              name="searchValue"
              type="search"
              class="form-control"
              placeholder="Pesquisar..."
              (keyup)="filterUpdate($event)"
              (search)="filterUpdate($event)"
          /></label>
        </div>
        <!-- <div class="col-10 col-md-6 mt-2 ml-1 ml-md-0 mt-md-0">
          <fieldset class="form-group mb-0">
            <ng-select
              [items]="selectStatus"
              [(ngModel)]="selectedStatus"
              bindLabel="name"
              placeholder="Selecionar Status"
              (change)="filterByStatus($event)"
            ></ng-select>
          </fieldset>
        </div> -->
      </div>
    </div>
  </div>
  <ngx-datatable
    [rows]="rows"
    [rowHeight]="50"
    class="bootstrap core-bootstrap"
    [columnMode]="ColumnMode.force"
    [headerHeight]="50"
    [footerHeight]="50"
    [scrollbarH]="true"
    [externalPaging]="true"
    [externalSorting]="true"
    [count]="page.count"
    [offset]="page.offset"
    [limit]="page.limit"
    [sortType]="'single'"
    (page)="pageCallback($event)"
    (sort)="sortCallback($event)"
  >
    <ngx-datatable-column
      name="Nome"
      prop="Autorizado.nm_autorizado"
      [width]="400"
      [minWidth]="10"
    >
      <ng-template ngx-datatable-cell-template let-row="row" let-name="value">
        <div class="d-flex align-items-center">
          <div *ngIf="row.Autorizado.dc_picture_url; else customAvatar">
            <img
              class="rounded-circle mr-1"
              src="{{ row.Autorizado.dc_picture_url }}"
              height="32"
              width="32"
              alt="datatable-avatar"
            />
          </div>
          <ng-template #customAvatar>
            <div class="avatar mr-1 ml-0">
              <div class="avatar-content">{{ name | initials }}</div>
            </div>
          </ng-template>
          <div class="cell-line-height">
            <span class="font-weight-bold d-block text-nowrap font-medium-1">{{
              name | shortname
            }}</span>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Unidade"
      prop="Unidade.nm_display"
      [width]="150"
      [minWidth]="10"
    >
      <ng-template ngx-datatable-cell-template let-value="value">
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Início"
      prop="Autorizado.dt_permitida"
      [width]="150"
      [minWidth]="10"
    >
      <ng-template ngx-datatable-cell-template let-value="value">
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Término"
      prop="Autorizado.dt_saida"
      [width]="150"
      [minWidth]="10"
    >
      <ng-template ngx-datatable-cell-template let-value="value">
        {{ value }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Ação"
      [width]="120"
      [minWidth]="10"
    >
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        <button
          class="btn btn-sm btn-primary"
          (click)="modalOpenFormAddAutorizado(row)"
        >
          Registrar
        </button>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
