<div class="slideout-content">
  <!-- Modal to Add Customer starts-->
  <div class="modal-slide-in new-user-modal" id="modals-slide-in">
    <div class="modal-dialog">
      <div class="modal-content p-0">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="toggleSidebar('view-visitante-sidebar')"
        >
          ×
        </button>
        <div class="modal-header mb-1">
          <h5 class="modal-title">
            <span class="align-middle">Visita</span>
          </h5>
        </div>
        <div class="modal-body flex-grow-1" *ngIf="visita?.Visita">
          <form>
            <h4>Dados do Visitante</h4>
            <div
              class="d-flex justify-content-between align-items-center flex-column"
            >
              <div class="profile-image-wrapper">
                <div class="profile-image p-1">
                  <div class="avatar">
                    <img
                      src="{{
                        visita.Pessoa.dc_picture_url ||
                          'assets/images/avatars/default.jpg'
                      }}"
                      alt="Profile Picture"
                      class="thumbnail-image"
                    />
                  </div>
                </div>
              </div>
              <h3>{{ visita.Pessoa.nm_pessoa }}</h3>
              <h6 *ngIf="visita.Pessoa.dc_email" class="text-muted">
                {{ visita.Pessoa.dc_email }}
              </h6>
              <h6 class="text-muted">
                <i class="feather icon-credit-card"></i>
                {{ visita.Pessoa.nr_cpf || visita.Pessoa.nr_rg }}
              </h6>
              <h6 *ngIf="visita.Pessoa.nr_telefone" class="text-muted">
                <i class="feather icon-phone"></i>
                {{ visita.Pessoa.nr_telefone }}
              </h6>
              <div
                class="badge badge-light-primary profile-badge"
                *ngIf="visita.Pessoa.nm_empresa"
              >
                {{ visita.Pessoa.nm_empresa }}
              </div>
              <h6 *ngIf="visita.Visita.dc_placa" class="text-muted">
                {{ visita.Visita.dc_placa }}
              </h6>
              <h6 *ngIf="visita.Visita.dc_modelo" class="text-muted">
                {{ visita.Visita.dc_modelo }}
              </h6>
              <h6 *ngIf="visita.Visita.nr_cnh" class="text-muted">
                CNH: {{ visita.Visita.nr_cnh }}
              </h6>
              <h6 *ngIf="visita.Visita.dt_validade_cnh" class="text-muted">
                Válida até: {{ visita.Visita.dt_validade_cnh }}
              </h6>
            </div>
            <hr class="mb-2" />
            <!-- <div class="form-group">
              <label for="nm_pessoa" class="form-label">Visitante</label>
              <input
                type="text"
                class="form-control"
                id="nm_pessoa"
                name="nm_pessoa"
                [(ngModel)]="visita.Pessoa.nm_pessoa"
                disabled
              />
            </div>
            <div class="form-group" *ngIf="visita?.Pessoa.nr_cpf">
              <label for="nr_cpf" class="form-label">CPF</label>
              <input
                type="text"
                class="form-control"
                id="nr_cpf"
                name="nr_cpf"
                [(ngModel)]="visita.Pessoa.nr_cpf"
                disabled
              />
            </div>
            <div class="form-group" *ngIf="visita?.Pessoa.nr_rg">
              <label for="nr_rg" class="form-label">RG</label>
              <input
                type="text"
                class="form-control"
                id="nr_rg"
                name="nr_rg"
                [(ngModel)]="visita.Pessoa.nr_rg"
                disabled
              />
            </div>
            <div class="form-group" *ngIf="visita?.Pessoa.nm_empresa">
              <label for="nm_empresa" class="form-label">Empresa</label>
              <input
                type="text"
                class="form-control"
                id="nm_empresa"
                name="nm_empresa"
                [(ngModel)]="visita.Pessoa.nm_empresa"
                disabled
              />
            </div> -->
            <!-- <div class="form-group" *ngIf="visita?.Pessoa.nr_telefone">
              <label for="nr_telefone" class="form-label">Telefone</label>
              <input
                type="text"
                class="form-control"
                id="nr_telefone"
                name="nr_telefone"
                [(ngModel)]="visita.Pessoa.nr_telefone"
                disabled
              />
            </div>
            <div class="form-group" *ngIf="visita?.Pessoa.dc_email">
              <label for="dc_email" class="form-label">E-mail</label>
              <input
                type="text"
                class="form-control"
                id="dc_email"
                name="dc_email"
                [(ngModel)]="visita.Pessoa.dc_email"
                disabled
              />
            </div> -->
            <h4>Dados da Visita</h4>
            <div class="form-group" *ngIf="visita?.Visita.dt_entrada">
              <label for="dt_entrada" class="form-label">Entrada</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="dt_entrada"
                  name="dt_entrada"
                  [(ngModel)]="visita.Visita.dt_entrada"
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="feather icon-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group" *ngIf="visita?.Visita.dt_saida">
              <label for="dt_saida" class="form-label">Saída</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="dt_saida"
                  name="dt_saida"
                  [(ngModel)]="visita.Visita.dt_saida"
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="feather icon-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group" *ngIf="visita?.Visita.dv_tp_destino">
                  <label for="dv_tp_destino" class="form-label">Destino</label>
                  <input
                    type="text"
                    class="form-control"
                    id="dv_tp_destino"
                    name="dv_tp_destino"
                    [(ngModel)]="visita.Visita.dv_tp_destino"
                    disabled
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" *ngIf="visita?.Visita.dv_tp_visita">
                  <label for="dv_tp_visita" class="form-label">Tipo</label>
                  <input
                    type="text"
                    class="form-control"
                    id="dv_tp_visita"
                    name="dv_tp_visita"
                    [(ngModel)]="visita.Visita.dv_tp_visita"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div class="form-group" *ngIf="visita?.Unidade.nm_display">
              <label for="nm_display" class="form-label">Unidade</label>
              <input
                type="text"
                class="form-control"
                id="nm_display"
                name="nm_display"
                [(ngModel)]="visita.Unidade.nm_display"
                disabled
              />
            </div>
            <div class="form-group" *ngIf="visita?.Visita.nm_visitado">
              <label for="nm_visitado" class="form-label">Visitado</label>
              <input
                type="text"
                class="form-control"
                id="nm_visitado"
                name="nm_visitado"
                [(ngModel)]="visita.Visita.nm_visitado"
                disabled
              />
            </div>

            <div class="form-group d-flex flex-wrap mt-2">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-dismiss="modal"
                (click)="toggleSidebar('view-visitante-sidebar')"
                rippleEffect
              >
                Fechar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal to Add Customer Ends-->
</div>
