<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel1">Entregar Encomenda</h4>

  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <ng-container *ngIf="!encomenda">
    <ul ngbNav #nav="ngbNav" class="nav nav-pills">
      <li ngbNavItem>
        <a ngbNavLink class="d-flex align-items-center"
          ><i data-feather="maximize" class="mr-sm-50"></i
          ><span class="d-none d-sm-block">QR Code</span></a
        >
        <ng-template ngbNavContent>
          <!-- Account Tab starts -->
          <div
            class="tab-pane active"
            id="qrcode"
            aria-labelledby="qrcode-tab"
            role="tabpanel"
          >
            <div [hidden]="init || codigo_validacao">
              <fieldset class="form-group mb-0">
                <ng-select
                  [items]="availableDevices"
                  [clearable]="false"
                  bindLabel="label"
                  bindValue="deviceId"
                  placeholder="Selecione uma câmera"
                  (change)="onDeviceSelectChange($event)"
                ></ng-select>
              </fieldset>
              <ng-container [hidden]="!deviceSelected">
                <zxing-scanner
                  [device]="selectedDevice"
                  (camerasFound)="onCamerasFound($event)"
                  [formats]="['QR_CODE']"
                  [autofocusEnabled]="true"
                  (scanSuccess)="onScanSuccess($event)"
                ></zxing-scanner>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink class="d-flex align-items-center"
          ><i data-feather="terminal" class="mr-sm-50"></i
          ><span class="d-none d-sm-block">Informar código</span></a
        >
        <ng-template ngbNavContent>
          <!-- Account Tab starts -->
          <div
            class="tab-pane active"
            id="input"
            aria-labelledby="input-tab"
            role="tabpanel"
          >
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label>Código: </label>
                  <input
                    type="text"
                    placeholder="Código"
                    class="form-control"
                    name="codigo_validacao"
                    [(ngModel)]="codigo_validacao"
                    [ngClass]="{
                      'is-invalid': code_invalid,
                      'is-valid': code_valid
                    }"
                  />
                  <div *ngIf="code_invalid" class="invalid-feedback">
                    Código informado é inválido
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <button
                  type="button"
                  class="btn btn-primary mt-2"
                  (click)="verificarCodigo()"
                  rippleEffect
                >
                  Verificar
                </button>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </ng-container>

  <ng-container *ngIf="encomenda">
    <div class="row">
      <div class="col-md-6">
        <div>
          <h5 class="mb-75">Encomenda:</h5>
          <p class="card-text">
            {{ encomenda.TipoEncomenda.nm_tipo }}
            {{ encomenda.Encomenda.cd_encomenda }}
          </p>
        </div>
        <div class="mt-2">
          <h5 class="mb-75">Remetente:</h5>
          <p class="card-text">{{ encomenda.Encomenda.nm_remetente }}</p>
        </div>
        <div class="mt-2">
          <h5 class="mb-75">Destinatário:</h5>
          <div class="media">
            <div class="media-body my-auto">
              <h6 class="mb-0">
                {{ encomenda.Encomenda.nm_destinatario }}
              </h6>
              <small>{{ encomenda.Unidade.nm_display }}</small>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <h5 class="mb-75">Recebido por:</h5>
          <div class="media">
            <div class="avatar mr-75">
              <img
                src="{{
                  encomenda.UsuarioRecebimento.dc_picture_url ||
                    'assets/images/avatars/default.jpg'
                }}"
                class="rounded"
                width="42"
                height="42"
                alt="Avatar"
              />
            </div>
            <div class="media-body my-auto">
              <h6 class="mb-0">
                {{ encomenda.UsuarioRecebimento.nm_usuario }}
              </h6>
              <small>{{ encomenda.Encomenda.dt_recebimento }}</small>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="text-center">
          <img
            src="{{
              encomenda.Encomenda.dc_picture_url ||
                'assets/images/avatars/default-avatar.jpg'
            }}"
            class="img-thumbnail"
          />
        </div>

        <form
          [formGroup]="EncomendaForm"
          (ngSubmit)="EncomendaFormOnSubmit()"
          #formEncomenda
        >
          <div class="row mt-2">
            <div class="col-12">
              <label>Retirado por: </label>
              <div class="form-group">
                <ng-select
                  [items]="moradores"
                  [clearable]="false"
                  bindLabel="Usuario.nm_usuario"
                  bindValue="Usuario.id"
                  name="usuario_retirada_id"
                  [(ngModel)]="encomenda.usuario_retirada_id"
                  formControlName="usuario_retirada_id"
                  [ngClass]="{
                    'is-invalid': submitted && VForm.usuario_retirada_id.errors
                  }"
                >
                </ng-select>
                <div
                  *ngIf="submitted && VForm.usuario_retirada_id.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="VForm.usuario_retirada_id.errors.required">
                    Usuário é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <div class="d-flex align-items-center justify-content-between w-100">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="activeModal.dismiss()"
      rippleEffect
      [hidden]="encomenda"
    >
      Fechar
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      (click)="ReiniciarEntrega()"
      rippleEffect
      [hidden]="!encomenda"
    >
      Cancelar
    </button>
    <button
      [disabled]="!encomenda || !EncomendaForm.valid"
      type="button"
      class="btn btn-primary"
      (click)="EncomendaFormOnSubmit()"
      rippleEffect
    >
      Confirmar Entrega
    </button>
  </div>
</div>
