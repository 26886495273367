import { Component, OnInit } from '@angular/core';

import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { OcorrenciaService } from '../ocorrencia.service';

@Component({
  selector: 'app-view-ocorrencia-sidebar',
  styleUrls: ['./view-ocorrencia-sidebar.component.scss'],
  templateUrl: './view-ocorrencia-sidebar.component.html'
})
export class ViewOcorrenciaSidebarComponent implements OnInit {
 public ocorrencia: any; 

  /**
   * Constructor
   *
   * @param {EncomendaAddService} _encomendaAddService
   * @param {CoreSidebarService} _coreSidebarService
   */
  constructor(private _coreSidebarService: CoreSidebarService,
    private _ocorrenciaService: OcorrenciaService) {}

  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  ngOnInit(): void {
    this._ocorrenciaService.onCurrentOcorrenciaChange.subscribe((ocorrencia) => {
      this.ocorrencia = ocorrencia;
      console.log(ocorrencia    );
    });
  }
}
