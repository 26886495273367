import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WebcamImage } from "ngx-webcam";
import { Observable, Subject } from "rxjs";
import { VisitanteService } from "../visitante.service";
import Swal from "sweetalert2";
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Component({
  selector: "add-visitante-autorizado",
  templateUrl: "./add-visitante-autorizado.component.html",
  styleUrls: ["./add-visitante-autorizado.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddVisitanteAutorizadoComponent implements OnInit {
  @BlockUI('form-section') formBlockUI: NgBlockUI;
  @Input() public autorizado: any;
  loading: boolean = true;
  /**
   * Constructor
   *
   * @param {FormBuilder} formBuilder
   * @param {NgbModal} modalService
   * @param {VisitanteService} visitanteService
   */
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private visitanteService: VisitanteService,
    private modalService: NgbModal
  ) {
    this.loading = true;
  }
  public VisitaForm: FormGroup;
  public submitted = false;
  public contentHeader: object;
  public pessoaPerfis: any[] = [];
  public lastVisits: any[] = [];
  public unidades: any[] = [];
  public tipo_documentos: any[] = [
    { id: "cpf", name: "CPF" },
    { id: "rg", name: "RG" },
  ];
  public tipo_visitas: any[] = [
    { id: "particular", name: "Particular" },
    { id: "servico", name: "Serviço" },
  ];
  public destinos: any[] = [
    { id: "unidade", name: "Unidade" },
  ];

  public visita: any = {
    id: null,
    cd_autorizacao: '',
    tp_documento: "cpf",
    tp_destino: "unidade",
    tp_visita: "particular",
    complete: true,
    Pessoa: {
      id: null,
      nr_cpf: "",
      nr_rg: "",
      nm_pessoa: "",
      dc_email: "",
      nr_telefone: "",
      nm_empresa: "",
      pessoa_perfil_id: null,
    },
    unidade_id: null,
    // condominio_id: 0,
    // dt_entrada: new Date(),
    // dt_saida: "",
    nm_visitado: "",
    image: null,
  };

  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  avatarImage = "../../../assets/images/avatars/default-avatar.jpg";
  imageMode = "file";

  get VForm() {
    return this.VisitaForm.controls;
  }
  get VFormPessoa() {
    return (this.VisitaForm.get('Pessoa') as FormGroup).controls;
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.loading = true;
    this.visitanteService.getUnidades().subscribe((data: any) => {
      this.unidades = data;
      this.visita.unidade_id = this.autorizado.Autorizado.unidade_id;
    });
    this.visitanteService.getPerfis().then((data: any) => {
      this.pessoaPerfis = data.filter((item) => item.PessoaPerfil.id_condomino == 0).map((perfil: any) => {
        return perfil.PessoaPerfil;
      });
    });
    this.visita.tp_destino = "unidade";
    this.visita.unidade_id = null;
    this.visita.nm_visitado = this.autorizado.Usuario.nm_usuario;

    this.visita.autorizado_id = this.autorizado.Autorizado.id;
    this.visita.tp_documento = this.autorizado.Autorizado.nr_cpf? "cpf": "rg";
    this.visita.Pessoa.nr_cpf = this.autorizado.Autorizado.nr_cpf;
    this.visita.Pessoa.nm_pessoa = this.autorizado.Autorizado.nm_autorizado;
    this.visita.Pessoa.nr_rg = this.autorizado.Autorizado.nr_rg;
    this.visita.Pessoa.dc_email = this.autorizado.Autorizado.dc_email;
    this.visita.Pessoa.nr_telefone = this.autorizado.Autorizado.nr_telefone;
    
    this.VisitaForm = this.formBuilder.group({
      cd_autorizacao: [this.visita.cd_autorizacao, Validators.required],
      tp_documento: [this.visita.tp_documento, Validators.required],
      tp_destino: [this.visita.tp_destino, Validators.required],
      unidade_id: [this.visita.unidade_id, Validators.required],
      nm_visitado: [this.visita.nm_visitado, Validators.required],
      tp_visita: [this.visita.tp_visita, Validators.required],
      Pessoa: this.formBuilder.group({
        // id: [this.visita.Pessoa.id],
        nr_cpf: [this.visita.Pessoa.nr_cpf, Validators.required],
        nr_rg: [this.visita.Pessoa.nr_rg],
        nm_pessoa: [this.visita.Pessoa.nm_pessoa, Validators.required],
        dc_email: [
          this.visita.Pessoa.dc_email,
          [
            Validators.maxLength(100),
            Validators.minLength(5),
            Validators.pattern(/.+@.+\..+/),
          ],
        ],
        pessoa_perfil_id: [
          this.visita.Pessoa.pessoa_perfil_id,
          Validators.required,
        ],
        nr_telefone: [this.visita.Pessoa.nr_telefone],
        nm_empresa: [this.visita.Pessoa.nm_empresa],
      })
    });
    this.VForm.tp_destino.disable();
    this.VForm.unidade_id.disable();
    if (this.visita.tp_documento == "cpf") {
      this.VisitaForm.controls.Pessoa.get("nr_cpf").setValidators([
        Validators.required,
        Validators.pattern(/^(\d{3}\.){2}\d{3}-\d{2}$/),
      ]);
      this.VisitaForm.controls.Pessoa.get("nr_rg").clearValidators();
    } else if (this.visita.tp_documento == "rg") {
      this.VisitaForm.controls.Pessoa.get("nr_cpf").clearValidators();
      this.VisitaForm.controls.Pessoa.get("nr_rg").setValidators([
        Validators.required,
      ]);
    }
    this.VisitaForm.controls.Pessoa.get("nr_cpf").updateValueAndValidity();
    this.VisitaForm.controls.Pessoa.get("nr_rg").updateValueAndValidity();
    this.loading = false;
  }
 
  public getSnapshot(): void {
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.visita.image = webcamImage!.imageAsDataUrl;
    this.avatarImage = this.visita.image;
    this.imageMode = "file";
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }
  uploadImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (event: any) => {
        this.visita.image = event.target.result;
        this.avatarImage = this.visita.image;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }
  VisitaFormOnSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.VisitaForm.invalid) {
      return;
    }
    this.formBlockUI.start("Registrando visita...");
    this.visitanteService.addVisit(this.visita).subscribe(
      (data: any) => {
        this.modalService.dismissAll();
        this.VisitaForm.reset();
        this.visita.image = null;
        this.visita.tp_documento = "cpf";
        this.visita.tp_destino = "unidade";
        this.visita.tp_visita = "particular";
        this.submitted = false;
        this.avatarImage = "../../../assets/images/avatars/default-avatar.jpg";
        this.imageMode = "file";
        this.formBlockUI.stop();
        Swal.fire({
          icon: "success",
          title: "Visita registrada com sucesso",
          showConfirmButton: false,
          timer: 1500,
        });
      },
      (error) => {
        this.formBlockUI.stop();
        Swal.fire({
          icon: "error",
          title: "Não foi possível registrar visita",
          html: (error.text || error) + "",
        });
      }
    );
  }
  changeTipoDocumento(e) {
    this.visita.Pessoa.id = null;
    if (e.id == "cpf") {
      this.VisitaForm.controls.Pessoa.get("nr_cpf").setValidators([
        Validators.required,
        Validators.pattern(/^(\d{3}\.?){2}\d{3}-?\d{2}$/),
      ]);
      this.VisitaForm.controls.Pessoa.get("nr_rg").clearValidators();
    } else if (e.id == "rg") {
      this.VisitaForm.controls.Pessoa.get("nr_cpf").clearValidators();
      this.VisitaForm.controls.Pessoa.get("nr_rg").setValidators([
        Validators.required,
      ]);
    }
    this.VisitaForm.controls.Pessoa.get("nr_cpf").updateValueAndValidity();
    this.VisitaForm.controls.Pessoa.get("nr_rg").updateValueAndValidity();
  }
  changeTipoVisita(e) {
    if (e.id == "particular") {
      this.VisitaForm.controls.Pessoa.get("nm_empresa").clearValidators();
    } else if (e.id == "servico") {
      this.VisitaForm.controls.Pessoa.get("nm_empresa").setValidators([
        Validators.required,
      ]);
    }
    this.VisitaForm.controls.Pessoa.get(
      "nm_empresa"
    ).updateValueAndValidity();
  }
  
  changeCpf(e) {
    this.visitanteService.getByCpf(e.target.value).subscribe((data: any) => {
      this.visita.Pessoa.id = null;
      if (data.Pessoa) {
        this.visita.Pessoa.id = data.Pessoa.id;
        this.visita.Pessoa.nr_cpf = data.Pessoa.nr_cpf;
        this.visita.Pessoa.nr_rg = data.Pessoa.nr_rg;
        this.visita.Pessoa.nm_pessoa = data.Pessoa.nm_pessoa;
        this.visita.Pessoa.dc_email = data.Pessoa.dc_email;
        this.visita.Pessoa.nr_telefone = data.Pessoa.nr_telefone;
        this.visita.Pessoa.nm_empresa = data.Pessoa.nm_empresa;
      }
    });
  }
  changeRg(e) {
    this.visita.Pessoa.id = null;
    this.visitanteService.getByRg(e.target.value).subscribe((data: any) => {
      if (data.Pessoa) {
        this.visita.Pessoa.id = data.Pessoa.id;
        this.visita.Pessoa.nr_cpf = data.Pessoa.nr_cpf;
        this.visita.Pessoa.nr_rg = data.Pessoa.nr_rg;
        this.visita.Pessoa.nm_pessoa = data.Pessoa.nm_pessoa;
        this.visita.Pessoa.dc_email = data.Pessoa.dc_email;
        this.visita.Pessoa.nr_telefone = data.Pessoa.nr_telefone;
        this.visita.Pessoa.nm_empresa = data.Pessoa.nm_empresa;
      }
    });
  }
}
