<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel1">Controle de Itens</h4>
  <button
    type="button"
    class="close"
    (click)="closeModal()"
    aria-label="Close"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <!-- ControleAreaItemnte List Header -->
  <div class="row">
    <div class="col-md-6 col-12">
      <div class="d-flex justify-content-between align-items-center m-1">
        <div class="d-flex align-items-center">
          <label class="d-flex align-items-center"
            >Exibir
            <select
              class="form-control mx-25"
              [(ngModel)]="page.limit"
              (change)="pageCallback({ offset: 0 })"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </label>
          <!-- routerLink="/apps/encomenda/add" -->
          <!-- <button
              class="btn btn-info ml-2"
              rippleEffect
              (click)="modalOpenForm(encomendaForm)"
            >
            <i data-feather="log-in" class="font-medium-2"></i> Registrar Recebimento
            </button>
            <button
              class="btn btn-success ml-2"
              rippleEffect
              (click)="modalOpenControleAreaItemnteDeliver()"
            >
            <i data-feather="log-out" class="font-medium-2"></i> Registrar Entrega
            </button> -->
        </div>
      </div>
    </div>
    <div
      class="col-md-6 col-12 d-flex justify-content-end align-items-center pr-3"
    >
      <label class=""
        ><input
          [(ngModel)]="searchValue"
          name="searchValue"
          type="search"
          class="form-control"
          placeholder="Pesquisar..."
          (keyup)="filterUpdate($event)"
          (search)="filterUpdate($event)"
      /></label>
    </div>
  </div>
  <!--/ ControleAreaItemnte List Header -->

  <!-- ControleAreaItemnte List Table -->
  <ngx-datatable
    [rows]="rows"
    [rowHeight]="50"
    class="bootstrap core-bootstrap"
    [columnMode]="ColumnMode.force"
    [headerHeight]="50"
    [footerHeight]="50"
    [scrollbarH]="true"
    [externalPaging]="true"
    [externalSorting]="true"
    [count]="page.count"
    [offset]="page.offset"
    [limit]="page.limit"
    [sortType]="'single'"
    (page)="pageCallback($event)"
    (sort)="sortCallback($event)"
  >
    <ngx-datatable-column name="Nome" prop="Pessoa.nm_pessoa" [width]="10">
      <ng-template ngx-datatable-cell-template let-row="row" let-name="value">
        <div class="d-flex align-items-center">
          <div *ngIf="row.Pessoa.dc_picture_url; else customAvatar">
            <img
              class="rounded-circle mr-1"
              src="{{ row.Pessoa.dc_picture_url }}"
              height="32"
              width="32"
              alt="datatable-avatar"
            />
          </div>
          <ng-template #customAvatar>
            <div class="avatar mr-1 ml-0">
              <div class="avatar-content">{{ name | initials }}</div>
            </div>
          </ng-template>
          <div class="cell-line-height">
            <span class="font-weight-bold d-block text-nowrap font-medium-1">{{
              name | shortname
            }}</span>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Unidade" prop="Pessoa.nm_unidade" [width]="10">
      <ng-template ngx-datatable-cell-template let-value="value">
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Item" prop="AreaItem.nm_item" [width]="10">
      <ng-template ngx-datatable-cell-template let-value="value">
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Retirada"
      prop="ControleAreaItem.dt_emprestimo"
      [width]="10"
    >
      <ng-template ngx-datatable-cell-template let-value="value">
        {{ value }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Devolução"
      prop="ControleAreaItem.dt_devolucao"
      [width]="10"
    >
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        <span *ngIf="value">{{ value }}</span>
        <button
          class="btn btn-sm btn-primary"
          *ngIf="!value"
          (click)="registerReturn(row)"
        >
          Devolver
        </button>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
<div class="modal-footer">
  <button class="btn btn-primary btn-sm" (click)="openAddChaves()" rippleEffect>
    Adicionar
  </button>
</div>