export class Condominio {
    id: number;
    nm_condominio: string;
    dc_razao: string;
    nr_cpf_cnpj: string;
    nr_latitude: string;
    nr_longitude: string;
    nr_telefone: string;
    administradora_id: number;
    dc_administradora: string;
    dc_estado: string;
    dc_cidade: string;
    dc_logradouro: string;
    dc_numero: string;
    dc_cep: string;
    dc_bairro: string;
    dc_complemento: string;
    dc_email: string;

    created: string;
    updated: string;

  }