import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskEmail'
})
export class MaskEmailPipe implements PipeTransform {
  transform(email: string): any {
    return email
      ?.split('@')
      .map((n,i) => i === 0  ? n : n.split('.').map((m,j) => j === 0 ? '*'.repeat(m.length) : m).join('.') )
      .join('@');
  }
}
