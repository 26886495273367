import { Component, OnInit } from '@angular/core';

import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { EncomendaListService } from '../encomenda-list/encomenda-list.service';

@Component({
  selector: 'app-view-encomenda-sidebar',
  styleUrls: ['./view-encomenda-sidebar.component.scss'],
  templateUrl: './view-encomenda-sidebar.component.html'
})
export class ViewEncomendaSidebarComponent implements OnInit {
 public encomenda: any; 

  /**
   * Constructor
   *
   * @param {EncomendaAddService} _encomendaAddService
   * @param {CoreSidebarService} _coreSidebarService
   */
  constructor(private _coreSidebarService: CoreSidebarService,
    private _encomendaListService: EncomendaListService) {}

  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  ngOnInit(): void {
    this._encomendaListService.onCurrentEncomendaChange.subscribe((encomenda) => {
      this.encomenda = encomenda;
      console.log(encomenda    );
    });
  }
}
