import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { io } from "socket.io-client";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "environments/environment";
import { User, Role } from "app/auth/models";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private socket = null;
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;
  public onNewEventSubject: BehaviorSubject<any>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    if (this.currentUserValue) {
      this.initSocket();
    }
    this.onNewEventSubject = new BehaviorSubject<any>(null);
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  // get isAdmin() {
  //   return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  // }

  /**
   *  Confirms if user is client
   */
  // get isClient() {
  //   return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  // }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/usuarios/authenticate.json`, {
        Usuario: { dc_email: email, dc_senha: password },
      })
      .pipe(
        map((user) => {
          // if(user.Condominio.id_chat == '1'){
          //   user.role = Role.Admin;
          // } else {
          //   user.role = Role.Client;
          // }
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user));

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                "Login efetuado com sucesso. Aproveite! 🎉",
                "👋 Seja bem-vindo, " + user.Usuario.nm_usuario + "!",
                { toastClass: "toast ngx-toastr", closeButton: true }
              );
            }, 2500);
            this.initSocket();
            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }
  /**
   * User recover password
   *
   * @param email
   * @returns user
   */
  recover(email: string) {
    return this._http.post<any>(
      `${environment.apiUrl}/usuarios/recuperar_senha.json`,
      { Usuario: { dc_email: email } }
    );
  }

  /**
   * Check recover token
   * @param token
   * @returns user
   */
  checkRecoverToken(token: string) {
    return this._http.get<any>(
      `${environment.apiUrl}/usuarios/reset_password/${token}.json`
    );
  }

  /**
   * Reset password
   * @param token
   * @param password
   * @param password_confirmation
   * @returns user
   */
  resetPassword(
    token: string,
    password: string,
    password_confirmation: string
  ) {
    return this._http.post<any>(
      `${environment.apiUrl}/usuarios/reset_password/${token}.json`,
      {
        Usuario: {
          dc_password_new: password,
          dc_password_confirm: password_confirmation,
        },
      }
    );
  }
  initSocket() {
    console.log("init socket");
    console.log(this.currentUserValue);
    if(!this.socket && this.currentUserValue) {
      this.socket = io("https://www.pointcondominio.com.br:2053", {
        transports: ["websocket"],
        query:{
          condominio_id: this.currentUserValue?.Condominio.id,
        }
      });
      this.socket.on("connect", () => {
        console.log("connected");
      });
      this.socket.on("disconnect", () => {
        console.log("disconnected");
      });
      this.socket.on("event", (data) => {
        console.log('event');
        console.log(data);
        this.onNewEventSubject.next(data);
      });
      this.socket.on("error", (error) => {
        console.log(error);
      });
      this.socket.on("reconnect", () => {
        console.log("reconnected");
      });
      this.socket.on("reconnect_attempt", () => {
        console.log("reconnect_attempt");
      });
      this.socket.on("reconnect_error", () => {
        console.log("reconnect_error");
      });
      this.socket.on("reconnect_failed", () => {
        console.log("reconnect_failed");
      });
      this.socket.on("reconnecting", () => {
        console.log("reconnecting");
      });
      this.socket.on("ping", () => {
        console.log("ping");
      });
      this.socket.on("pong", () => {
        console.log("pong");
      });
      this.socket.on("connect_error", (err) => {
        console.log(`connect_error ${err.message}`);
      });
      this.socket.on("connect_timeout", () => {
        console.log("connect_timeout");
      });
      this.socket.on("connecting", () => {
        console.log("connecting");
      });
      this.socket.on("error", () => {
        console.log("error");
      });

    }
  }


  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
    // notify
    this.currentUserSubject.next(null);
  }
}
