import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { CoreCommonModule } from '@core/common.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreSidebarModule } from '@core/components';
import { NgxMaskModule } from 'ngx-mask';
import { AutorizadoService } from './autorizado.service';
import { AddAutorizadoCardComponent } from './add-autorizado-card/add-autorizado-card.component';
import { AutorizadoCardComponent } from './autorizado-card/autorizado-card.component';


// routing
const routes: Routes = [
];

@NgModule({
  declarations: [
    AddAutorizadoCardComponent,
    AutorizadoCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    CoreDirectivesModule,
    Ng2FlatpickrModule,
    NgxDatatableModule,
    FormsModule,
    CorePipesModule,
    NgbModule,
    NgSelectModule,
    CoreSidebarModule,
    NgxMaskModule.forRoot()
  ],
  providers: [AutorizadoService],
  exports: [AddAutorizadoCardComponent,AutorizadoCardComponent]
})
export class AutorizadoModule {}
