<div class="card text-center mb-0 h-100 d-inline-block w-100">
  <div class="card-body">
    <div class="avatar bg-light-warning p-50 m-0 mb-1">
      <div class="avatar-content">
        <i data-feather="box" class="font-medium-5"></i>
      </div>
    </div>
    <h2 class="font-weight-bolder">Encomendas</h2>
    <div class="d-flex justify-content-between align-items-center mt-2 gap-3">
      <button
        type="button"
        class="btn btn-primary"
        rippleEffect
        (click)="modalOpenForm(encomendaForm)"
      >
        Receber
      </button>
      <button
        type="button"
        class="btn btn-danger"
        rippleEffect
        (click)="modalOpenEncomendaDeliver()"
      >
        Entregar
      </button>
    </div>
    <!-- <p class="mb-0 line-ellipsis">Nenhum alerta ativo</p> -->
  </div>
</div>
<ng-template #encomendaForm let-modal>
  <form
    [formGroup]="EncomendaForm"
    (ngSubmit)="EncomendaFormOnSubmit()"
    #formEncomenda
  >
    <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel1">Receber Encomenda</h4>
      <button
        type="button"
        class="close"
        (click)="modal.dismiss('Cross click')"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <h6 class="mb-2">1. Dados da Encomenda</h6>
      <div class="row">
        <div class="col-md-8 col-12">
          <div class="row">
            <div class="col-md-6 col-12">
              <label>Tipo: </label>
              <div class="form-group">
                <ng-select
                  [items]="tipo_encomendas"
                  [clearable]="false"
                  bindLabel="TipoEncomenda.nm_tipo"
                  bindValue="TipoEncomenda.id"
                  name="tipo_encomenda_id"
                  [(ngModel)]="encomenda.tipo_encomenda_id"
                  formControlName="tipo_encomenda_id"
                  [ngClass]="{
                    'is-invalid': submitted && VForm.tipo_encomenda_id.errors
                  }"
                >
                </ng-select>
                <div
                  *ngIf="submitted && VForm.tipo_encomenda_id.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="VForm.tipo_encomenda_id.errors.required">
                    Tipo de encomenda é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <label>Código: </label>
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Código de rastreio"
                  class="form-control"
                  name="cd_encomenda"
                  [(ngModel)]="encomenda.cd_encomenda"
                  formControlName="cd_encomenda"
                  [ngClass]="{
                    'is-invalid': submitted && VForm.cd_encomenda.errors
                  }"
                />
                <div
                  *ngIf="submitted && VForm.cd_encomenda.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="VForm.cd_encomenda.errors.required">
                    Código de rastreio é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label>Remetente: </label>
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Nome do Remetente"
                  class="form-control"
                  name="nm_remetente"
                  [(ngModel)]="encomenda.nm_remetente"
                  formControlName="nm_remetente"
                  [ngClass]="{
                    'is-invalid': submitted && VForm.nm_remetente.errors
                  }"
                />
                <div
                  *ngIf="submitted && VForm.nm_remetente.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="VForm.nm_remetente.errors.required">
                    Nome do remetente é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label>Observações: </label>
              <div class="form-group">
                <textarea
                  placeholder="Observações"
                  class="form-control"
                  name="dc_observacao"
                  [(ngModel)]="encomenda.dc_observacao"
                  formControlName="dc_observacao"
                  [ngClass]="{
                    'is-invalid': submitted && VForm.dc_observacao.errors
                  }"
                ></textarea>
                <div
                  *ngIf="submitted && VForm.dc_observacao.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="VForm.dc_observacao.errors.required">
                    Observação é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <!-- header media -->
          <div *ngIf="imageMode == 'camera'">
            <div class="row">
              <div class="col-md-12 text-center">
                <div class="rounded mb-75 object-fit-cover">
                  <webcam
                    [trigger]="invokeObservable"
                    (imageCapture)="captureImg($event)"
                    [width]="150"
                    [height]="150"
                  ></webcam>
                </div>
              </div>
              <div class="col-md-12 text-center">
                <button
                  type="button"
                  class="btn btn-sm btn-primary mb-75 mr-75"
                  (click)="getSnapshot()"
                >
                  Capturar
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-outline-secondary mb-75"
                  rippleEffect
                  (click)="imageMode = 'file'"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="imageMode == 'file'">
            <div class="row">
              <div class="col-12 text-center">
                <a href="javascript:void(0);">
                  <img
                    [src]="avatarImage"
                    class="rounded mb-75 object-fit-cover shadow"
                    alt=""
                    height="150"
                    width="150"
                    (click)="file.click()"
                  />
                </a>
              </div>
              <div class="col-12 text-center">
                <button
                  type="button"
                  (click)="file.click()"
                  for="account-upload"
                  class="btn btn-sm btn-primary mb-75 mr-75"
                  rippleEffect
                >
                  Upload
                </button>
                <input
                  type="file"
                  #file
                  id="account-upload"
                  hidden
                  accept="image/*"
                  (change)="uploadImage($event)"
                />
                <button
                  type="button"
                  class="btn btn-sm btn-outline-secondary mb-75"
                  rippleEffect
                  (click)="imageMode = 'camera'"
                >
                  Câmera
                </button>
              </div>
            </div>
          </div>
          <!--/ header media -->
        </div>
      </div>

      <h6 class="mb-2">2. Destinatário</h6>
      <div class="row">
        <div class="col-md-6 col-12">
          <label>Bloco: </label>
          <div class="form-group">
            <ng-select
              [items]="blocos"
              [clearable]="false"
              bindLabel="Bloco.nm_bloco"
              bindValue="Bloco.id"
              [loading]="false"
              name="bloco_id"
              [(ngModel)]="encomenda.bloco_id"
              formControlName="bloco_id"
              [ngClass]="{
                'is-invalid': submitted && VForm.bloco_id.errors
              }"
              (change)="onChangeBloco()"
            >
            </ng-select>
            <div
              *ngIf="submitted && VForm.bloco_id.errors"
              class="invalid-feedback"
            >
              <div *ngIf="VForm.bloco_id.errors.required">
                Bloco é obrigatório
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <label>Unidade: </label>
          <div class="form-group">
            <ng-select
              [items]="unidades"
              [clearable]="false"
              bindLabel="Unidade.nm_unidade"
              bindValue="Unidade.id"
              [loading]="false"
              name="unidade_id"
              [(ngModel)]="encomenda.unidade_id"
              formControlName="unidade_id"
              [ngClass]="{
                'is-invalid': submitted && VForm.unidade_id.errors
              }"
              (change)="onChangeUnidade()"
            >
            </ng-select>
            <div
              *ngIf="submitted && VForm.unidade_id.errors"
              class="invalid-feedback"
            >
              <div *ngIf="VForm.unidade_id.errors.required">
                Unidade é obrigatória
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-12">
          <label>Destinatário: </label>
          <div class="form-group">
            <input
              type="text"
              placeholder="Nome do destinatário"
              class="form-control"
              name="nm_destinatario"
              [(ngModel)]="encomenda.nm_destinatario"
              formControlName="nm_destinatario"
              [ngClass]="{
                'is-invalid': submitted && VForm.nm_destinatario.errors
              }"
            />
            <div
              *ngIf="submitted && VForm.nm_destinatario.errors"
              class="invalid-feedback"
            >
              <div *ngIf="VForm.nm_destinatario.errors.required">
                Destinatário é obrigatório
              </div>
            </div>
          </div>
        </div>
      </div>
      <h6 class="mb-2">3. Notificação</h6>
      <div class="row">
        <!-- <div class="col-md-4 col-12">
          <div class="form-group">
            <label class="d-block mb-1"></label>
            <div class="custom-control custom-checkbox custom-control-inline">
              <input
                type="checkbox"
                class="custom-control-input"
                id="id_notificar"
                name="id_notificar"
                [(ngModel)]="encomenda.id_notificar"
                formControlName="id_notificar"
                (change)="onChangeNotificar()"
              />
              <label class="custom-control-label" for="id_notificar"
                >Notificar Condôminos</label
              >
            </div>
          </div>
        </div> -->

        <div
          class="col-md-8 col-12"
          *ngIf="encomenda.id_notificar && usuarios && usuarios.length > 0"
        >
          <div class="">
            <div class="card-body profile-suggestion" formGroupName="usuarios">
              <h5 class="mb-2">Usuários</h5>
              <!-- user suggestions -->
              <div
                class="d-flex justify-content-start align-items-center"
                [class.mb-1]="usuarios.length - 1 !== usuarios.indexOf(usuario)"
                *ngFor="let usuario of usuarios; let i = index"
                [formGroupName]="i"
              >
                  <div
                    *ngIf="usuario.Usuario.dc_picture_url; else customAvatar"
                    class="avatar mr-1"
                  >
                    <img
                      src="{{
                        usuario.Usuario.dc_picture_url ||
                          'assets/images/avatars/default.jpg'
                      }}"
                      height="40"
                      width="40"
                      alt="avatar"
                    />
                  </div>
                  <ng-template #customAvatar>
                    <div class="avatar mr-1">
                      <div class="avatar-content">{{ usuario.Usuario.nm_usuario | initials }}</div>
                    </div>
                  </ng-template>
                <!-- <div class="avatar mr-1">
                  <img
                    [src]="usuario.Usuario.dc_picture_url"
                    alt="avatar img"
                    height="40"
                    width="40"
                  />
                </div> -->
                <div class="profile-user-info">
                  <h6 class="mb-0">{{ usuario.Usuario.nm_usuario }}</h6>
                  <small class="text-muted">{{
                    usuario.Usuario.dc_tipo
                  }}</small>
                </div>
                <div
                  class="custom-control custom-switch custom-control-inline ml-auto"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    [(ngModel)]="encomenda.usuarios[i].selected"
                    name="encomendaUsuarios{{ i }}Selected"
                    id="encomendaUsuarios{{ i }}Selected"
                    formControlName="selected"
                  />
                  <label
                    class="custom-control-label"
                    for="encomendaUsuarios{{ i }}Selected"
                  ></label>
                </div>
                <!-- <div class="profile-star ml-auto">
                  <i
                    class="font-medium-3 fa"
                    [ngClass]="{
                      'fa-star text-warning': suggestedPagesRef.favorite === true,
                      'fa-star-o': suggestedPagesRef.favorite !== true
                    }"
                  ></i>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" 
      class="btn btn-primary" 
      [disabled]="submitting"
      rippleEffect>
        {{ submitting ? 'Aguarde...' : 'Registrar' }}
      </button>
    </div>
  </form>
</ng-template>
