<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section class="encomenda-preview-wrapper">
      <div class="row encomenda-preview">
        <!-- Encomenda -->
        <div class="col-xl-9 col-md-8 col-12 printRef">
          <div class="card encomenda-preview-card">
            <div class="card-body encomenda-padding pb-0">
              <!-- Header Starts -->
              <div class="d-flex justify-content-between flex-md-row flex-column encomenda-spacing mt-0">
                <div>
                  <div class="logo-wrapper">
                    <svg
                      viewBox="0 0 139 95"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      height="24"
                    >
                      <defs>
                        <linearGradient
                          id="encomenda-linearGradient-1"
                          x1="100%"
                          y1="10.5120544%"
                          x2="50%"
                          y2="89.4879456%"
                        >
                          <stop stop-color="#000000" offset="0%"></stop>
                          <stop stop-color="#FFFFFF" offset="100%"></stop>
                        </linearGradient>
                        <linearGradient
                          id="encomenda-linearGradient-2"
                          x1="64.0437835%"
                          y1="46.3276743%"
                          x2="37.373316%"
                          y2="100%"
                        >
                          <stop stop-color="#EEEEEE" stop-opacity="0" offset="0%"></stop>
                          <stop stop-color="#FFFFFF" offset="100%"></stop>
                        </linearGradient>
                      </defs>
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(-400.000000, -178.000000)">
                          <g transform="translate(400.000000, 178.000000)">
                            <path
                              class="text-primary"
                              d="M-5.68434189e-14,2.84217094e-14 L39.1816085,2.84217094e-14 L69.3453773,32.2519224 L101.428699,2.84217094e-14 L138.784583,2.84217094e-14 L138.784199,29.8015838 C137.958931,37.3510206 135.784352,42.5567762 132.260463,45.4188507 C128.736573,48.2809251 112.33867,64.5239941 83.0667527,94.1480575 L56.2750821,94.1480575 L6.71554594,44.4188507 C2.46876683,39.9813776 0.345377275,35.1089553 0.345377275,29.8015838 C0.345377275,24.4942122 0.230251516,14.560351 -5.68434189e-14,2.84217094e-14 Z"
                              style="fill: currentColor"
                            ></path>
                            <path
                              d="M69.3453773,32.2519224 L101.428699,1.42108547e-14 L138.784583,1.42108547e-14 L138.784199,29.8015838 C137.958931,37.3510206 135.784352,42.5567762 132.260463,45.4188507 C128.736573,48.2809251 112.33867,64.5239941 83.0667527,94.1480575 L56.2750821,94.1480575 L32.8435758,70.5039241 L69.3453773,32.2519224 Z"
                              fill="url(#encomenda-linearGradient-1)"
                              opacity="0.2"
                            ></path>
                            <polygon
                              fill="#000000"
                              opacity="0.049999997"
                              points="69.3922914 32.4202615 32.8435758 70.5039241 54.0490008 16.1851325"
                            ></polygon>
                            <polygon
                              fill="#000000"
                              opacity="0.099999994"
                              points="69.3922914 32.4202615 32.8435758 70.5039241 58.3683556 20.7402338"
                            ></polygon>
                            <polygon
                              fill="url(#encomenda-linearGradient-2)"
                              opacity="0.099999994"
                              points="101.428699 0 83.0667527 94.1480575 130.378721 47.0740288"
                            ></polygon>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <h3 class="text-primary encomenda-logo">Vuexy</h3>
                  </div>
                  <p class="card-text mb-25">Office 149, 450 South Brand Brooklyn</p>
                  <p class="card-text mb-25">San Diego County, CA 91905, USA</p>
                  <p class="card-text mb-0">+1 (123) 456 7891, +44 (876) 543 2198</p>
                </div>
                <div class="mt-md-0 mt-2">
                  <h4 class="encomenda-title">
                    Encomenda
                    <span class="encomenda-number">#{{ apiData.id }}</span>
                  </h4>
                  <div class="encomenda-date-wrapper">
                    <p class="encomenda-date-title">Date Issued:</p>
                    <p class="encomenda-date">{{ apiData.issuedDate }}</p>
                  </div>
                  <div class="encomenda-date-wrapper">
                    <p class="encomenda-date-title">Due Date:</p>
                    <p class="encomenda-date">{{ apiData.dueDate }}</p>
                  </div>
                </div>
              </div>
              <!-- Header Ends -->
            </div>

            <hr class="encomenda-spacing" />

            <!-- Address And Contact Starts -->
            <div class="card-body encomenda-padding pt-0">
              <div class="row encomenda-spacing">
                <div class="col-xl-8 p-0">
                  <h6 class="mb-2">Encomenda To:</h6>
                  <h6 class="mb-25">{{ apiData.client.name }}</h6>
                  <p class="card-text mb-25">{{ apiData.client.company }}</p>
                  <p class="card-text mb-25">{{ apiData.client.address + ' ' + apiData.client.country }}</p>
                  <p class="card-text mb-25">{{ apiData.client.contact }}</p>
                  <p class="card-text mb-0">{{ apiData.client.companyEmail }}</p>
                </div>
                <div class="col-xl-4 p-0 mt-xl-0 mt-2">
                  <h6 class="mb-2">Payment Details:</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">Total Due:</td>
                        <td>
                          <span class="font-weight-bold">{{ paymentDetails.totalDue }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">Bank name:</td>
                        <td>{{ paymentDetails.bankName }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Country:</td>
                        <td>{{ paymentDetails.country }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">IBAN:</td>
                        <td>{{ paymentDetails.iban }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">SWIFT code:</td>
                        <td>{{ paymentDetails.swiftCode }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- Address And Contact Ends -->

            <!-- Encomenda Description Starts -->
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="py-1">Task description</th>
                    <th class="py-1">Rate</th>
                    <th class="py-1">Hours</th>
                    <th class="py-1">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="py-1">
                      <p class="card-text font-weight-bold mb-25">Native App Development</p>
                      <p class="card-text text-nowrap">
                        Developed a full stack native app using React Native, Bootstrap & Python
                      </p>
                    </td>
                    <td class="py-1">
                      <span class="font-weight-bold">$60.00</span>
                    </td>
                    <td class="py-1">
                      <span class="font-weight-bold">30</span>
                    </td>
                    <td class="py-1">
                      <span class="font-weight-bold">$1,800.00</span>
                    </td>
                  </tr>
                  <tr class="border-bottom">
                    <td class="py-1">
                      <p class="card-text font-weight-bold mb-25">Ui Kit Design</p>
                      <p class="card-text text-nowrap">
                        Designed a UI kit for native app using Sketch, Figma & Adobe XD
                      </p>
                    </td>
                    <td class="py-1">
                      <span class="font-weight-bold">$60.00</span>
                    </td>
                    <td class="py-1">
                      <span class="font-weight-bold">20</span>
                    </td>
                    <td class="py-1">
                      <span class="font-weight-bold">$1200.00</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="card-body encomenda-padding pb-0">
              <div class="row encomenda-sales-total-wrapper">
                <div class="col-md-6 order-md-1 order-2 mt-md-0 mt-3">
                  <p class="card-text mb-0">
                    <span class="font-weight-bold">Salesperson:</span> <span class="ml-75">Alfie Solomons</span>
                  </p>
                </div>
                <div class="col-md-6 d-flex justify-content-end order-md-2 order-1">
                  <div class="encomenda-total-wrapper">
                    <div class="encomenda-total-item">
                      <p class="encomenda-total-title">Subtotal:</p>
                      <p class="encomenda-total-amount">$1800</p>
                    </div>
                    <div class="encomenda-total-item">
                      <p class="encomenda-total-title">Discount:</p>
                      <p class="encomenda-total-amount">$28</p>
                    </div>
                    <div class="encomenda-total-item">
                      <p class="encomenda-total-title">Tax:</p>
                      <p class="encomenda-total-amount">21%</p>
                    </div>
                    <hr class="my-50" />
                    <div class="encomenda-total-item">
                      <p class="encomenda-total-title">Total:</p>
                      <p class="encomenda-total-amount">$1690</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Encomenda Description Ends -->

            <hr class="encomenda-spacing" />

            <!-- Encomenda Note Starts -->
            <div class="card-body encomenda-padding pt-0">
              <div class="row">
                <div class="col-12">
                  <span class="font-weight-bold">Note: </span>
                  <span
                    >It was a pleasure working with you and your team. We hope you will keep us in mind for future
                    freelance projects. Thank You!</span
                  >
                </div>
              </div>
            </div>
            <!-- Encomenda Note Ends -->
          </div>
        </div>
        <!--/ Encomenda -->

        <!-- Encomenda Actions -->
        <div class="col-xl-3 col-md-4 col-12 encomenda-actions mt-md-0 mt-2">
          <div class="card">
            <div class="card-body">
              <button
                class="btn btn-primary btn-block mb-75"
                data-toggle="modal"
                data-target="#send-encomenda-sidebar"
                (click)="toggleSidebar('encomenda-sidebar')"
                rippleEffect
              >
                Send Encomenda
              </button>
              <button class="btn btn-outline-secondary btn-block btn-download-encomenda mb-75" rippleEffect>
                Download
              </button>
              <a class="btn btn-outline-secondary btn-block mb-75" href="javascript:window.print();" rippleEffect>
                Print
              </a>
              <a
                class="btn btn-outline-secondary btn-block mb-75"
                routerLink="/apps/encomenda/edit/{{ urlLastValue }}"
                rippleEffect
              >
                Edit
              </a>
              <button
                class="btn btn-success btn-block"
                data-toggle="modal"
                data-target="#add-payment-sidebar"
                (click)="toggleSidebar('add-payment-sidebar')"
                rippleEffect
              >
                Add Payment
              </button>
            </div>
          </div>
        </div>
        <!--/ Encomenda Actions -->
      </div>
    </section>
  </div>

  <!-- Send Encomenda Sidebar -->
  <core-sidebar
    class="modal modal-slide-in sidebar-todo-modal fade"
    name="encomenda-sidebar"
    overlayClass="modal-backdrop"
    hideOnEsc="true"
  >
    <app-send-encomenda-sidebar-preview></app-send-encomenda-sidebar-preview>
  </core-sidebar>
  <!--/  Send Encomenda Sidebar -->

  <!-- Add Payment Sidebar -->
  <core-sidebar
    class="modal modal-slide-in sidebar-todo-modal fade"
    name="add-payment-sidebar"
    overlayClass="modal-backdrop"
    hideOnEsc="true"
  >
    <app-add-payment-sidebar-preview></app-add-payment-sidebar-preview>
  </core-sidebar>
  <!--/  Add Payment Sidebar -->
</div>
