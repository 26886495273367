import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { Subject } from "rxjs";
import { ReservaService } from "../reserva.service";
import { CoreConfigService } from "@core/services/config.service";
import { takeUntil } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "reserva-guests",
  templateUrl: "./reserva-guests.component.html",
  styleUrls: ["./reserva-guests.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ReservaGuestsComponent implements OnInit, OnDestroy {
    @Input() public reserva: any;
  // public
  public data: any;
  public selectedOption = 10;
  public ColumnMode = ColumnMode
  public searchValue = "";
  public page:any;

  // decorator
  @ViewChild(DatatableComponent) table: DatatableComponent;

  // private
  private tempData = [];
  private _unsubscribeAll: Subject<any>;
  public rows = [];
  public tempFilterData;

  

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {EncomendaListService} _encomendaListService
   */
  constructor(
    public activeModal: NgbActiveModal,
    private _reservaService: ReservaService,
    private _coreConfigService: CoreConfigService
  ) {
    this.page = this._reservaService.convidado_paging;
    this._unsubscribeAll = new Subject();
  }

  /**
   * filterUpdate
   *
   * @param event
   */
  filterUpdate(event) {
    this._reservaService.updateConvidadoFilter({ search: event.target.value });
  }

  /**
   * Called whenever the user changes page
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this._reservaService.updateConvidadoPaging(this.page);
  }

  /**
   * Called whenever the user changes the sort order
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    // there will always be one "sort" object if "sortType" is set to "single"
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this._reservaService.updateConvidadoPaging(this.page);
  }

  ngOnInit(): void {
    
    // Subscribe config change
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        // If we have zoomIn route Transition then load datatable after 450ms(Transition will finish in 400ms)
        if (config.layout.animation === "zoomIn") {
          setTimeout(() => {
            this._reservaService.onConvidadoListChanged
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((response) => {
                this.data = response;
                this.rows = this.data;
                this.tempData = this.rows;
                this.tempFilterData = this.rows;
                this.page = this._reservaService.convidado_paging;
              });
          }, 450);
        } else {
          this._reservaService.onConvidadoListChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response) => {
              this.data = response;
              this.rows = this.data;
              this.tempData = this.rows;
              this.tempFilterData = this.rows;
              this.page = this._reservaService.convidado_paging;
            });
        }
      });
      this._reservaService.updateConvidadoFilter({ search: "", reserva_id: this.reserva.Reserva.id });
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  
}
