<div class="card mb-0 h-100 d-inline-block w-100"
[ngClass]="{'bg-warning': evento && evento.EquipamentoEvento && evento.EquipamentoEvento.dc_observacao_cadastro,
'bg-danger': evento && evento.EquipamentoEvento && evento.EquipamentoEvento.id_restricao == 1
}"
>
  <div class="card-body" *ngIf="evento && evento.EquipamentoEvento">
    <div
      class="d-flex justify-content-between align-items-center flex-column"
      *ngIf="
        !evento.EquipamentoEvento.pessoa_id &&
        !evento.EquipamentoEvento.tp_alarme
      "
    >
      <div class="profile-image-wrapper">
        <div class="profile-image p-1">
          <div class="avatar">
            <img
              src="{{
                evento.Pessoa.dc_picture_url ||
                  'assets/images/avatars/default.jpg'
              }}"
              alt="Profile Picture"
              class="thumbnail-image"
            />
          </div>
        </div>
      </div>
      <h3
        class="badge badge-pill"
        title="{{ evento.EquipamentoEvento.tp_evento }}"
        [ngClass]="{
          'badge-light-primary':
            evento.EquipamentoEvento.tp_evento == 'liberado-sem-passagem',
          'badge-light-success':
            evento.EquipamentoEvento.tp_evento == 'liberado' ||
            evento.EquipamentoEvento.tp_evento == 'porta-fechada',
          'badge-light-danger':
            evento.EquipamentoEvento.tp_evento == 'nao-identificado' ||
            evento.EquipamentoEvento.tp_evento == 'negado' ||
            (evento.EquipamentoEvento.tp_evento == 'porta-aberta' &&
              evento.EquipamentoEvento.dc_evento == 'Porta esquecida aberta') ||
            evento.EquipamentoEvento.tp_evento == 'porta-violada',
          'badge-light-warning':
            evento.EquipamentoEvento.tp_evento == 'porta-aberta' &&
            evento.EquipamentoEvento.dc_evento != 'Porta esquecida aberta'
        }"
      >
        {{ evento.EquipamentoEvento.dc_evento }}
      </h3>
    </div>
    <div
      class="d-flex justify-content-between align-items-center flex-column"
      *ngIf="
        !evento.EquipamentoEvento.pessoa_id &&
        evento.EquipamentoEvento.tp_alarme
      "
    >
      <div class="profile-image-wrapper">
        <div class="profile-image p-1">
          <div
            class="avatar bg-light-{{
              config[evento.EquipamentoEvento.tp_alarme].color
            }} p-25 m-0"
          >
            <div class="avatar-content">
              <i
                [data-feather]="config[evento.EquipamentoEvento.tp_alarme].icon"
                class="font-medium-5"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <h3
        class="badge badge-pill"
        title="{{ evento.EquipamentoEvento.tp_evento }}"
        [ngClass]="{
          'badge-light-primary':
            evento.EquipamentoEvento.tp_evento == 'liberado-sem-passagem',
          'badge-light-success':
            evento.EquipamentoEvento.tp_evento == 'liberado' ||
            evento.EquipamentoEvento.tp_evento == 'porta-fechada',
          'badge-light-danger':
            evento.EquipamentoEvento.tp_evento == 'nao-identificado' ||
            evento.EquipamentoEvento.tp_evento == 'negado' ||
            (evento.EquipamentoEvento.tp_evento == 'porta-aberta' &&
              evento.EquipamentoEvento.dc_evento == 'Porta esquecida aberta') ||
            evento.EquipamentoEvento.tp_evento == 'porta-violada',
          'badge-light-warning':
            evento.EquipamentoEvento.tp_evento == 'porta-aberta' &&
            evento.EquipamentoEvento.dc_evento != 'Porta esquecida aberta'
        }"
      >
        {{ evento.EquipamentoEvento.dc_evento }}
      </h3>
    </div>
    <div
      class="d-flex justify-content-between align-items-center flex-column"
      *ngIf="evento.EquipamentoEvento.pessoa_id"
    >
      <div class="profile-image-wrapper">
        <div class="profile-image p-0 mb-1 h-full">
          <div class="avatar">
            <img
              src="{{
                evento.Pessoa.dc_picture_url ||
                  'assets/images/avatars/default.jpg'
              }}"
              alt="Profile Picture"
              class="thumbnail-image"
            />
          </div>
        </div>
      </div>
      <h4>{{ evento.Pessoa.nm_pessoa }}</h4>
      <h4
        class="badge badge-pill"
        title="{{ evento.EquipamentoEvento.tp_evento }}"
        [ngClass]="{
          'badge-light-primary':
            evento.EquipamentoEvento.tp_evento == 'liberado-sem-passagem',
          'badge-light-success':
            evento.EquipamentoEvento.tp_evento == 'liberado' ||
            evento.EquipamentoEvento.tp_evento == 'porta-fechada',
          'badge-light-danger':
            evento.EquipamentoEvento.tp_evento == 'nao-identificado' ||
            evento.EquipamentoEvento.tp_evento == 'negado' ||
            (evento.EquipamentoEvento.tp_evento == 'porta-aberta' &&
              evento.EquipamentoEvento.dc_evento == 'Porta esquecida aberta') ||
            evento.EquipamentoEvento.tp_evento == 'porta-violada',
          'badge-light-warning':
            evento.EquipamentoEvento.tp_evento == 'porta-aberta' &&
            evento.EquipamentoEvento.dc_evento != 'Porta esquecida aberta'
        }"
      >
        {{ evento.EquipamentoEvento.dc_evento }}
      </h4>
      <h6 *ngIf="evento.PessoaPerfil.nm_perfil" class="text-muted">
        {{ evento.PessoaPerfil.nm_perfil }}
      </h6>
      <h6 *ngIf="evento.Pessoa.dc_email" class="text-muted">
        {{ evento.Pessoa.dc_email }}
      </h6>
      <h6
        class="text-muted"
        *ngIf="evento.Pessoa.nr_cpf || evento.Pessoa.nr_rg"
      >
        <i class="feather icon-credit-card"></i>
        {{ evento.Pessoa.nr_cpf || evento.Pessoa.nr_rg }}
      </h6>
      <h6 *ngIf="evento.Pessoa.nr_telefone" class="text-muted">
        <i class="feather icon-phone"></i>
        {{ evento.Pessoa.nr_telefone }}
      </h6>
      <div
        class="badge badge-light-primary profile-badge"
        *ngIf="evento.Pessoa.nm_empresa"
      >
        {{ evento.Pessoa.nm_empresa }}
      </div>
      <div class="text-left w-100">
        <h6 class="text-muted">
          <i class="feather icon-calendar"></i>
          {{ evento.EquipamentoEvento.created | date : "dd/MM/yyyy HH:mm:ss" }}
        </h6>
        <h6 class="text-muted">
          <i class="feather icon-airplay"></i>
          {{ evento.Leitor.nm_leitor }}
        </h6>
        <h6 class="text-muted">
          <i class="feather icon-repeat"></i>
          {{ evento.EquipamentoEvento.dv_id_direcao }}
        </h6>
        <h6 class="text-muted">
          <i class="feather icon-maximize"></i>
          {{ evento.EquipamentoEvento.dv_id_biometria }}
        </h6>
        <h6 class="text-muted" *ngIf="evento.EquipamentoEvento.veiculo_id">
          <i class="feather icon-truck"></i>
          {{ evento.Veiculo.nm_veiculo }}
        </h6>
      </div>
      <!-- <h6 *ngIf="evento.EquipamentoEvento.dc_placa" class="text-muted">
                    {{ evento.EquipamentoEvento.dc_placa }}
                  </h6>
                  <h6 *ngIf="evento.EquipamentoEvento.dc_modelo" class="text-muted">
                    {{ evento.EquipamentoEvento.dc_modelo }}
                  </h6> -->
    </div>
  </div>
</div>
