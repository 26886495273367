import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { EncomendaListService } from "../encomenda-list/encomenda-list.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EncomendaDeliverService } from "../encomenda-deliver/encomenda-deliver.service";

@Component({
  selector: "app-edit-encomenda-sidebar",
  styleUrls: ["./edit-encomenda-sidebar.component.scss"],
  templateUrl: "./edit-encomenda-sidebar.component.html",
})
export class EditEncomendaSidebarComponent implements OnInit {
  public encomenda: any;
  loading: boolean = false;
  init: boolean = true;
  moradores: any[] = [];
  public EncomendaForm: FormGroup;
  public submitted = false;

  /**
   * Constructor
   *
   * @param {EncomendaAddService} _encomendaAddService
   * @param {CoreSidebarService} _coreSidebarService
   */
  constructor(
    private _coreSidebarService: CoreSidebarService,
    private formBuilder: FormBuilder,
    private encomendaDeliverService: EncomendaDeliverService,
    private _encomendaListService: EncomendaListService,
    
  ) {
    
  }

  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  ngOnInit(): void {
    this._encomendaListService.onCurrentEncomendaChange.subscribe(
      (encomenda) => {
        this.encomenda = encomenda;
        console.log(encomenda);
        if (this.encomenda?.Encomenda && this.encomenda?.Encomenda?.unidade_id) {
          this.encomendaDeliverService
            .getMoradores(this.encomenda?.Encomenda.unidade_id)
            .subscribe((res) => {
              this.moradores = res;
            });
        }
      }
    );
    this.EncomendaForm = this.formBuilder.group({
      usuario_retirada_id: [
        this.encomenda?.usuario_retirada_id,
        Validators.required,
      ],
    });
  }

  get VForm() {
    return this.EncomendaForm.controls;
  }
  EncomendaFormOnSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.EncomendaForm.invalid) {
      return;
    }

    this._encomendaListService
      .addEntrega({
        usuario_retirada_id: this.encomenda.usuario_retirada_id,
        id: this.encomenda.Encomenda.id,
      })
      .then((data: any) => {
        this._coreSidebarService
          .getSidebarRegistry("edit-encomenda-sidebar")
          .close();
        this.EncomendaForm.reset();
        this.submitted = false;
        Swal.fire({
          icon: "success",
          title: "Entrega registrada com sucesso",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Não foi possível registrar entrega",
          html: (error.text || error) + "",
        });
      });
  }
}
