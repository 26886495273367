import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";

import { BehaviorSubject, Observable } from "rxjs";

import { environment } from "environments/environment";

@Injectable()
export class AutorizadoService implements Resolve<any> {
  rows: [] = [];
  paging = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: "Autorizado.dt_permitida",
    orderDir: "asc",
  };
  filter = {
    search: "",
  };
  nextAuthorizeds: [] = [];
  onAuthorizedListChanged: BehaviorSubject<any>;
  onNextAuthorizedListChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this.onAuthorizedListChanged = new BehaviorSubject([]);
    this.onNextAuthorizedListChanged = new BehaviorSubject([]);
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows(), this.getNextAuthorizeds()]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  /**
   * Get rows
   */
  getDataTableRows(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(
          `${environment.apiUrl}/autorizados.json`,
          { ControleAreaItemFilter: this.filter },
          { params: this.paging }
        )
        .subscribe((response: any) => {
          this.rows = response.data;
          this.paging.count = response.total;
          this.onAuthorizedListChanged.next(this.rows);
          resolve(this.rows);
        }, reject);
    });
  }

  /**
   * Get next Reservas
   *
   * */
  getNextAuthorizeds(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      console.log(
        "AutorizadoService.getNextAuthorizeds()",
        `${environment.apiUrl}/autorizados/index/pendentes.json`
      );
      this._httpClient
        .get(`${environment.apiUrl}/autorizados/index/pendentes.json`)
        .subscribe((response: any) => {
          this.nextAuthorizeds = response;
          this.onNextAuthorizedListChanged.next(this.nextAuthorizeds);
          resolve(this.nextAuthorizeds);
        }, reject);
    });
  }

  updatePaging(paging: any) {
    this.paging = { ...this.paging, ...paging };
    this.getDataTableRows();
  }
  updateFilter(filter: any) {
    this.filter = { ...this.filter, ...filter };
    this.paging.offset = 0;
    this.getDataTableRows();
  }
}