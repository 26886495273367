export class Usuario {
    id: number;
    nm_usuairo: string;
    nr_telefone: string;
    nr_celular: string;
    nr_cpf_cnpj: string;
    nr_rg_ie: string;
    dc_picture_url: string;
    dc_email: string;
    dt_nascimento: string;
    dc_estado: string;
    dc_cidade: string;
    dc_logradouro: string;
    dc_numero: string;
    dc_cep: string;
    dc_bairro: string;
    dc_complemento: string;
    
    created: string;
    updated: string;
  }