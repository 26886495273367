<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- Encomenda List -->
    <section class="encomenda-list-wrapper">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Recebimento e Entrega de Encomendas</h4>
          <div ngbDropdown>
            <button
              class="btn-icon btn btn-transparent hide-arrow btn-sm"
              type="button"
              aria-haspopup="true"
              aria-expanded="false"
              ngbDropdownToggle
            >
              <i
                data-feather="more-vertical"
                id="brtn-options"
                class="font-medium-3"
              ></i>
            </button>
            <div
              ngbDropdownMenu
              class="dropdown-menu-right"
              aria-labelledby="brtn-options"
            >
              <a ngbDropdownItem (click)="download()">Download CSV</a>
            </div>
          </div>
        </div>
        <!-- Encomenda List Header -->
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <div class="d-flex align-items-center">
                <label class="d-flex align-items-center"
                  >Exibir
                  <select
                    class="form-control mx-25"
                    [(ngModel)]="page.limit"
                    (change)="pageCallback({ offset: 0 })"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </label>
                <!-- routerLink="/apps/encomenda/add" -->
                <button
                  class="btn btn-info ml-2"
                  rippleEffect
                  (click)="modalOpenForm(encomendaForm)"
                >
                  <i data-feather="log-in" class="font-medium-2"></i> Registrar
                  Recebimento
                </button>
                <button
                  class="btn btn-success ml-2"
                  rippleEffect
                  (click)="modalOpenEncomendaDeliver()"
                >
                  <i data-feather="log-out" class="font-medium-2"></i> Registrar
                  Entrega
                </button>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-12 d-flex justify-content-end align-items-center pr-3"
          >
            <label class="d-flex align-items-center mb-0 mr-1"
              ><input
                [(ngModel)]="searchValue"
                name="searchValue"
                type="search"
                class="form-control"
                placeholder="Pesquisar..."
                (keyup)="filterUpdate($event)"
                (search)="filterUpdate($event)"
            /></label>
            <label class="mr-1"
              ><input
                [(ngModel)]="dataReferencia"
                name="dataReferencia"
                type="date"
                class="form-control"
                placeholder="Data de Referência"
                (change)="filterUpdateByDate($event)"
            /></label>
            <ng-select
              [items]="selectStatus"
              [(ngModel)]="selectedStatus"
              bindLabel="name"
              placeholder="Selecionar Status"
              (change)="filterByStatus($event)"
            ></ng-select>
          </div>
        </div>
        <!--/ Encomenda List Header -->

        <!-- Encomenda List Table -->
        <ngx-datatable
          [rows]="rows"
          [rowHeight]="50"
          class="bootstrap core-bootstrap"
          [limit]="selectedOption"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          [scrollbarH]="true"
          [externalPaging]="true"
          [externalSorting]="true"
          [count]="page.count"
          [offset]="page.offset"
          [limit]="page.limit"
          [sortType]="'single'"
          (page)="pageCallback($event)"
          (sort)="sortCallback($event)"
        >
          <ngx-datatable-column name="#" prop="Encomenda.id" [width]="10">
            <ng-template let-id="value" ngx-datatable-cell-template>
              <a
                data-toggle="modal"
                data-target="#view-encomenda-sidebar"
                (click)="viewData(id)"
                class="font-weight-bold"
                >#{{ id }}</a
              >
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="Data"
            prop="Encomenda.dt_recebimento"
            [width]="70"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <div class="cell-line-height">
                <span class="d-block text-nowrap">{{ value }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="Status"
            prop="Encomenda.st_encomenda"
            [width]="30"
          >
            <ng-template
              let-row="row"
              let-encomendaStatus="value"
              ngx-datatable-cell-template
            >
              <!-- <a class="font-weight-bold">{{ encomendaStatus }}</a> -->
              <div
                class="avatar avatar-status"
                [ngClass]="{
                  'bg-light-success': row.Encomenda.st_encomenda == 'retirada',
                  'bg-light-warning': row.Encomenda.st_encomenda == 'devolvida',
                  'bg-light-danger': row.Encomenda.st_encomenda == 'pendente'
                }"
              >
                <ng-template #tipContent
                  ><span
                    >{{ row.Encomenda.dv_st_encomenda }}<br />
                    <span class="font-weight-bold">Recebimento:</span>
                    {{ row.Encomenda.dt_recebimento }}
                    <ng-container
                      *ngIf="row.Encomenda.st_encomenda == 'retirada'"
                    >
                      <br />
                      <span class="font-weight-bold">Entrega:</span>
                      {{ row.Encomenda.dt_retirada }}
                    </ng-container>
                  </span>
                </ng-template>
                <span
                  class="avatar-content"
                  placement="top"
                  [ngbTooltip]="tipContent"
                  container="body"
                >
                  <i
                    class="feather avatar-icon font-medium-1"
                    [ngClass]="{
                      'icon-check-circle':
                        row.Encomenda.st_encomenda == 'retirada',
                      'icon-arrow-down-circle':
                        row.Encomenda.st_encomenda == 'pendente',
                      'icon-info': row.Encomenda.st_encomenda == 'devolvida'
                    }"
                  ></i
                ></span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="Recebido por"
            prop="UsuarioRecebimento.nm_usuario"
            [width]="150"
          >
            <ng-template
              let-row="row"
              let-name="value"
              ngx-datatable-cell-template
            >
              <div class="d-flex align-items-center">
                <div
                  *ngIf="
                    row.UsuarioRecebimento.dc_picture_url;
                    else customAvatar
                  "
                >
                  <img
                    class="rounded-circle mr-1"
                    src="{{ row.UsuarioRecebimento.dc_picture_url }}"
                    height="32"
                    width="32"
                    alt="datatable-avatar"
                  />
                </div>
                <ng-template #customAvatar>
                  <div
                    class="avatar mr-1 ml-0"
                    [ngClass]="{
                      'bg-light-success':
                        row.Encomenda.st_encomenda == 'retirada',
                      'bg-light-warning':
                        row.Encomenda.st_encomenda == 'devolvida',
                      'bg-light-danger':
                        row.Encomenda.st_encomenda == 'pendente'
                    }"
                  >
                    <div class="avatar-content">{{ name | initials }}</div>
                  </div>
                </ng-template>
                <div class="cell-line-height">
                  <span
                    class="font-weight-bold d-block text-nowrap font-medium-1"
                    >{{ name }}</span
                  >
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="Remetente"
            prop="Encomenda.nm_remetente"
            [width]="50"
          >
            <ng-template
              let-row="row"
              let-name="value"
              ngx-datatable-cell-template
            >
              <div class="cell-line-height">
                <span class="d-block text-nowrap">{{ name }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="Unidade"
            prop="Unidade.nm_display"
            [width]="50"
          >
            <ng-template
              let-row="row"
              let-name="value"
              ngx-datatable-cell-template
            >
              <div class="cell-line-height">
                <span class="d-block text-nowrap">{{ name }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="Destinatário"
            prop="Encomenda.nm_destinatario"
            [width]="50"
          >
            <ng-template
              let-row="row"
              let-name="value"
              ngx-datatable-cell-template
            >
              <div class="cell-line-height">
                <span class="d-block text-nowrap">{{ name }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Ações" [width]="40" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="d-flex align-items-center col-actions">
                <!-- <a
                  class="mr-1"
                  href="javascript:void(0);"
                  container="body"
                  placement="top"
                  ngbTooltip="Send Mail"
                  ><i size="18" data-feather="send"></i
                ></a> -->
                <a
                  class="mr-1"
                  data-toggle="modal"
                  data-target="#view-encomenda-sidebar"
                  (click)="viewData(row.Encomenda.id)"
                  container="body"
                  placement="top"
                  ngbTooltip="Visualizar Detalhes"
                  ><i size="18" data-feather="eye"></i
                ></a>
                <div ngbDropdown container="body" *ngIf="row.Encomenda.st_encomenda == 'pendente' && (currentUser.role == 'Admin' || currentUser.role == 'AdminWithChat')">
                  <button
                    ngbDropdownToggle
                    type="button"
                    class="btn icon-btn btn-sm hide-arrow px-0"
                    rippleEffect
                  >
                    <i
                      data-feather="more-vertical"
                      size="18"
                      class="cursor-pointer"
                    ></i>
                  </button>
                  <div ngbDropdownMenu>
                    <!-- <a href="javascript:void(0)" ngbDropdownItem
                      ><i data-feather="download" class="mr-1"></i>Download</a
                    > -->
                   
                    <!-- routerLink="/apps/encomenda/edit/{{ row.Encomenda.id }}" -->
                    <a
                      data-toggle="modal"
                      data-target="#edit-encomenda-sidebar"
                      (click)="editData(row.Encomenda.id)"
                      ngbDropdownItem
                      ><i data-feather="log-out" class="mr-1"></i>Registrar Entrega</a
                    >
                    <!-- <a href="javascript:void(0)" ngbDropdownItem
                      ><i data-feather="trash" class="mr-1"></i>Delete</a
                    ><a href="javascript:void(0)" ngbDropdownItem
                      ><i data-feather="copy" class="mr-1"></i>Duplicate</a
                    > -->
                  </div>
                </div> 
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
        <!--/ Encomenda List Table -->
      </div>
    </section>
    <!--/ Encomenda List -->
  </div>
  <core-sidebar
    class="modal modal-slide-in sidebar-todo-modal fade"
    name="edit-encomenda-sidebar"
    overlayClass="modal-backdrop"
    hideOnEsc="true"
  >
    <app-edit-encomenda-sidebar></app-edit-encomenda-sidebar>
  </core-sidebar>
  <core-sidebar
    class="modal modal-slide-in sidebar-todo-modal fade"
    name="view-encomenda-sidebar"
    overlayClass="modal-backdrop"
    hideOnEsc="true"
  >
    <app-view-encomenda-sidebar></app-view-encomenda-sidebar>
  </core-sidebar>
</div>
<!-- Modal -->
<ng-template #encomendaForm let-modal>
  <form
    [formGroup]="EncomendaForm"
    (ngSubmit)="EncomendaFormOnSubmit()"
    #formEncomenda
  >
    <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel1">Receber Encomenda</h4>
      <button
        type="button"
        class="close"
        (click)="modal.dismiss('Cross click')"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <h6 class="mb-2">1. Dados da Encomenda</h6>
      <div class="row">
        <div class="col-md-8 col-12">
          <div class="row">
            <div class="col-md-6 col-12">
              <label>Tipo: </label>
              <div class="form-group">
                <ng-select
                  [items]="tipo_encomendas"
                  [clearable]="false"
                  bindLabel="TipoEncomenda.nm_tipo"
                  bindValue="TipoEncomenda.id"
                  name="tipo_encomenda_id"
                  [(ngModel)]="encomenda.tipo_encomenda_id"
                  formControlName="tipo_encomenda_id"
                  [ngClass]="{
                    'is-invalid': submitted && VForm.tipo_encomenda_id.errors
                  }"
                >
                </ng-select>
                <div
                  *ngIf="submitted && VForm.tipo_encomenda_id.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="VForm.tipo_encomenda_id.errors.required">
                    Tipo de encomenda é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <label>Código: </label>
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Código de rastreio"
                  class="form-control"
                  name="cd_encomenda"
                  [(ngModel)]="encomenda.cd_encomenda"
                  formControlName="cd_encomenda"
                  [ngClass]="{
                    'is-invalid': submitted && VForm.cd_encomenda.errors
                  }"
                />
                <div
                  *ngIf="submitted && VForm.cd_encomenda.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="VForm.cd_encomenda.errors.required">
                    Código de rastreio é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label>Remetente: </label>
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Nome do Remetente"
                  class="form-control"
                  name="nm_remetente"
                  [(ngModel)]="encomenda.nm_remetente"
                  formControlName="nm_remetente"
                  [ngClass]="{
                    'is-invalid': submitted && VForm.nm_remetente.errors
                  }"
                />
                <div
                  *ngIf="submitted && VForm.nm_remetente.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="VForm.nm_remetente.errors.required">
                    Nome do remetente é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label>Observações: </label>
              <div class="form-group">
                <textarea
                  placeholder="Observações"
                  class="form-control"
                  name="dc_observacao"
                  [(ngModel)]="encomenda.dc_observacao"
                  formControlName="dc_observacao"
                  [ngClass]="{
                    'is-invalid': submitted && VForm.dc_observacao.errors
                  }"
                ></textarea>
                <div
                  *ngIf="submitted && VForm.dc_observacao.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="VForm.dc_observacao.errors.required">
                    Observação é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <!-- header media -->
          <div *ngIf="imageMode == 'camera'">
            <div class="row">
              <div class="col-md-12 text-center">
                <div class="rounded mb-75 object-fit-cover">
                  <webcam
                    [trigger]="invokeObservable"
                    (imageCapture)="captureImg($event)"
                    [width]="200"
                    [height]="200"
                  ></webcam>
                </div>
              </div>
              <div class="col-md-12 text-center">
                <button
                  type="button"
                  class="btn btn-sm btn-primary mb-75 mr-75"
                  (click)="getSnapshot()"
                >
                  Capturar
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-outline-secondary mb-75"
                  rippleEffect
                  (click)="imageMode = 'file'"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="imageMode == 'file'">
            <div class="row">
              <div class="col-12 text-center">
                <a href="javascript:void(0);">
                  <img
                    [src]="avatarImage"
                    class="rounded mb-75 object-fit-cover shadow"
                    alt=""
                    height="200"
                    width="200"
                    (click)="file.click()"
                  />
                </a>
              </div>
              <div class="col-12 text-center">
                <button
                  type="button"
                  (click)="file.click()"
                  for="account-upload"
                  class="btn btn-sm btn-primary mb-75 mr-75"
                  rippleEffect
                >
                  Upload
                </button>
                <input
                  type="file"
                  #file
                  id="account-upload"
                  hidden
                  accept="image/*"
                  (change)="uploadImage($event)"
                />
                <button
                  type="button"
                  class="btn btn-sm btn-outline-secondary mb-75"
                  rippleEffect
                  (click)="imageMode = 'camera'"
                >
                  Câmera
                </button>
              </div>
            </div>
          </div>
          <!--/ header media -->
        </div>
      </div>

      <h6 class="mb-2">2. Destinatário</h6>
      <div class="row">
        <div class="col-md-6 col-12">
          <label>Bloco: </label>
          <div class="form-group">
            <ng-select
              [items]="blocos"
              [clearable]="false"
              bindLabel="Bloco.nm_bloco"
              bindValue="Bloco.id"
              [loading]="false"
              name="bloco_id"
              [(ngModel)]="encomenda.bloco_id"
              formControlName="bloco_id"
              [ngClass]="{
                'is-invalid': submitted && VForm.bloco_id.errors
              }"
              (change)="onChangeBloco()"
            >
            </ng-select>
            <div
              *ngIf="submitted && VForm.bloco_id.errors"
              class="invalid-feedback"
            >
              <div *ngIf="VForm.bloco_id.errors.required">
                Bloco é obrigatório
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <label>Unidade: </label>
          <div class="form-group">
            <ng-select
              [items]="unidades"
              [clearable]="false"
              bindLabel="Unidade.nm_unidade"
              bindValue="Unidade.id"
              [loading]="false"
              name="unidade_id"
              [(ngModel)]="encomenda.unidade_id"
              formControlName="unidade_id"
              [ngClass]="{
                'is-invalid': submitted && VForm.unidade_id.errors
              }"
              (change)="onChangeUnidade()"
            >
            </ng-select>
            <div
              *ngIf="submitted && VForm.unidade_id.errors"
              class="invalid-feedback"
            >
              <div *ngIf="VForm.unidade_id.errors.required">
                Unidade é obrigatória
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-12">
          <label>Destinatário: </label>
          <div class="form-group">
            <input
              type="text"
              placeholder="Nome do destinatário"
              class="form-control"
              name="nm_destinatario"
              [(ngModel)]="encomenda.nm_destinatario"
              formControlName="nm_destinatario"
              [ngClass]="{
                'is-invalid': submitted && VForm.nm_destinatario.errors
              }"
            />
            <div
              *ngIf="submitted && VForm.nm_destinatario.errors"
              class="invalid-feedback"
            >
              <div *ngIf="VForm.nm_destinatario.errors.required">
                Destinatário é obrigatório
              </div>
            </div>
          </div>
        </div>
      </div>
      <h6 class="mb-2">3. Notificação</h6>
      <div class="row">
        <!-- <div class="col-md-4 col-12">
          <div class="form-group">
            <label class="d-block mb-1"></label>
            <div class="custom-control custom-checkbox custom-control-inline">
              <input
                type="checkbox"
                class="custom-control-input"
                id="id_notificar"
                name="id_notificar"
                [(ngModel)]="encomenda.id_notificar"
                formControlName="id_notificar"
                (change)="onChangeNotificar()"
              />
              <label class="custom-control-label" for="id_notificar"
                >Notificar Condôminos</label
              >
            </div>
          </div>
        </div> -->

        <div
          class="col-md-8 col-12"
          *ngIf="encomenda.id_notificar && usuarios && usuarios.length > 0"
        >
          <div class="">
            <div class="card-body profile-suggestion" formGroupName="usuarios">
              <h5 class="mb-2">Usuários</h5>
              <!-- user suggestions -->
              <div
                class="d-flex justify-content-start align-items-center"
                [class.mb-1]="usuarios.length - 1 !== usuarios.indexOf(usuario)"
                *ngFor="let usuario of usuarios; let i = index"
                [formGroupName]="i"
              >
                <div
                  *ngIf="usuario.Usuario.dc_picture_url; else customAvatar"
                  class="avatar mr-1"
                >
                  <img
                    src="{{
                      usuario.Usuario.dc_picture_url ||
                        'assets/images/avatars/default.jpg'
                    }}"
                    height="40"
                    width="40"
                    alt="avatar"
                  />
                </div>
                <ng-template #customAvatar>
                  <div class="avatar mr-1">
                    <div class="avatar-content">
                      {{ usuario.Usuario.nm_usuario | initials }}
                    </div>
                  </div>
                </ng-template>
                <!-- <div class="avatar mr-1">
                  <img
                    [src]="usuario.Usuario.dc_picture_url"
                    alt="avatar img"
                    height="40"
                    width="40"
                  />
                </div> -->
                <div class="profile-user-info">
                  <h6 class="mb-0">{{ usuario.Usuario.nm_usuario }}</h6>
                  <small class="text-muted">{{
                    usuario.Usuario.dc_tipo
                  }}</small>
                </div>
                <div
                  class="custom-control custom-switch custom-control-inline ml-auto"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    [(ngModel)]="encomenda.usuarios[i].selected"
                    name="encomendaUsuarios{{ i }}Selected"
                    id="encomendaUsuarios{{ i }}Selected"
                    formControlName="selected"
                  />
                  <label
                    class="custom-control-label"
                    for="encomendaUsuarios{{ i }}Selected"
                  ></label>
                </div>
                <!-- <div class="profile-star ml-auto">
                  <i
                    class="font-medium-3 fa"
                    [ngClass]="{
                      'fa-star text-warning': suggestedPagesRef.favorite === true,
                      'fa-star-o': suggestedPagesRef.favorite !== true
                    }"
                  ></i>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="submitting"
        rippleEffect
      >
        {{ submitting ? "Aguarde..." : "Registrar" }}
      </button>
    </div>
  </form>
</ng-template>
