import { Component, OnInit } from '@angular/core';

import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { VisitanteService } from '../visitante.service';

@Component({
  selector: 'app-view-visitante-sidebar',
  templateUrl: './view-visitante-sidebar.component.html',
  styleUrls: ["./view-visitante-sidebar.component.scss"],
})
export class ViewVisitanteSidebarComponent implements OnInit {
 public visita: any; 

  /**
   * Constructor
   *
   * @param {EncomendaAddService} _encomendaAddService
   * @param {CoreSidebarService} _coreSidebarService
   */
  constructor(private _coreSidebarService: CoreSidebarService,
    private _visitanteService: VisitanteService) {}

  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  ngOnInit(): void {
    this._visitanteService.onCurrentVisitaChange.subscribe((visita) => {
      if(visita.Visitante?.nr_cpf){
        visita.Pessoa.nr_cpf = visita.Visitante?.nr_cpf.replace(/(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/, '$1.$2.XXX-$4');
      }
      this.visita = visita;
      console.log(visita    );
    });
  }
}
