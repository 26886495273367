import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";

import { environment } from "environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class VisitanteService implements Resolve<any> {
  rows: [] = [];
  paging = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: "Visita.id",
    orderDir: "desc",
  };
  filter = {
    search: "",
  };
  public onVisitanteListChanged: BehaviorSubject<any>;
  public currentVisita;
  public onCurrentVisitaChange: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {
    // Set the defaults
    this.onVisitanteListChanged = new BehaviorSubject([]);
    this.onCurrentVisitaChange = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows()]).then(() => {
        resolve();
      }, reject);
    });
  }
  download() {
    return this._http.post(
      `${environment.apiUrl}/visitas/download.json`,
      {
        VisitaFilter: this.filter,
      },
      { observe: "response", responseType: "blob" }
    );
  }
  getUsuarios(unidadeId:number) {
    return this._http.get<any[]>(`${environment.apiUrl}/unidades/moradores/${unidadeId}.json`);
  }
  getUnidade(unidadeId:number) {
    return this._http.get<any>(`${environment.apiUrl}/unidades/view/${unidadeId}.json`);
  }
  /**
   * Get rows
   */
  getDataTableRows(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http
        .post(
          `${environment.apiUrl}/visitas.json`,
          { VisitaFilter: this.filter },
          { params: this.paging }
        )
        .subscribe((response: any) => {
          this.rows = response.data;
          this.paging.count = response.total;
          this.onVisitanteListChanged.next(this.rows);
          resolve(this.rows);
        }, reject);
    });
  }
  getPerfis(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http
        .get(`${environment.apiUrl}/pessoa_perfis/list.json`)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  openDoor(id): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http
        .post(`${environment.apiUrl}/equipamentos/open_door/${id}.json`, {})
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  getDoors(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http
        .get(`${environment.apiUrl}/equipamentos/list_doors.json`)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  getCameras(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http
        .get(`${environment.apiUrl}/equipamentos/list_cameras.json`)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  getDigitais(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http
        .get(`${environment.apiUrl}/equipamentos/list_digitais.json`)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  getEquipmentImage(id: number): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http
        .get(`${environment.apiUrl}/equipamentos/get_image/${id}.json`)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  getEquipmentCapture(
    id: number,
    pessoa_id: number,
    type: string
  ): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http
        .get(
          `${environment.apiUrl}/equipamentos/get_capture/${id}/${pessoa_id}/${type}.json`
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  testImage(id: number, image: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http
        .post(`${environment.apiUrl}/equipamentos/test_image/${id}.json`, {
          image,
        })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get last visits
   * @param {number} limit
   * @returns {Observable<any>}
   * */
  getLastVisits(limit: number = 5) {
    return this._http.get<any[]>(
      `${environment.apiUrl}/visitas/last/${limit}.json`
    );
  }
  getByCpf(cpf: string) {
    return this._http.get<any>(
      `${environment.apiUrl}/pessoas/view_cpf/${cpf}.json`
    );
  }
  getByRg(rg: string) {
    return this._http.get<any>(
      `${environment.apiUrl}/pessoas/view_rg/${rg}.json`
    );
  }
  getUnidades() {
    return this._http.get<any[]>(`${environment.apiUrl}/unidades/list.json`);
  }
  addVisit(visita: any) {
    return this._http.post<any>(`${environment.apiUrl}/visitas/add.json`, {
      Visita: visita,
    });
  }
  registerExit(visitaId) {
    return this._http.put<any>(
      `${environment.apiUrl}/visitas/exit/${visitaId}.json`,
      { Visita: { id: visitaId } }
    );
  }
  getById(id) {
    this._http
      .get<any>(`${environment.apiUrl}/visitas/view/${id}.json`)
      .subscribe((response: any) => {
        this.currentVisita = response;
        this.onCurrentVisitaChange.next(this.currentVisita);
      });
  }
  updatePaging(paging: any) {
    this.paging = { ...this.paging, ...paging };
    this.getDataTableRows();
  }
  updateFilter(filter: any) {
    this.filter = { ...this.filter, ...filter };
    this.paging.offset = 0;
    this.getDataTableRows();
  }
}
