import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "reserva-list",
  templateUrl: "./reserva-list.component.html",
  styleUrls: ["./reserva-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ReservaListComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
