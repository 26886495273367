<div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Lista de Convidados do Evento: {{ reserva.Reserva.nm_evento }}</h4>
    <button
      type="button"
      class="close"
      (click)="activeModal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="row">
        <div class="col-md-6 col-12">
          
        </div>
        <div class="col-md-6 col-12 d-flex justify-content-end align-items-center pr-1">
          <label class=""
            ><input
              [(ngModel)]="searchValue"
              name="searchValue"
              type="search"
              class="form-control"
              placeholder="Pesquisar..."
              (keyup)="filterUpdate($event)"
              (search)="filterUpdate($event)"
          /></label>
        </div>
      </div>
      <!--/ Morador List Header -->

      <!-- Morador List Table -->
      <ngx-datatable
      [rows]="rows"
      [rowHeight]="50"
      class="bootstrap core-bootstrap"
      [columnMode]="ColumnMode.force"
      [headerHeight]="50"
      [footerHeight]="50"
      [scrollbarH]="true"
      [externalPaging]="true"
      [externalSorting]="true"
      [count]="page.count"
      [offset]="page.offset"
      [limit]="page.limit"
      [sortType]="'single'"
      (page)="pageCallback($event)"
      (sort)="sortCallback($event)"
      >
       
        <ngx-datatable-column
        name="Convidado"
        prop="Convidado.nm_convidado"
        [width]="250"
        
      >
        <ng-template
          let-row="row"
          let-name="value"
          ngx-datatable-cell-template
        >
          <div class="d-flex align-items-center">
            <div class="cell-line-height">
              <span
                class="font-weight-bold d-block font-medium-1"
                >{{ name }}</span
              >
              <!-- <span class="text-muted font-small-2">
                {{ row.Usuario.dc_email }}</span
              > -->
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
        <ngx-datatable-column
          name="Presença"
          prop="Convidado.id_presenca"
          [width]="150"
        >
        <ng-template
        let-row="row"
        let-presencaStatus="value"
        ngx-datatable-cell-template
      >
        <!-- <a class="font-weight-bold">{{ encomendaStatus }}</a> -->
        <div
          class="avatar avatar-status"
          [ngClass]="{
            'bg-light-success': row.Convidado.id_presenca == '1',
            'bg-light-warning': row.Convidado.id_presenca == '0'
          }"
        >
          
          <span
            class="avatar-content"
          >
            <i
              class="feather avatar-icon font-medium-1"
              [ngClass]="{
                'icon-check-circle':
                  row.Convidado.id_presenca == '1',
                'icon-arrow-down-circle':
                  row.Convidado.id_presenca == '0'
              }"
            ></i
          ></span>
        </div>
      </ng-template>
        </ngx-datatable-column>
        
        
      </ngx-datatable>
    </div>
    
  