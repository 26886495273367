<ng-container *ngIf="rows && rows.length > 0">
  <!-- for each row in rows show card -->
  <ngb-carousel
    *ngIf="rows"
    [showNavigationIndicators]="true"
    [showNavigationArrows]="true"
    [pauseOnHover]="true"
    [pauseOnFocus]="true"
    [interval]="0"
  >
    <ng-template ngbSlide *ngFor="let row of rows">
      <div class="card card-developer-meetup">
         <div class="bg-light-primary rounded-top text-center">
          <img
            src="{{
              'assets/images/default_reserva.png'
            }}"
            alt="{{ row.Area.nm_area }}"
          />
        </div> 
        <div
          class="card-body d-flex justify-content-around align-items-center"
        >
          <div class="meetup-header d-flex align-items-center">
            <div class="meetup-day">
              <h6 class="mb-0">{{ row.Reserva.dt_reserva | date : "EEE" }}</h6>
              <h3 class="mb-0">{{ row.Reserva.dt_reserva | date : "dd" }}</h3>
            </div>
            <div class="my-auto">
              <h4 class="card-title mb-25">{{ row.Reserva.nm_evento }}</h4>
              <p class="card-text mb-0">{{ row.Area.nm_area }}</p>
            </div>
          </div>
          <!-- <div class="media">
      <div class="avatar bg-light-primary rounded mr-1">
        <div class="avatar-content">
          <i data-feather="map-pin" class="avatar-icon font-medium-3"></i>
        </div>
      </div>
      <div class="media-body">
        <h6 class="mb-0">{{ row.Area.nm_area }}</h6>
        <small>Manhattan, New york City</small>
      </div>
    </div> -->
          <div>
            <div class="row">
              <div class="col mb-0">
                <div class="media">
                  <div class="avatar bg-light-primary rounded mr-1">
                    <div class="avatar-content">
                      <i
                        data-feather="clock"
                        class="avatar-icon font-medium-3"
                      ></i>
                    </div>
                  </div>

                  <div class="media-body">
                    <!-- <h6 class="mb-0">
              {{ row.Reserva.dt_reserva | date : "fullDate" }}
            </h6> -->
                    <small *ngIf="row.Reserva.area_periodo_id"
                      >{{ row.AreaPeriodo.nm_periodo }} - das
                      {{ row.AreaPeriodo.hr_inicio }} às
                      {{ row.AreaPeriodo.hr_termino }}</small
                    >
                    <small *ngIf="!row.Reserva.area_periodo_id"
                      >a partir das {{ row.Reserva.dc_horario }}</small
                    >
                  </div>
                </div>
              </div>
              <div class="col mb-0">
                <div class="media">
                  <div class="avatar bg-light-primary rounded mr-1">
                    <img
                      src="{{
                        row.Usuario.dc_picture_url ||
                          'assets/images/avatars/default.jpg'
                      }}"
                      class="rounded"
                      width="34"
                      height="34"
                      alt="Avatar"
                    />
                  </div>
                  <div class="media-body">
                    <h6 class="mb-0">{{ row.Usuario.nm_usuario }}</h6>
                    <small>{{ row.Unidade.nm_display }}</small>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-between align-items-center mt-2 gap-3"
            >
              <button
                type="button"
                class="btn btn-outline-primary mr-auto"
                (click)="modalOpenReservaGuests(row)"
                rippleEffect
              >
                Lista de Convidados
              </button>
              <button
                *ngIf="row.Reserva.dt_reserva == today"
                type="button"
                class="btn btn-primary ml-auto"
                (click)="modalOpenFormAddConvidado(row)"
                rippleEffect
              >
                Registrar Convidado
              </button>
            </div>
          </div>
          <!-- <div class="media" *ngIf="row.Visita && row.Visita.length > 0">
      <div class="avatar bg-light-primary rounded mr-1">
        <div class="avatar-content">
          <i
            data-feather="users"
            class="avatar-icon font-medium-3"
          ></i>
        </div>
      </div>
      <div class="media-body">
        <div class="avatar-group mt-0">
          <ng-container *ngFor="let visita of row.Visita; let i = index">
            <div
              placement="bottom"
              container="body"
              ngbTooltip="{{ visita.Pessoa.nm_pessoa }}"
              class="avatar pull-up"
              *ngIf="i < 5"
            >
              <img
                src="{{
                  visita.Pessoa.dc_picture_url ||
                    'assets/images/avatars/default.jpg'
                }}"
                alt="Avatar"
                width="33"
                height="33"
              />
            </div>
          </ng-container>
          <h6
            class="align-self-center cursor-pointer ml-50 mb-0"
            *ngIf="row.Visita && row.Visita.length > 5"
          >
            +{{ row.Visita.length - 5 }}
          </h6>
        </div>
      </div>
    </div>       -->
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</ng-container>
<div class="card card-developer-meetup" *ngIf="!rows || rows.length == 0">
  <div class="bg-light-primary rounded-top text-center">
    <img src="{{ 'assets/images/default_reserva.png' }}" alt="" height="170" />
  </div>
  <div class="card-body">
    <h3>Próximas Reservas</h3>
    <p>Não existem reservas confirmadas no momento.</p>
  </div>
</div>
