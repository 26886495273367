import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";

import { BehaviorSubject, Observable } from "rxjs";

import { environment } from "environments/environment";

@Injectable()
export class EncomendaListService implements Resolve<any> {
  rows: [] = [];
  paging = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: 'Encomenda.id',
    orderDir: 'desc'
  };
  filter ={
      search: '',
      st_encomenda: '',
  }
  onEncomendaListChanged: BehaviorSubject<any>;
  public currentEncomenda;
  public onCurrentEncomendaChange: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this.onEncomendaListChanged = new BehaviorSubject([]);
    this.onCurrentEncomendaChange = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    console.log("EncomendaListService.resolve()", route, state);
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows()]).then(() => {
        resolve();
      }, reject);
    });
  }
  download() {
    return this._httpClient.post(
      `${environment.apiUrl}/encomendas/download.json`,
      {
        EncomendaFilter: this.filter,
      },
      { observe: "response", responseType: "blob" }
    );
  }
  /**
   * Get rows
   */
  getDataTableRows(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(`${environment.apiUrl}/encomendas.json`,{EncomendaFilter: this.filter},{params: this.paging})
        .subscribe((response: any) => {
          this.rows = response.data;
          this.paging.count = response.total;
          this.onEncomendaListChanged.next(this.rows);
          resolve(this.rows);
        }, reject);
    });
  }

  getTiposEncomenda(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(`${environment.apiUrl}/tipo_encomendas.json`)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  getBlocos() {
    return this._httpClient.get<any[]>(`${environment.apiUrl}/blocos.json`);
  }
  getUnidades(blocoId) {
    return this._httpClient.get<any[]>(`${environment.apiUrl}/unidades/list/${blocoId}.json`);
  }
  getUsuarios(unidadeId:number) {
    return this._httpClient.get<any[]>(`${environment.apiUrl}/unidades/moradores/${unidadeId}.json`);
  }
  addEncomenda(encomenda: any) {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.apiUrl}/encomendas/add.json`, {
          Encomenda: encomenda,
        })
        .subscribe((response) => {
          this.getDataTableRows().then((encomendas) => {
            resolve(encomendas);
          }, reject);
        }, reject);
    });
  }
  addEntrega(encomenda: any) {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put<any>(
          `${environment.apiUrl}/encomendas/entrega/${encomenda.id}.json`,
          {Encomenda: encomenda}
        )
        .subscribe((response) => {
          this.getDataTableRows().then((encomendas) => {
            resolve(encomendas);
          }, reject);
        });
    });
  }
  getById(id) {
    this._httpClient.get<any>(`${environment.apiUrl}/encomendas/view/${id}.json`).subscribe((response: any) => {
      this.currentEncomenda = response;
      this.onCurrentEncomendaChange.next(this.currentEncomenda);
    });
  }
  updatePaging(paging: any) {
    this.paging = { ...this.paging, ...paging };
    this.getDataTableRows();
  }
  updateFilter(filter: any) {
    this.filter = { ...this.filter, ...filter };
    this.paging.offset = 0;
    this.getDataTableRows();
  }
}
