import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy, OnInit } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";

import { BehaviorSubject, Observable, Subject } from "rxjs";

import { environment } from "environments/environment";
import { AuthenticationService } from "app/auth/service";
import { takeUntil } from "rxjs/operators";

@Injectable()
export class EquipamentoEventoService
  implements Resolve<any>, OnDestroy, OnInit
{
  rows: [] = [];
  paging = {
    limit: 6,
    count: 0,
    offset: 0,
    orderBy: "EquipamentoEvento.id",
    orderDir: "desc",
  };
  filter = {
    search: "",
    grupo_leitor_id: "",
  };
  public onAlertaPendenteListChanged: BehaviorSubject<any>;
  public onPanicoPendenteListChanged: BehaviorSubject<any>;
  public onEquipamentoEventoListChanged: BehaviorSubject<any>;
  public currentEquipamentoEvento;
  public lastEquipamentoEvento;
  public onCurrentEquipamentoEventoChange: BehaviorSubject<any>;
  public onLastEquipamentoEventoChange: BehaviorSubject<any>;
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private authService: AuthenticationService
  ) {
    this._unsubscribeAll = new Subject();
    // Set the defaults
    this.onEquipamentoEventoListChanged = new BehaviorSubject([]);
    this.onCurrentEquipamentoEventoChange = new BehaviorSubject({});
    this.onLastEquipamentoEventoChange = new BehaviorSubject({});
    this.onAlertaPendenteListChanged = new BehaviorSubject([]);
    this.onPanicoPendenteListChanged = new BehaviorSubject([]);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    this.authService.onNewEventSubject
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response) => {
        console.log(response);
        if (response && response.event_type == "event") {
          this.getLastEvent(response.data.EquipamentoEvento.id);
        }
      });
  }

  getLastEvent(id) {
    this._httpClient
      .get<any>(`${environment.apiUrl}/equipamento_eventos/view/${id}.json`)
      .subscribe((response: any) => {
        this.lastEquipamentoEvento = response || null;
        this.onLastEquipamentoEventoChange.next(this.lastEquipamentoEvento);
      });
  }

  getGrupoLeitores(){
    return this._httpClient.get<any[]>(`${environment.apiUrl}/grupo_leitores/list.json`);
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    console.log("EquipamentoEventoListService.resolve()", route, state);
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.getDataTableRows(),
        this.getPendentes("alerta"),
        this.getPendentes("panico"),
      ]).then(() => {
        resolve();
      }, reject);
    });
  }
  download() {
    return this._httpClient.post(
      `${environment.apiUrl}/equipamento_eventos/download.json`,
      {
        EquipamentoEventoFilter: this.filter,
      },
      { observe: "response", responseType: "blob" }
    );
  }
  getPendentes(type): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(`${environment.apiUrl}/equipamento_eventos/pendentes/${type}.json`)
        .subscribe((response: any) => {
          if (type == "alerta") {
            this.onAlertaPendenteListChanged.next(response);
          } else if (type == "panico") {
            this.onPanicoPendenteListChanged.next(response);
          } else {
            throw new Error("Tipo de evento inválido!");
          }
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get rows
   */
  getDataTableRows(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(
          `${environment.apiUrl}/equipamento_eventos.json`,
          { EquipamentoEventoFilter: this.filter },
          { params: this.paging }
        )
        .subscribe((response: any) => {
          this.rows = response.data;
          if (
            !this.lastEquipamentoEvento ||
            this.rows.filter(
              (row: any) =>
                parseInt(row.EquipamentoEvento.id) >
                parseInt(this.lastEquipamentoEvento.EquipamentoEvento.id)
            ).length > 0
          ) {
            //get last event order by id and set as last event
            this.rows
              .sort(
                (a: any, b: any) =>
                  parseInt(b.EquipamentoEvento.id) -
                  parseInt(a.EquipamentoEvento.id)
              )
              .forEach((row: any) => {
                if (
                  !this.lastEquipamentoEvento ||
                  parseInt(row.EquipamentoEvento.id) >
                    parseInt(this.lastEquipamentoEvento.EquipamentoEvento.id)
                ) {
                  this.lastEquipamentoEvento = row;
                  this.onLastEquipamentoEventoChange.next(
                    this.lastEquipamentoEvento
                  );
                }
              });
          }
          this.paging.count = response.total;
          this.onEquipamentoEventoListChanged.next(this.rows);
          resolve(this.rows);
        }, reject);
    });
  }

  getById(id) {
    this.currentEquipamentoEvento = null;
    this.onCurrentEquipamentoEventoChange.next(this.currentEquipamentoEvento);
    this._httpClient
      .get<any>(`${environment.apiUrl}/equipamento_eventos/view/${id}.json`)
      .subscribe((response: any) => {
        this.currentEquipamentoEvento = response;
        this.onCurrentEquipamentoEventoChange.next(
          this.currentEquipamentoEvento
        );
      });
  }

  getMotivos(type) {
    return this._httpClient.get<any[]>(
      `${environment.apiUrl}/alarme_motivos/list/${type}.json`
    );
  }

  update(data): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(
          `${environment.apiUrl}/equipamento_eventos/edit/${data.EquipamentoEvento.id}.json`,
          data
        )
        .subscribe((response: any) => {
          this.getPendentes(data.EquipamentoEvento.tp_alarme).then((res) => {
            if (data.EquipamentoEvento.tp_alarme == "alerta") {
              this.onAlertaPendenteListChanged.next(res);
            } else if (data.EquipamentoEvento.tp_alarme == "panico") {
              this.onPanicoPendenteListChanged.next(res);
            }
            resolve(res);
          }, reject);
        }, reject);
    });
  }

  updatePaging(paging: any) {
    this.paging = { ...this.paging, ...paging };
    this.getDataTableRows();
  }
  updateFilter(filter: any) {
    this.filter = { ...this.filter, ...filter };
    this.paging.offset = 0;
    this.getDataTableRows();
  }
}
