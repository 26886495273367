<div class="content-wrapper container-xxl p-0 h-100 d-inline-block w-100">
  <div class="content-body h-100 d-inline-block w-100">
    <!-- EquipamentoEvento List -->
    <section class="equipamento-evento-list-wrapper h-100 d-inline-block w-100">
      <div class="card mb-0 h-100 d-inline-block w-100">
        <div class="card-header">
          <h4 class="card-title">Eventos</h4>
          <div class="col-12 col-md-4">
          <select
            class="form-control"
            [(ngModel)]="grupo_leitor_id"
            name="grupo_leitor_id"
            id="grupo_leitor_id"
            (change)="filterUpdate($event)"
          >
            <option value="">Todos</option>
            <option
              *ngFor="let grupo of grupoLeitores"
              [value]="grupo.GrupoLeitor.id"
            >
              {{ grupo.GrupoLeitor.nm_grupo_leitor }}
            </option>
          </select>
        </div>
          <div ngbDropdown>
            <button
              class="btn-icon btn btn-transparent hide-arrow btn-sm"
              type="button"
              aria-haspopup="true"
              aria-expanded="false"
              ngbDropdownToggle
            >
              <i
                data-feather="more-vertical"
                id="brtn-options"
                class="font-medium-3"
              ></i>
            </button>
            <div
              ngbDropdownMenu
              class="dropdown-menu-right"
              aria-labelledby="brtn-options"
            >
              <a ngbDropdownItem (click)="download()">Download CSV</a>
            </div>
          </div>
        </div>
        <!-- EquipamentoEvento List Header -->
        <!-- <div class="row">
            <div class="col-md-6 col-12">
              <div class="d-flex justify-content-between align-items-center m-1">
                <div class="d-flex align-items-center">
                  <label class="d-flex align-items-center"
                    >Exibir
                    <select
                      class="form-control mx-25"
                      [(ngModel)]="page.limit"
                      (change)="pageCallback({ offset: 0 })"
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-4 col-12 d-flex justify-content-start justify-content-md-end align-items-center offset-lg-2"
            >
              <div class="row pr-1 pb-1 pb-md-0 align-items-center w-100">
                <div class="col-10 col-md-6 pr-0">
                  <label class="d-flex align-items-center mb-0 ml-1 ml-md-0"
                    ><input
                      [(ngModel)]="searchValue"
                      name="searchValue"
                      type="search"
                      class="form-control"
                      placeholder="Pesquisar..."
                      (keyup)="filterUpdate($event)"
                      (search)="filterUpdate($event)"
                  /></label>
                </div>
              </div>
            </div>
          </div> -->
        <!--/ EquipamentoEvento List Header -->

        <!-- EquipamentoEvento List Table -->
        <ngx-datatable
          [rows]="rows"
          [rowHeight]="50"
          class="bootstrap core-bootstrap mb-0"
          [limit]="selectedOption"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          [scrollbarH]="true"
          [externalPaging]="true"
          [externalSorting]="true"
          [count]="page.count"
          [offset]="page.offset"
          [limit]="page.limit"
          [sortType]="'single'"
          (page)="pageCallback($event)"
          (sort)="sortCallback($event)"
        >
          <ngx-datatable-column
            name="Data"
            prop="EquipamentoEvento.created"
            [width]="100"
            [sortable]="false"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <div class="cell-line-height">
                <span class="d-block text-nowrap">{{
                  value | date : "dd/MM/yy HH:mm"
                }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="Nome"
            prop="Pessoa.nm_pessoa"
            [width]="300"
            [sortable]="false"
          >
            <ng-template
              let-row="row"
              let-name="value"
              ngx-datatable-cell-template
            >
              <div
                class="d-flex align-items-center"
                *ngIf="row.Pessoa.nm_pessoa"
              >
                <div *ngIf="row.Pessoa.dc_picture_url; else customAvatar">
                  <img
                    class="rounded-circle mr-1"
                    src="{{ row.Pessoa.dc_picture_url }}"
                    height="32"
                    width="32"
                    alt="datatable-avatar"
                  />
                </div>
                <ng-template #customAvatar>
                  <div class="avatar mr-1 ml-0">
                    <div class="avatar-content">{{ name | initials }}</div>
                  </div>
                </ng-template>
                <div class="cell-line-height">
                  <span class="d-block text-nowrap font-medium-1">{{
                    name | shortname
                  }}</span>
                  <span class="text-primary font-small-2">
                    {{ row.PessoaPerfil.nm_perfil }} /
                    {{ row.Unidade.nm_display }}</span
                  >
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- <ngx-datatable-column
            name="Unidade"
            prop="Unidade.nm_display"
            [sortable]="false"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <div class="cell-line-height">
                <span class="d-block text-nowrap">{{ value }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column> -->
          <!-- <ngx-datatable-column
            name="Veículo"
            prop="Veiculo.nm_veiculo"
            [sortable]="false"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <div class="cell-line-height">
                <span class="d-block text-nowrap">{{ value }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column> -->
          <!-- <ngx-datatable-column
            name="Leitor"
            prop="Leitor.nm_leitor"
            [sortable]="false"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <div class="cell-line-height">
                <span class="d-block text-nowrap">{{ value }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column> -->
          <ngx-datatable-column
            name="Evento"
            prop="EquipamentoEvento.dv_tp_evento"
            [sortable]="false"
            [width]="200"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <div
                class="badge badge-pill"
                title="{{ row.EquipamentoEvento.tp_evento }}"
                [ngClass]="{
                  'badge-light-primary':
                    row.EquipamentoEvento.tp_evento == 'liberado-sem-passagem',
                  'badge-light-success':
                    row.EquipamentoEvento.tp_evento == 'liberado' ||
                    row.EquipamentoEvento.tp_evento == 'porta-fechada',
                  'badge-light-danger':
                    row.EquipamentoEvento.tp_evento == 'nao-identificado' ||
                    row.EquipamentoEvento.tp_evento == 'negado' ||
                    row.EquipamentoEvento.tp_evento == 'dedo-panico' ||
                    row.EquipamentoEvento.tp_evento == 'cartao-panico' ||
                    row.EquipamentoEvento.tp_evento == 'violacao-equipamento' ||
                    (row.EquipamentoEvento.tp_evento == 'porta-aberta' &&
                      row.EquipamentoEvento.dc_evento ==
                        'Porta esquecida aberta') ||
                    row.EquipamentoEvento.tp_evento == 'porta-violada',
                  'badge-light-warning':
                    row.EquipamentoEvento.tp_evento == 'porta-aberta' &&
                    row.EquipamentoEvento.dc_evento != 'Porta esquecida aberta'
                }"
              >
                {{ row.EquipamentoEvento.dc_evento }}
              </div>
              <div>
                <span class="text-muted font-small-2">
                  {{ row.Leitor.nm_leitor }}</span
                >
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Ações" [width]="60" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="d-flex align-items-center col-actions">
                <a
                  class="mr-1"
                  data-toggle="modal"
                  data-target="#view-equipamento-evento-sidebar"
                  (click)="viewData(row.EquipamentoEvento.id)"
                  container="body"
                  placement="top"
                  ngbTooltip="Visualizar Detalhes"
                  ><i size="18" data-feather="eye"></i
                ></a>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
        <!--/ EquipamentoEvento List Table -->
      </div>
    </section>
    <!--/ EquipamentoEvento List -->
  </div>
  <core-sidebar
    class="modal modal-slide-in sidebar-todo-modal fade"
    name="view-equipamento-evento-sidebar"
    overlayClass="modal-backdrop"
    hideOnEsc="true"
  >
    <app-view-equipamento-evento-sidebar></app-view-equipamento-evento-sidebar>
  </core-sidebar>
</div>
