import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WebcamImage } from "ngx-webcam";
import { Observable, Subject } from "rxjs";
import { OcorrenciaService } from "../ocorrencia.service";
import Swal from "sweetalert2";

@Component({
  selector: "add-ocorrencia-card",
  templateUrl: "./add-ocorrencia-card.component.html",
  styleUrls: ["./add-ocorrencia-card.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddOcorrenciaCardComponent implements OnInit {
  /**
   * Constructor
   *
   * @param {FormBuilder} formBuilder
   * @param {NgbModal} modalService
   * @param {OcorrenciaService} ocorrenciaService
   */
  constructor(
    private formBuilder: FormBuilder,
    private ocorrenciaService: OcorrenciaService,
    private modalService: NgbModal
  ) {}
  public OcorrenciaForm: FormGroup;
  public submitted = false;
  public lastOccurrences: any[] = [];
  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  avatarImage = "../../../assets/images/avatars/default-avatar.jpg";
  imageMode = "file";

  occurrence = {
    id: null,
    id_destino: "sindico",
    st_ocorrencia: "em-aberto",
    id_severidade: "leve",
    nm_ocorrencia: "",
    dt_ocorrencia: new Date().toISOString().slice(0, 10),
    dc_ocorrencia: "",
    image: null,
  };

  get VForm() {
    return this.OcorrenciaForm.controls;
  }

  ngOnInit(): void {
    this.ocorrenciaService.getLastOccurrences().subscribe((data: any) => {
      this.lastOccurrences = data;
    });
    this.OcorrenciaForm = this.formBuilder.group({
      nm_ocorrencia: [this.occurrence.nm_ocorrencia, Validators.required],
      dt_ocorrencia: [this.occurrence.dt_ocorrencia, Validators.required],
      dc_ocorrencia: [this.occurrence.dc_ocorrencia, Validators.required],
    });
  }
  modalOpenForm(modalForm) {
    this.modalService.open(modalForm, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      //scrollable: true,
      size: "lg",
    });
  }

  public getSnapshot(): void {
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.occurrence.image = webcamImage!.imageAsDataUrl;
    this.avatarImage = this.occurrence.image;
    this.imageMode = "file";
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }
  uploadImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (event: any) => {
        this.occurrence.image = event.target.result;
        this.avatarImage = this.occurrence.image;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }
  OcorrenciaFormOnSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.OcorrenciaForm.invalid) {
      return;
    }

    this.ocorrenciaService.addOccurrence(this.occurrence).then(
      (data: any) => {
        this.modalService.dismissAll();
        this.OcorrenciaForm.reset();
        this.occurrence.image = null;
        this.occurrence.id_destino = "sindico";
        this.occurrence.st_ocorrencia = "em-aberto";
        this.occurrence.id_severidade = "leve";
        this.submitted = false;
        this.avatarImage = "../../../assets/images/avatars/default-avatar.jpg";
        this.imageMode = "file";

        this.ocorrenciaService.getLastOccurrences().subscribe((data: any) => {
          this.lastOccurrences = data;
        });
        Swal.fire({
          icon: "success",
          title: "Ocorrência registrada com sucesso",
          showConfirmButton: false,
          timer: 1500,
        });
      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: "Não foi possível registrar ocorrência",
          html: (error.text || error) + "",
        });
      }
    );
  }
}
