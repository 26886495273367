import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ViewEncapsulation,
  } from "@angular/core";
  
  import { Observable, Subject } from "rxjs";
  import { takeUntil } from "rxjs/operators";
  import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
  
  import { CoreConfigService } from "@core/services/config.service";
  
  import { EquipamentoEventoService } from "app/main/apps/equipamento-evento/equipamento-evento.service";
  import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
  import { WebcamImage } from "ngx-webcam";
  import { FormBuilder, FormGroup, Validators } from "@angular/forms";
  import Swal from "sweetalert2";
  import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
  
  @Component({
    selector: "app-equipamento-evento-list",
    templateUrl: "./equipamento-evento-list.component.html",
    styleUrls: ["./equipamento-evento-list.component.scss"],
    encapsulation: ViewEncapsulation.None,
  })
  export class EquipamentoEventoListComponent implements OnInit, OnDestroy {
    // public
    public data: any;
    public selectedOption = 10;
    public ColumnMode = ColumnMode
    public searchValue = "";
    public page:any;
    public grupoLeitores: any[] = [];
  
    // decorator
    @ViewChild(DatatableComponent) table: DatatableComponent;
  
    // private
    private tempData = [];
    private _unsubscribeAll: Subject<any>;
    public rows = [];
    public tempFilterData;
    public previousStatusFilter = "";
    public grupo_leitor_id = "";
  
    /**
     * Constructor
     *
     * @param {CoreConfigService} _coreConfigService
     * @param {CalendarService} _calendarService
     * @param {EquipamentoEventoService} _equipamentoEventoService
     */
    constructor(
      private formBuilder: FormBuilder,
      private _equipamentoEventoService: EquipamentoEventoService,
      private _coreConfigService: CoreConfigService,
      private _coreSidebarService: CoreSidebarService,
      private modalService: NgbModal
    ) {
      this.page = _equipamentoEventoService.paging;
      this._unsubscribeAll = new Subject();
    }
  
    /**
     * filterUpdate
     *
     * @param event
     */
    filterUpdate(event) {
      this._equipamentoEventoService.updateFilter({ /*search: event.target.value,*/ grupo_leitor_id: this.grupo_leitor_id });
    }
  
    
  
   /**
     * Called whenever the user changes page
     *
     * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
     */
   pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this._equipamentoEventoService.updatePaging(this.page);
  }
  
  /**
   * Called whenever the user changes the sort order
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    // there will always be one "sort" object if "sortType" is set to "single"
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this._equipamentoEventoService.updatePaging(this.page);
  }
    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit(): void {
      // Subscribe config change
      this._equipamentoEventoService.getGrupoLeitores().subscribe((response) => {
        this.grupoLeitores = response;
      });
      this._coreConfigService.config
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((config) => {
          // If we have zoomIn route Transition then load datatable after 450ms(Transition will finish in 400ms)
          if (config.layout.animation === "zoomIn") {
            setTimeout(() => {
              this._equipamentoEventoService.onEquipamentoEventoListChanged
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((response) => {
                  this.data = response;
                  this.rows = this.data;
                  this.tempData = this.rows;
                  this.tempFilterData = this.rows;
                  this.page = this._equipamentoEventoService.paging;
                });
            }, 450);
          } else {
            this._equipamentoEventoService.onEquipamentoEventoListChanged
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((response) => {
                this.data = response;
                this.rows = this.data;
                this.tempData = this.rows;
                this.tempFilterData = this.rows;
                this.page = this._equipamentoEventoService.paging;
              });
          }
        });
  
    }
  
    /**
     * On destroy
     */
    ngOnDestroy(): void {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
    }

    viewData(id) {
      this._coreSidebarService
        .getSidebarRegistry("view-equipamento-evento-sidebar")
        .toggleOpen();
      this._equipamentoEventoService.getById(id);
    }
    download() {
      this._equipamentoEventoService.download().subscribe(
        (response: any) => {
          console.log(response);
          //const blob = new Blob([response], { type: "application/octet-stream" });
          const url = window.URL.createObjectURL(response.body);
          const link = document.createElement("a");
          link.href = url;
          link.download = "eventos.csv";
          link.click();
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.log(error);
        }
      );
    }
   
  }
  