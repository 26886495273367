import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddAutorizadoCardComponent } from "../add-autorizado-card/add-autorizado-card.component";
import { AutorizadoService } from "../autorizado.service";

@Component({
  selector: "autorizado-card",
  templateUrl: "./autorizado-card.component.html",
  styleUrls: ["./autorizado-card.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AutorizadoCardComponent implements OnInit {
  constructor(private modalService: NgbModal, private autorizadoService: AutorizadoService) {}
  ngOnInit(): void {}
  openDialog() {
    this.autorizadoService.getDataTableRows();
    const modalRef = this.modalService.open(AddAutorizadoCardComponent, {
        backdrop: "static",
        keyboard: false,
        centered: true,
        // scrollable: true,
        size: "xl",
    });
        
  }
}
