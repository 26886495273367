<div class="slideout-content">
  <!-- Modal to Add Customer starts-->
  <div class="modal-slide-in new-user-modal" id="modals-slide-in">
    <div class="modal-dialog">
      <div class="modal-content p-0">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="toggleSidebar('invoide-add-sidebar')"
        >
          ×
        </button>
        <div class="modal-header mb-1">
          <h5 class="modal-title">
            <span class="align-middle">Add Customer</span>
          </h5>
        </div>
        <div class="modal-body flex-grow-1">
          <form>
            <div class="form-group">
              <label for="customer-name" class="form-label">Customer Name</label>
              <input type="text" class="form-control" id="customer-name" placeholder="John Doe" />
            </div>
            <div class="form-group">
              <label for="customer-email" class="form-label">Email</label>
              <input type="email" class="form-control" id="customer-email" placeholder="example@domain.com" />
            </div>
            <div class="form-group">
              <label for="customer-address" class="form-label">Customer Address</label>
              <textarea
                class="form-control"
                id="customer-address"
                cols="2"
                rows="2"
                placeholder="1307 Lady Bug Drive New York"
              ></textarea>
            </div>
            <div class="form-group position-relative">
              <label for="customer-country" class="form-label">Country</label>
              <select class="form-control" id="customer-country" name="customer-country">
                <option label="select country"></option>
                <option value="Australia">Australia</option>
                <option value="Canada">Canada</option>
                <option value="Russia">Russia</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Singapore">Singapore</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United Arab Emirates">United Arab Emirates</option>
                <option value="United States of America">United States of America</option>
              </select>
            </div>
            <div class="form-group">
              <label for="customer-contact" class="form-label">Contact</label>
              <input type="number" class="form-control" id="customer-contact" placeholder="763-242-9206" />
            </div>
            <div class="form-group d-flex flex-wrap mt-2">
              <button
                type="button"
                class="btn btn-primary mr-1"
                (click)="toggleSidebar('invoide-add-sidebar')"
                rippleEffect
              >
                Add
              </button>
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-dismiss="modal"
                (click)="toggleSidebar('invoide-add-sidebar')"
                rippleEffect
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal to Add Customer Ends-->
</div>
