<div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Registrar Ocorrência</h4>
    <button
      type="button"
      class="close"
      (click)="activeModal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form
      [formGroup]="OcorrenciaForm"
      (ngSubmit)="OcorrenciaFormOnSubmit()"
      #formOcorrencia
    >
      <h6 class="mb-2">1. Dados da Ocorrência</h6>
      <div class="row">
        <div class="col-md-8 col-12">
          <div class="row">
            <div class="col-md-8 col-12">
              <label>Assunto: </label>
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Assunto"
                  class="form-control"
                  name="nm_ocorrencia"
                  [(ngModel)]="occurrence.nm_ocorrencia"
                  formControlName="nm_ocorrencia"
                  [ngClass]="{
                    'is-invalid': submitted && VForm.nm_ocorrencia.errors
                  }"
                />
                <div
                  *ngIf="submitted && VForm.nm_ocorrencia.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="VForm.nm_ocorrencia.errors.required">
                    Assunto é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <label>Data: </label>
              <div class="form-group">
                <input
                  type="date"
                  placeholder="CPF"
                  class="form-control"
                  name="dt_ocorrencia"
                  [(ngModel)]="occurrence.dt_ocorrencia"
                  formControlName="dt_ocorrencia"
                  [ngClass]="{
                    'is-invalid': submitted && VForm.dt_ocorrencia.errors
                  }"
                />
                <div
                  *ngIf="submitted && VForm.dt_ocorrencia.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="VForm.dt_ocorrencia.errors.required">
                    Data é obrigatória
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label>Ocorrência: </label>
              <div class="form-group">
                <textarea
                  class="form-control"
                  name="dc_ocorrencia"
                  [(ngModel)]="occurrence.dc_ocorrencia"
                  rows="3"
                  formControlName="dc_ocorrencia"
                  [ngClass]="{
                    'is-invalid': submitted && VForm.dc_ocorrencia.errors
                  }"
                ></textarea>
                <div
                  *ngIf="submitted && VForm.dc_ocorrencia.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="VForm.dc_ocorrencia.errors.required">
                    Descrição da ocorrência é obrigatória
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <!-- header media -->
          <div *ngIf="imageMode == 'camera'">
            <div class="row">
              <div class="col-md-12 text-center">
                <div class="rounded mb-75 object-fit-cover">
                  <webcam
                    [trigger]="invokeObservable"
                    (imageCapture)="captureImg($event)"
                    [width]="200"
                    [height]="200"
                  ></webcam>
                </div>
              </div>
              <div class="col-md-12 text-center">
                <button
                  type="button"
                  class="btn btn-sm btn-primary mb-75 mr-75"
                  (click)="getSnapshot()"
                >
                  Capturar
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-outline-secondary mb-75"
                  rippleEffect
                  (click)="imageMode = 'file'"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="imageMode == 'file'">
            <div class="row">
              <div class="col-12 text-center">
                <a href="javascript:void(0);">
                  <img
                    [src]="avatarImage"
                    class="rounded mb-75 object-fit-cover shadow"
                    alt=""
                    height="200"
                    width="200"
                    (click)="file.click()"
                  />
                </a>
              </div>
              <div class="col-12 text-center">
                <button
                  type="button"
                  (click)="file.click()"
                  for="account-upload"
                  class="btn btn-sm btn-primary mb-75 mr-75"
                  rippleEffect
                >
                  Upload
                </button>
                <input
                  type="file"
                  #file
                  id="account-upload"
                  hidden
                  accept="image/*"
                  (change)="uploadImage($event)"
                />
                <button
                  type="button"
                  class="btn btn-sm btn-outline-secondary mb-75"
                  rippleEffect
                  (click)="imageMode = 'camera'"
                >
                  Câmera
                </button>
              </div>
            </div>
          </div>
          <!--/ header media -->
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="OcorrenciaFormOnSubmit()"
      rippleEffect
    >
      Registrar Ocorrência
    </button>
  </div>