<div class="slideout-content">
  <!-- Modal to Add Customer starts-->
  <div class="modal-slide-in new-user-modal" id="modals-slide-in">
    <div class="modal-dialog">
      <div class="modal-content p-0">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="toggleSidebar('edit-encomenda-sidebar')"
        >
          ×
        </button>
        <div class="modal-header mb-1">
          <h5 class="modal-title">
            <span class="align-middle">Encomenda</span>
          </h5>
        </div>
        <div class="modal-body flex-grow-1" *ngIf="encomenda?.Encomenda">
          <form
            [formGroup]="EncomendaForm"
            (ngSubmit)="EncomendaFormOnSubmit()"
            #formEncomenda
          >
            <h4>Dados da Encomenda</h4>
            <div
              class="d-flex justify-content-between align-items-center flex-column"
            >
              <div class="encomenda-image-wrapper">
                <div class="encomenda-image">
                  <img
                    src="{{
                      encomenda.Encomenda.dc_picture_url ||
                        'assets/images/default_camera.png'
                    }}"
                    alt="Profile Picture"
                    class="thumbnail-image"
                  />
                </div>
              </div>
              <h3>{{ encomenda.TipoEncomenda.nm_tipo }}</h3>
              <h6 class="text-muted">{{ encomenda.Encomenda.cd_encomenda }}</h6>
              <h6 *ngIf="encomenda.Encomenda.dc_email" class="text-muted">
                {{ encomenda.Encomenda.dc_email }}
              </h6>

              <div
                class="badge profile-badge"
                *ngIf="encomenda.Encomenda.dv_st_encomenda"
                [ngClass]="{
                  'badge-light-warning':
                    encomenda.Encomenda.st_encomenda == 'devolvida',
                  'badge-light-success':
                    encomenda.Encomenda.st_encomenda == 'retirada',
                  'badge-light-danger':
                    encomenda.Encomenda.st_encomenda == 'pendente'
                }"
              >
                {{ encomenda.Encomenda.dv_st_encomenda }}
              </div>
              <dl class="w-100">
                <dt>Remetente</dt>
                <dd>{{ encomenda.Encomenda.nm_remetente }}</dd>
                <dt>Destinatário</dt>
                <dd>{{ encomenda.Encomenda.nm_destinatario }}</dd>
                <dt>Unidade</dt>
                <dd>{{ encomenda.Unidade.nm_display }}</dd>
                <dt>Observações</dt>
                <dd>{{ encomenda.Encomenda.dc_observacao }}</dd>
              </dl>
            </div>
            <hr class="mb-1" />
            <ng-container *ngIf="encomenda.Encomenda.usuario_recebimento_id">
              <h6>Recebido por</h6>
              <div class="media">
                <div class="avatar mr-75">
                  <img
                    src="{{
                      encomenda.UsuarioRecebimento.dc_picture_url ||
                        'assets/images/avatars/default.jpg'
                    }}"
                    class="rounded"
                    width="42"
                    height="42"
                    alt="Avatar"
                  />
                </div>
                <div class="media-body my-auto">
                  <h6 class="mb-0">
                    {{ encomenda.UsuarioRecebimento.nm_usuario }}
                  </h6>
                  <small>{{ encomenda.Encomenda.dt_recebimento }}</small>
                </div>
              </div>
            </ng-container>

            <div class="row mt-2">
              <div class="col-12">
                <label>Retirado por: </label>
                <div class="form-group">
                  <ng-select
                    [items]="moradores"
                    [clearable]="false"
                    bindLabel="Usuario.nm_usuario"
                    bindValue="Usuario.id"
                    name="usuario_retirada_id"
                    [(ngModel)]="encomenda.usuario_retirada_id"
                    formControlName="usuario_retirada_id"
                    [ngClass]="{
                      'is-invalid':
                        submitted && VForm.usuario_retirada_id.errors
                    }"
                  >
                  </ng-select>
                  <div
                    *ngIf="submitted && VForm.usuario_retirada_id.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="VForm.usuario_retirada_id.errors.required">
                      Usuário é obrigatório
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group d-flex flex-wrap mt-2 ">
              <button
                [disabled]="!encomenda || !EncomendaForm.valid"
                type="button"
                class="btn btn-primary mr-1"
                (click)="EncomendaFormOnSubmit()"
                rippleEffect
              >
                Confirmar Entrega
              </button>
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-dismiss="modal"
                (click)="toggleSidebar('edit-encomenda-sidebar')"
                rippleEffect
              >
                Fechar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal to Add Customer Ends-->
</div>
