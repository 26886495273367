import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortname'
})
export class ShortnamePipe implements PipeTransform {
  transform(fullName: string): any {
    return fullName
      ?.split(' ')
      .map((n,i) => i === 0 || i === fullName?.split(' ').length -1 ? n : n[0].toUpperCase() +'.')
      .join(' ');
  }
}
