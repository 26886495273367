import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

// import { SampleComponent } from './sample.component';
import { HomeComponent } from './home.component';
import { AuthGuard } from 'app/auth/helpers';
import { EncomendaModule } from '../apps/encomenda/encomenda.module';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';

import { VisitanteModule } from '../apps/visitante/visitante.module';
import { OcorrenciaModule } from '../apps/ocorrencia/ocorrencia.module';
import { ReservaModule } from '../apps/reserva/reserva.module';
import { EncomendaListService } from '../apps/encomenda/encomenda-list/encomenda-list.service';
import { ReservaService } from '../apps/reserva/reserva.service';
import { AutorizadoService } from '../apps/autorizado/autorizado.service';
import { AutorizadoModule } from '../apps/autorizado/autorizado.module';
// import { DashboardComponent } from './dashboard.component';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EquipamentoEventoService } from '../apps/equipamento-evento/equipamento-evento.service';
import { EquipamentoEventoModule } from '../apps/equipamento-evento/equipamento-evento.module';
import { ChaveService } from '../apps/chaves/chave.service';
import { ChavesModule } from '../apps/chaves/chaves.module';

const routes = [
  // {
  //   path: 'dashboard',
  //   component: DashboardComponent,
  //   resolve: {
  //     uls2: ReservaService
  //   },
  //   data: { animation: 'home' },
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'home',
    component: HomeComponent,
    resolve: {
      uls: EncomendaListService,
      uls2: ReservaService,
      uls3: AutorizadoService,
      uls4: EquipamentoEventoService,
      uls5: ChaveService

    },
    data: { animation: 'home' },
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [HomeComponent],
  imports: [
    RouterModule.forChild(routes),
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    EncomendaModule,
    EquipamentoEventoModule,
    VisitanteModule,
    OcorrenciaModule,
    ReservaModule,
    AutorizadoModule,
    ChavesModule,
    AutorizadoModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [EncomendaListService, ReservaService, EquipamentoEventoService],
  exports: [ HomeComponent],
})
export class SampleModule {}
