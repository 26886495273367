import { Component, OnDestroy, OnInit } from "@angular/core";

import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { EquipamentoEventoService } from "../equipamento-evento.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-view-equipamento-evento-sidebar",
  styleUrls: ["./view-equipamento-evento-sidebar.component.scss"],
  templateUrl: "./view-equipamento-evento-sidebar.component.html",
})
export class ViewEquipamentoEventoSidebarComponent implements OnInit, OnDestroy {
  public evento: any;
  
  private _unsubscribeAll: Subject<any>;
  config: any = {
    alerta: {
      title: "Avisos",
      icon: "alert-triangle",
      color: "warning",
    },
    panico: {
      title: "Pânico",
      icon: "x-circle",
      color: "danger",
    },
  };

  /**
   * Constructor
   *
   * @param {CoreSidebarService} _coreSidebarService
   */
  constructor(
    private _coreSidebarService: CoreSidebarService,
    private _equipamentoEvento: EquipamentoEventoService
  ) {
    this._unsubscribeAll = new Subject();
  }

  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name): void {
    this.evento = null;
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  ngOnInit(): void {
    this._equipamentoEvento.onCurrentEquipamentoEventoChange
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(
      (evento) => {
        this.evento = evento;
        console.log(evento);
      }
    );
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
