<div class="slideout-content">
  <!-- Modal To Add New User Starts-->
  <div class="modalsd modal-slide-in sdfade add-payment-modal" id="modals-slide-in">
    <div class="modal-dialog">
      <div class="modal-content p-0">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="toggleSidebar('add-payment-sidebar')"
        >
          ×
        </button>
        <div class="modal-header mb-1">
          <h5 class="modal-title">
            <span class="align-middle">Add Payment</span>
          </h5>
        </div>
        <div class="modal-body flex-grow-1">
          <form>
            <div class="form-group">
              <input id="balance" class="form-control" type="text" value="Encomenda Balance: 5000.00" disabled />
            </div>
            <div class="form-group">
              <label class="form-label" for="amount">Payment Amount</label>
              <input id="amount" class="form-control" type="number" placeholder="$1000" />
            </div>
            <div class="form-group">
              <label class="form-label" for="payment-date">Payment Date</label>
              <ng2-flatpickr [config]="paymentDateOptions" name="Date"></ng2-flatpickr>
            </div>
            <div class="form-group">
              <label class="form-label" for="payment-method">Payment Method</label>
              <select class="form-control" id="payment-method">
                <option value="" selected disabled>Select payment method</option>
                <option value="Cash">Cash</option>
                <option value="Bank Transfer">Bank Transfer</option>
                <option value="Debit">Debit</option>
                <option value="Credit">Credit</option>
                <option value="Paypal">Paypal</option>
              </select>
            </div>
            <div class="form-group">
              <label class="form-label" for="payment-note">Internal Payment Note</label>
              <textarea class="form-control" id="payment-note" rows="5" placeholder="Internal Payment Note"></textarea>
            </div>
            <div class="form-group d-flex flex-wrap mb-0">
              <button
                type="button"
                class="btn btn-primary mr-1"
                (click)="toggleSidebar('add-payment-sidebar')"
                rippleEffect
              >
                Send
              </button>
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-dismiss="modal"
                (click)="toggleSidebar('add-payment-sidebar')"
                rippleEffect
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal To Add New User Ends-->
</div>
