import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { ShortnamePipe } from './shortname.pipe';
import { MaskEmailPipe } from './maskemail.pipe';
import { MaskDocumentPipe } from './maskdocument.pipe';

@NgModule({
  declarations: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, ShortnamePipe, MaskEmailPipe, MaskDocumentPipe],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, ShortnamePipe, MaskEmailPipe, MaskDocumentPipe]
})
export class CorePipesModule {}
