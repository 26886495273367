import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { CoreCommonModule } from '@core/common.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreSidebarModule } from '@core/components';

import { EncomendaListComponent } from 'app/main/apps/encomenda/encomenda-list/encomenda-list.component';
import { EncomendaListService } from 'app/main/apps/encomenda/encomenda-list/encomenda-list.service';

import { EncomendaAddComponent } from 'app/main/apps/encomenda/encomenda-add/encomenda-add.component';
import { EncomendaAddService } from 'app/main/apps/encomenda/encomenda-add/encomenda-add.service';
import { AddCustomerSidebarComponent } from 'app/main/apps/encomenda/encomenda-add/add-customer-sidebar/add-customer-sidebar.component';

import { EncomendaEditComponent } from 'app/main/apps/encomenda/encomenda-edit/encomenda-edit.component';
import { EncomendaEditService } from 'app/main/apps/encomenda/encomenda-edit/encomenda-edit.service';
import { SendEncomendaSidebarComponent } from 'app/main/apps/encomenda/encomenda-edit/sidebar/send-encomenda-sidebar/send-encomenda-sidebar.component';
import { AddPaymentSidebarComponent } from 'app/main/apps/encomenda/encomenda-edit/sidebar/add-payment-sidebar/add-payment-sidebar.component';

import { EncomendaPreviewComponent } from 'app/main/apps/encomenda/encomenda-preview/encomenda-preview.component';
import { EncomendaPreviewService } from 'app/main/apps/encomenda/encomenda-preview/encomenda-preview.service';
import { AddPaymentSidebarPreviewComponent } from 'app/main/apps/encomenda/encomenda-preview/sidebar/add-payment-sidebar-preview/add-payment-sidebar-preview.component';
import { SendEncomendaSidebarPreviewComponent } from 'app/main/apps/encomenda/encomenda-preview/sidebar/send-encomenda-sidebar-preview/send-encomenda-sidebar-preview.component';
import { WebcamModule } from 'ngx-webcam';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { EncomendaDeliverComponent } from './encomenda-deliver/encomenda-deliver.component';
import { EncomendaDeliverService } from './encomenda-deliver/encomenda-deliver.service';
import { ViewEncomendaSidebarComponent } from './view-encomenda-sidebar/view-encomenda-sidebar.component';
import { EditEncomendaSidebarComponent } from './edit-encomenda-sidebar/edit-encomenda-sidebar.component';
import { EncomendaCardComponent } from './encomenda-card/encomenda-card.component';

// routing
const routes: Routes = [
  {
    path: 'add',
    component: EncomendaAddComponent,
    resolve: {
      Ias: EncomendaAddService
    },
    data: { animation: 'EncomendaAddComponent' }
  },
  {
    path: 'list',
    component: EncomendaListComponent,
    resolve: {
      uls: EncomendaListService
    },
    data: { animation: 'EncomendaListComponent' }
  },
  {
    path: 'preview/:id',
    component: EncomendaPreviewComponent,
    resolve: {
      data: EncomendaPreviewService
    },
    data: { path: 'user-view/:id', animation: 'EncomendaPreviewComponent' }
  },
  {
    path: 'edit/:id',
    component: EncomendaEditComponent,
    resolve: {
      data: EncomendaEditService
    },
    data: { path: 'user-view/:id', animation: 'EncomendaEditComponent' }
  },
  {
    path: 'preview',
    redirectTo: '/apps/encomenda/preview/4989' // Redirection
  },
  {
    path: 'edit',
    redirectTo: '/apps/encomenda/edit/4989' // Redirection
  }
];

@NgModule({
  declarations: [
    EncomendaAddComponent,
    EncomendaListComponent,
    EncomendaPreviewComponent,
    EncomendaEditComponent,
    AddCustomerSidebarComponent,
    SendEncomendaSidebarComponent,
    AddPaymentSidebarComponent,
    SendEncomendaSidebarPreviewComponent,
    AddPaymentSidebarPreviewComponent,
    EncomendaDeliverComponent,
    ViewEncomendaSidebarComponent,
    EditEncomendaSidebarComponent,
    EncomendaCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    CoreDirectivesModule,
    Ng2FlatpickrModule,
    NgxDatatableModule,
    FormsModule,
    CorePipesModule,
    NgbModule,
    NgSelectModule,
    CoreSidebarModule,
    WebcamModule,
    ZXingScannerModule

  ],
  providers: [EncomendaListService, EncomendaPreviewService, EncomendaEditService, EncomendaAddService, EncomendaDeliverService],
  exports: [EncomendaListComponent,EncomendaCardComponent]
})
export class EncomendaModule {} 
