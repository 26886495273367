import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { CoreConfigService } from "@core/services/config.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AutorizadoService } from "../autorizado.service";
import { AddVisitanteAutorizadoComponent } from "../../visitante/add-visitante-autorizado/add-visitante-autorizado.component";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";

@Component({
  selector: "add-autorizado-card",
  templateUrl: "./add-autorizado-card.component.html",
  styleUrls: ["./add-autorizado-card.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddAutorizadoCardComponent implements OnInit, OnDestroy {
  public data: any;
  public selectedOption = 10;
  public ColumnMode = ColumnMode;
  public searchValue = "";
  public page: any;

  
  // decorator
  @ViewChild(DatatableComponent) table: DatatableComponent;

  // private
  private tempData = [];
  private _unsubscribeAll: Subject<any>;
  public rows = [];
  public tempFilterData;
  
  constructor(
    private _coreConfigService: CoreConfigService,
    private _autorizadoService: AutorizadoService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal
  ) {
    this.page = this._autorizadoService.paging;
    this._unsubscribeAll = new Subject();
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  ngOnInit(): void {
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        // If we have zoomIn route Transition then load datatable after 450ms(Transition will finish in 400ms)
        if (config.layout.animation === "zoomIn") {
          setTimeout(() => {
            this._autorizadoService.onAuthorizedListChanged
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((response) => {
                this.data = response;
                this.rows = this.data;
                this.tempData = this.rows;
                this.tempFilterData = this.rows;
                this.page = this._autorizadoService.paging;
              });
          }, 450);
        } else {
          this._autorizadoService.onAuthorizedListChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response) => {
              this.data = response;
                this.rows = this.data;
                this.tempData = this.rows;
                this.tempFilterData = this.rows;
                this.page = this._autorizadoService.paging;
            });
        }
      });
  }

  /**
   * filterUpdate
   *
   * @param event
   */
  filterUpdate(event) {
    this._autorizadoService.updateFilter({ search: event.target.value });
  }

  /**
   * Called whenever the user changes page
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  pageCallback(pageInfo: {
    count?: number;
    pageSize?: number;
    limit?: number;
    offset?: number;
  }) {
    this.page.offset = pageInfo.offset;
    this._autorizadoService.updatePaging(this.page);
  }

  /**
   * Called whenever the user changes the sort order
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  sortCallback(sortInfo: {
    sorts: { dir: string; prop: string }[];
    column: {};
    prevValue: string;
    newValue: string;
  }) {
    // there will always be one "sort" object if "sortType" is set to "single"
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this._autorizadoService.updatePaging(this.page);
  }

  

  modalOpenFormAddAutorizado(autorizado) {
    let modalRef = this.modalService.open(AddVisitanteAutorizadoComponent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      // scrollable: true,
      size: "lg",
    

    });
    modalRef.componentInstance.autorizado = autorizado;
  }
  close(){
    this.activeModal.close();
  }
}
