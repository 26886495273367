import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { CoreConfigService } from "@core/services/config.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AddVisitanteReservaComponent } from "../../visitante/add-visitante-reserva/add-visitante-reserva.component";
import { ReservaService } from "../reserva.service";
import { ReservaGuestsComponent } from "../reserva-guests/reserva-guests.component";

@Component({
  selector: "reserva-card",
  templateUrl: "./reserva-card.component.html",
  styleUrls: ["./reserva-card.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ReservaCardComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  public data: any;
  public rows = [];
  public today = new Date().toLocaleString('pt-BR', {
    timeZone: 'America/Sao_Paulo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).split('/').reverse().join('-');
  
  // public today = new Date().toISOString().split("T")[0];
  constructor(
    private _coreConfigService: CoreConfigService,
    private _reservaService: ReservaService,
    private modalService: NgbModal
  ) {
    this._unsubscribeAll = new Subject();
    console.log(this.today);
  }
/**
   * On destroy
   */
ngOnDestroy(): void {
  // Unsubscribe from all subscriptions
  this._unsubscribeAll.next();
  this._unsubscribeAll.complete();
}
  ngOnInit(): void {
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        // If we have zoomIn route Transition then load datatable after 450ms(Transition will finish in 400ms)
        if (config.layout.animation === "zoomIn") {
          setTimeout(() => {
            this._reservaService.onNextReservaListChanged
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((response) => {
                this.data = response;
                this.rows = this.data;
              });
          }, 450);
        } else {
          this._reservaService.onNextReservaListChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response) => {
              this.data = response;
              this.rows = this.data;
            });
        }
      });
  }
  
  modalOpenReservaGuests(reserva){
    let modalRef = this.modalService.open(ReservaGuestsComponent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      // scrollable: true,
      size: "lg",
    

    });
    
    modalRef.componentInstance.reserva = reserva;
  }
  modalOpenFormAddConvidado(reserva) {
    let modalRef = this.modalService.open(AddVisitanteReservaComponent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      // scrollable: true,
      size: "lg",
    

    });
    modalRef.result.then((data) => {
      console.log(data  );
      // on close
      this._reservaService.getNextReservas();
    }, (reason) => {
      console.log(reason);
      // on dismiss
      this._reservaService.getNextReservas();
    });
    modalRef.componentInstance.reserva = reserva;
  }
}
