<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel1">Nova Retirada de Item</h4>
  <button
    type="button"
    class="close"
    (click)="activeModal.dismiss('Cross click')"
    aria-label="Close"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <form
    [formGroup]="ControleAreaItemAddForm"
    (ngSubmit)="ControleAreaItemAddFormOnSubmit()"
    *blockUI="'form-section'"
    class="form-block"
    #formControleAreaItemAdd="ngForm"
  >
    <div class="row">
      <div class="col-6">
        <div class="form-group" *ngIf="unidades">
          <label for="pessoa_id">Unidade</label>
          <ng-select
            
            [items]="unidades"
            [clearable]="true"
            bindLabel="Unidade.nm_display"
            bindValue="Unidade.id"
            name="unidade_id"
            [(ngModel)]="controle.unidade_id"
            [ngModelOptions]="{standalone: true}"
            required
            [class.error]="
              formControleAreaItemAdd.submitted && ControleAreaItemUnidadeIdRef.invalid
            "
            #ControleAreaItemUnidadeIdRef="ngModel"
            (change)="onChangeUnidade($event)"
          >
          </ng-select>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group" *ngIf="pessoas">
          <label for="pessoa_id">Condômino</label>
          <ng-select
            
            [items]="pessoas"
            [clearable]="true"
            bindLabel="Pessoa.nm_pessoa"
            bindValue="Pessoa.id"
            name="pessoa_id"
            [ngModelOptions]="{standalone: true}"
            required
            [(ngModel)]="controle.pessoa_id"
            [class.error]="
              formControleAreaItemAdd.submitted && ControleAreaItemPessoaIdRef.invalid
            "
            #ControleAreaItemPessoaIdRef="ngModel"
          >
          </ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="area_item_id">Item</label>
          <ng-select
            *ngIf="itens"
            [items]="itens"
            [clearable]="true"
            bindLabel="nm_item"
            bindValue="id"
            name="area_item_id"
            [(ngModel)]="controle.area_item_id"
            [ngModelOptions]="{standalone: true}"
            required
            [class.error]="
              formControleAreaItemAdd.submitted && ControleAreaItemAreaItemIdRef.invalid
            "
            #ControleAreaItemAreaItemIdRef="ngModel"
          >
          </ng-select>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
    <button
    type="button"
    class="btn btn-secondary"
    (click)="activeModal.dismiss('Cross click')"
    rippleEffect
  >
    Cancelar
  </button>
  <!-- Button to submit formControleAreaItemAdd -->
  <button
    type="button"
    class="btn btn-primary"
    (click)="ControleAreaItemAddFormOnSubmit()"
    rippleEffect
  >
    <!-- (click)="modal.close('Accept click')" -->
    Confirmar
  </button>
  <!-- Button to close modal -->
  
</div>
