import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";

import { BehaviorSubject, Observable } from "rxjs";

import { environment } from "environments/environment";

@Injectable()
export class ReservaService implements Resolve<any> {
  rows: [] = [];
  convidado_rows: [] = [];
  convidado_paging = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: "Convidado.nm_convidado",
    orderDir: "desc",
  };
  convidado_filter = {
    reserva_id: -1,
    search: "",
  };
  nextReservas: [] = [];
  onConvidadoListChanged: BehaviorSubject<any>;
  onReservaListChanged: BehaviorSubject<any>;
  onNextReservaListChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this.onReservaListChanged = new BehaviorSubject([]);
    this.onConvidadoListChanged = new BehaviorSubject([]);
    this.onNextReservaListChanged = new BehaviorSubject([]);
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows(), this.getNextReservas()]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  /**
   * Get rows
   */
  getDataTableRows(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      console.log(
        "ReservaService.getDataTableRows()",
        `${environment.apiUrl}/reservas.json`
      );
      this._httpClient
        .get(`${environment.apiUrl}/reservas.json`)
        .subscribe((response: any) => {
          this.rows = response;
          this.onReservaListChanged.next(this.rows);
          resolve(this.rows);
        }, reject);
    });
  }
  getConvidadoDataTableRows(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(`${environment.apiUrl}/reservas/convidados.json`,{ConvidadoFilter:this.convidado_filter},{params:this.convidado_paging})
        .subscribe((response: any) => {
          this.convidado_rows = response.data;
          this.convidado_paging.count = response.total;

          this.onConvidadoListChanged.next(this.convidado_rows);
          resolve(this.convidado_rows);
        }, reject);
    });
  }

  /**
   * Get next Reservas
   *
   * */
  getNextReservas(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      console.log(
        "ReservaService.getNextReservas()",
        `${environment.apiUrl}/reservas/index/proximas.json`
      );
      this._httpClient
        .get(`${environment.apiUrl}/reservas/index/proximas.json`)
        .subscribe((response: any) => {
          this.nextReservas = response;
          this.onNextReservaListChanged.next(this.nextReservas);
          resolve(this.nextReservas);
        }, reject);
    });
  }

  updateConvidadoPaging(paging: any) {
    this.convidado_paging = { ...this.convidado_paging, ...paging };
    this.getConvidadoDataTableRows();
  }
  updateConvidadoFilter(filter: any) {
    this.convidado_filter = { ...this.convidado_filter, ...filter };
    this.convidado_paging.offset = 0;
    this.getConvidadoDataTableRows();
  }
}
