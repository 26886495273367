<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel1">
    {{ evento.EquipamentoEvento.dc_evento }} -
    {{ evento.EquipamentoEvento.dv_tp_alarme }}
  </h4>
  <button
    type="button"
    class="close"
    (click)="activeModal.dismiss('Cross click')"
    aria-label="Close"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <form
    [formGroup]="EventoForm"
    (ngSubmit)="EventoFormOnSubmit()"
    #formVisita
    *blockUI="'form-section'"
    class="form-block"
  >
    <div class="row">
      <div class="col-12">
        <label>Motivo: </label>
        <div class="form-group">
          <ng-select
            [items]="motivos"
            [clearable]="false"
            bindLabel="AlarmeMotivo.nm_motivo"
            bindValue="AlarmeMotivo.id"
            name="alarme_motivo_id"
            [(ngModel)]="evento.EquipamentoEvento.alarme_motivo_id"
            formControlName="alarme_motivo_id"
            [ngClass]="{
              'is-invalid': submitted && VForm.alarme_motivo_id.errors
            }"
          >
          </ng-select>
          <div
            *ngIf="submitted && VForm.alarme_motivo_id.errors"
            class="invalid-feedback"
          >
            <div *ngIf="VForm.alarme_motivo_id.errors.required">
              Motivo é obrigatório
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label>Severidade: </label>
        <div class="form-group">
          <ng-select
            [items]="severidades"
            [clearable]="false"
            bindLabel="name"
            bindValue="id"
            name="id_severidade"
            [(ngModel)]="evento.EquipamentoEvento.id_severidade"
            formControlName="id_severidade"
            [ngClass]="{
              'is-invalid': submitted && VForm.id_severidade.errors
            }"
          >
          </ng-select>
          <div
            *ngIf="submitted && VForm.id_severidade.errors"
            class="invalid-feedback"
          >
            <div *ngIf="VForm.id_severidade.errors.required">
              Severidade é obrigatório
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label>Descrição / Tratamento: </label>
        <div class="form-group">
          <textarea
            class="form-control"
            name="dc_tratamento"
            [(ngModel)]="evento.EquipamentoEvento.dc_tratamento"
            formControlName="dc_tratamento"
            [ngClass]="{
              'is-invalid': submitted && VForm.dc_tratamento.errors
            }"
          ></textarea>
          <div
            *ngIf="submitted && VForm.dc_tratamento.errors"
            class="invalid-feedback"
          >
            <div *ngIf="VForm.dc_tratamento.errors.required">
              Tratamento é obrigatório
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <!-- Button to submit formVisita -->
  <button
    type="button"
    [class]="evento.EquipamentoEvento.tp_alarme == 'panico' ?  'btn btn-danger': 'btn btn-warning'"
    (click)="EventoFormOnSubmit()"
    rippleEffect
  >
    <!-- (click)="modal.close('Accept click')" -->
    Registrar
  </button>
</div>
