import { Component, OnInit } from "@angular/core";

import { EquipamentoEventoService } from "../equipamento-evento.service";

@Component({
  selector: "app-equipamento-evento-preview",
  styleUrls: ["./equipamento-evento-preview.component.scss"],
  templateUrl: "./equipamento-evento-preview.component.html",
})
export class EquipamentoEventoPreviewComponent implements OnInit {
  public evento: any;
  config: any = {
    alerta: {
      title: "Avisos",
      icon: "alert-triangle",
      color: "warning",
    },
    panico: {
      title: "Pânico",
      icon: "x-circle",
      color: "danger",
    },
  };

  /**
   * Constructor
   *
   * @param {CoreSidebarService} _coreSidebarService
   */
  constructor(
    private _equipamentoEvento: EquipamentoEventoService
  ) {}


  ngOnInit(): void {
    this._equipamentoEvento.onLastEquipamentoEventoChange.subscribe(
      (evento) => {
        this.evento = evento;
        console.log(evento);
      }
    );
  }
}
