import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ChaveService } from "../chave.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddChavesComponent } from "../add-chaves/add-chaves.component";
import Swal from "sweetalert2";

@Component({
  selector: "app-chaves-list",
  templateUrl: "./chaves-list.component.html",
  styleUrls: ["./chaves-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ChavesListComponent implements OnInit, OnDestroy {
  // public
  public data: any;
  public selectedOption = 10;
  public ColumnMode = ColumnMode;
  public searchValue = "";
  public page: any;

  // decorator
  @ViewChild(DatatableComponent) table: DatatableComponent;

  // private
  private tempData = [];
  private _unsubscribeAll: Subject<any>;
  public rows = [];
  public tempFilterData;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {EncomendaListService} _encomendaListService
   */
  constructor(
    private _chaveService: ChaveService,
    private _coreConfigService: CoreConfigService,
    private _modalService: NgbModal,
  ) {
    this.page = this._chaveService.paging;
    this._unsubscribeAll = new Subject();
  }

  /**
   * filterUpdate
   *
   * @param event
   */
  filterUpdate(event) {
    this._chaveService.updateFilter({ search: event.target.value });
  }

  /**
   * Called whenever the user changes page
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  pageCallback(pageInfo: {
    count?: number;
    pageSize?: number;
    limit?: number;
    offset?: number;
  }) {
    this.page.offset = pageInfo.offset;
    this._chaveService.updatePaging(this.page);
  }

  /**
   * Called whenever the user changes the sort order
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  sortCallback(sortInfo: {
    sorts: { dir: string; prop: string }[];
    column: {};
    prevValue: string;
    newValue: string;
  }) {
    // there will always be one "sort" object if "sortType" is set to "single"
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this._chaveService.updatePaging(this.page);
  }

  ngOnInit(): void {
    // Subscribe config change
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        // If we have zoomIn route Transition then load datatable after 450ms(Transition will finish in 400ms)
        if (config.layout.animation === "zoomIn") {
          setTimeout(() => {
            this._chaveService.onChaveListChanged
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((response) => {
                this.data = response;
                this.rows = this.data;
                this.tempData = this.rows;
                this.tempFilterData = this.rows;
                this.page = this._chaveService.paging;
              });
          }, 450);
        } else {
          this._chaveService.onChaveListChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response) => {
              this.data = response;
              this.rows = this.data;
              this.tempData = this.rows;
              this.tempFilterData = this.rows;
              this.page = this._chaveService.paging;
            });
        }
      });
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  openAddChaves(){
    const modal = this._modalService.open(AddChavesComponent,{
      size: "md",
      backdrop: "static",
      keyboard: false,
      centered: true,
    });
  }
  closeModal(){
    this._modalService.dismissAll();
  }
  registerReturn(controle){
    Swal.fire({
      title: "Deseja realmente registrar a devolução deste item?",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((result) => {
      if (result.isConfirmed) {
        this._chaveService.registerReturn(controle.ControleAreaItem.id).subscribe(
          (data: any) => {
            Swal.fire({
              icon: "success",
              title: "Devolução registrada com sucesso",
              showConfirmButton: false,
              timer: 1500,
            });
            this._chaveService.getDataTableRows();
            // this.visitanteService.getLastVisits(5).subscribe((data: any) => {
            //   this.lastVisits = data;
            // });
          },
          (error) => {
            Swal.fire({
              icon: "error",
              title: "Não foi possível registrar devolução",
              html: (error.text || error) + "",
            });
          }
        );
      } else if (result.isDismissed) {
        //Swal.fire("Saída não registrada", "", "info");
      }
    });
  }
  
}
