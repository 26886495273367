import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { CoreCommonModule } from '@core/common.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreSidebarModule } from '@core/components';

import { AddVisitanteCardComponent } from './add-visitante-card/add-visitante-card.component';
import { VisitanteService } from './visitante.service';
import { NgxMaskModule } from 'ngx-mask';
import { WebcamModule } from 'ngx-webcam';
import { AddVisitanteReservaComponent } from './add-visitante-reserva/add-visitante-reserva.component';
import { VisitanteListComponent } from './visitante-list/visitante-list.component';
import { ViewVisitanteSidebarComponent } from './view-visitante-sidebar/view-visitante-sidebar.component';
import { AddVisitanteAutorizadoComponent } from './add-visitante-autorizado/add-visitante-autorizado.component';
import { BlockUIModule } from 'ng-block-ui';
import { ImageCropperModule } from 'ngx-image-cropper';

// routing
const routes: Routes = [
//   {
//     path: 'add',
//     component: InvoiceAddComponent,
//     resolve: {
//       Ias: InvoiceAddService
//     },
//     data: { animation: 'InvoiceAddComponent' }
//   },
  {
    path: 'list',
    component: VisitanteListComponent,
    resolve: {
      uls: VisitanteService
    },
    data: { animation: 'VisitanteListComponent' }
  },
//   {
//     path: 'preview/:id',
//     component: InvoicePreviewComponent,
//     resolve: {
//       data: InvoicePreviewService
//     },
//     data: { path: 'user-view/:id', animation: 'InvoicePreviewComponent' }
//   },
//   {
//     path: 'edit/:id',
//     component: InvoiceEditComponent,
//     resolve: {
//       data: InvoiceEditService
//     },
//     data: { path: 'user-view/:id', animation: 'InvoiceEditComponent' }
//   },
//   {
//     path: 'preview',
//     redirectTo: '/apps/invoice/preview/4989' // Redirection
//   },
//   {
//     path: 'edit',
//     redirectTo: '/apps/invoice/edit/4989' // Redirection
//   }
];

@NgModule({
  declarations: [
    AddVisitanteCardComponent,
    AddVisitanteReservaComponent,
    AddVisitanteAutorizadoComponent,
    VisitanteListComponent,
    ViewVisitanteSidebarComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    CoreDirectivesModule,
    Ng2FlatpickrModule,
    NgxDatatableModule,
    FormsModule,
    CorePipesModule,
    NgbModule,
    NgSelectModule,
    CoreSidebarModule,
    ImageCropperModule,
    NgxMaskModule.forRoot(),
    WebcamModule,
    BlockUIModule.forRoot({
      message: 'Aguarde ...'
    })
  ],
  providers: [VisitanteService],
  exports: [AddVisitanteCardComponent,VisitanteListComponent,ViewVisitanteSidebarComponent,AddVisitanteReservaComponent,AddVisitanteAutorizadoComponent]
})
export class VisitanteModule {}
