import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { CoreCommonModule } from '@core/common.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreSidebarModule } from '@core/components';

import { ChaveService } from './chave.service';
import { NgxMaskModule } from 'ngx-mask';
import { WebcamModule } from 'ngx-webcam';
import { ChavesListComponent } from './chaves-list/chaves-list.component';
import { BlockUIModule } from 'ng-block-ui';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AddChavesComponent } from './add-chaves/add-chaves.component';

// routing
const routes: Routes = [
  {
    path: 'list',
    component: ChavesListComponent,
    resolve: {
      uls: ChaveService
    },
    data: { animation: 'ChavesListComponent' }
  },

];

@NgModule({
  declarations: [
    ChavesListComponent,
    AddChavesComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    CoreDirectivesModule,
    Ng2FlatpickrModule,
    NgxDatatableModule,
    FormsModule,
    CorePipesModule,
    NgbModule,
    NgSelectModule,
    CoreSidebarModule,
    ImageCropperModule,
    NgxMaskModule.forRoot(),
    WebcamModule,
    BlockUIModule.forRoot({
      message: 'Aguarde ...'
    })
  ],
  providers: [ChaveService],
  exports: [ChavesListComponent,AddChavesComponent]
})
export class ChavesModule {}
