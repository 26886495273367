<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- Ocorrencia List -->
    <section class="ocorrencia-list-wrapper">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Lista de Ocorrências</h4>
          <i
            data-feather="more-vertical"
            class="font-medium-3 cursor-pointer"
          ></i>
        </div>
        <!-- Ocorrencia List Header -->
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <div class="d-flex align-items-center">
                <label class="d-flex align-items-center"
                  >Exibir
                  <select
                    class="form-control mx-25"
                    [(ngModel)]="page.limit"
                    (change)="pageCallback({ offset: 0 })"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </label>
                <button
                  class="btn btn-info ml-2"
                  rippleEffect
                  (click)="modalOpenForm()"
                >
                  <i data-feather="plus-circle" class="font-medium-2"></i>
                  Adicionar Ocorrência
                </button>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-12 d-flex justify-content-end align-items-center pr-3"
          >
            <label class=""
              ><input
                [(ngModel)]="searchValue"
                name="searchValue"
                type="search"
                class="form-control"
                placeholder="Pesquisar..."
                (keyup)="filterUpdate($event)"
                (search)="filterUpdate($event)"
            /></label>
          </div>
        </div>
        <!--/ Ocorrencia List Header -->

        <!-- Ocorrencia List Table -->
        <ngx-datatable
          [rows]="rows"
          [rowHeight]="50"
          class="bootstrap core-bootstrap"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          [scrollbarH]="true"
          [externalPaging]="true"
          [externalSorting]="true"
          [count]="page.count"
          [offset]="page.offset"
          [limit]="page.limit"
          [sortType]="'single'"
          (page)="pageCallback($event)"
          (sort)="sortCallback($event)"
        >
          <ngx-datatable-column name="#" prop="Ocorrencia.id" [width]="10">
            <ng-template let-id="value" ngx-datatable-cell-template>
              <a
                data-toggle="modal"
                data-target="#view-ocorrencia-sidebar"
                (click)="viewData(id)"
                class="font-weight-bold"
                >#{{ id }}</a
              >
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="Data"
            prop="Ocorrencia.dt_ocorrencia"
            [width]="50"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <div class="cell-line-height">
                <span class="d-block text-wrap">{{ value }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="Ocorrência"
            prop="Ocorrencia.nm_ocorrencia"
            [width]="70"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <div class="cell-line-height">
                <span class="d-block text-wrap">{{ value }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="Destino"
            prop="Ocorrencia.dv_id_destino"
            [width]="50"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <div class="cell-line-height">
                <span class="d-block text-wrap">{{ value }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="Severidade"
            prop="Ocorrencia.dv_id_severidade"
            [width]="50"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <div class="cell-line-height">
                <span class="d-block text-wrap">{{ value }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="Usuário"
            prop="Usuario.nm_usuario"
            [width]="70"
          >
            <ng-template
              let-row="row"
              let-name="value"
              ngx-datatable-cell-template
            >
              <div class="d-flex align-items-center">
                <div *ngIf="row.Usuario.dc_picture_url; else customAvatar">
                  <img
                    class="rounded-circle mr-1"
                    src="{{
                      row.Usuario.dc_picture_url ||
                        'assets/images/avatars/default.jpg'
                    }}"
                    height="32"
                    width="32"
                    alt="datatable-avatar"
                  />
                </div>
                <ng-template #customAvatar>
                  <div class="avatar mr-1 ml-0">
                    <div class="avatar-content">{{ name | initials }}</div>
                  </div>
                </ng-template>
                <div class="cell-line-height">
                  <span
                    class="font-weight-bold d-block text-wrap font-medium-1"
                    >{{ name }}</span
                  >
                  <!-- <span class="text-muted font-small-2">
                  {{ row.Usuario.dc_email }}</span
                > -->
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Ações" [width]="40" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="d-flex align-items-center col-actions">
                <!-- <a
                      class="mr-1"
                      href="javascript:void(0);"
                      container="body"
                      placement="top"
                      ngbTooltip="Send Mail"
                      ><i size="18" data-feather="send"></i
                    ></a> -->
                <a
                  class="mr-1"
                  data-toggle="modal"
                  data-target="#view-ocorrencia-sidebar"
                  (click)="viewData(row.Ocorrencia.id)"
                  container="body"
                  placement="top"
                  ngbTooltip="Visualizar Detalhes"
                  ><i size="18" data-feather="eye"></i
                ></a>
                <!-- <div ngbDropdown container="body">
                      <button
                        ngbDropdownToggle
                        type="button"
                        class="btn icon-btn btn-sm hide-arrow px-0"
                        rippleEffect
                      >
                        <i
                          data-feather="more-vertical"
                          size="18"
                          class="cursor-pointer"
                        ></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a href="javascript:void(0)" ngbDropdownItem
                          ><i data-feather="download" class="mr-1"></i>Download</a
                        ><a
                          routerLink="/apps/encomenda/edit/{{ row.id }}"
                          ngbDropdownItem
                          ><i data-feather="edit" class="mr-1"></i>Edit</a
                        ><a href="javascript:void(0)" ngbDropdownItem
                          ><i data-feather="trash" class="mr-1"></i>Delete</a
                        ><a href="javascript:void(0)" ngbDropdownItem
                          ><i data-feather="copy" class="mr-1"></i>Duplicate</a
                        >
                      </div>
                    </div> -->
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
        <!--/ Ocorrencia List Table -->
      </div>
    </section>
    <!--/ Ocorrencia List -->
  </div>
  <core-sidebar
    class="modal modal-slide-in sidebar-todo-modal fade"
    name="view-ocorrencia-sidebar"
    overlayClass="modal-backdrop"
    hideOnEsc="true"
  >
    <app-view-ocorrencia-sidebar></app-view-ocorrencia-sidebar>
  </core-sidebar>
</div>
